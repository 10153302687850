<div class="container-fluid c-container-50 btn-login-prices">
  <button *ngIf="!this.userLogging" [routerLink]="['/register'] | localize" style="white-space: normal;background: #FF0000; color: #ffffff; width: 100%;" class="btn btn_register_prices" >{{ 'reseller_login_price' | translate }}</button>
</div>
<div class="c-content-box c-size-xs c-bg-white">
  <div class="container-fluid c-container-50 headerproduct">
    <div class="row">
      <div class="col-md-12 c-margin-t-10">
        <ul class="pull-left c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
          <li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>

          <ng-container *ngFor="let breadcrumb of this.breadcrumbs;let last=last  "  >
            <li>|</li>
            <li>
              <a *ngIf="breadcrumb.url" [routerLink]=" (breadcrumb.url | localize) " (click)="last ? '' : this.item=null" href="javascript:void(0)" >{{ breadcrumb.description |  titlecase }}</a>
              <span *ngIf="!breadcrumb.url"     >{{ breadcrumb.description |  titlecase }}</span>
            </li>
          </ng-container>


        </ul>

        <div class="pull-right centermobile">
          <a href="javascript:void(0)" onclick="window.close();"  class="btn c-theme-btn c-btn-square c-bg-black ccwhite helveticaltsd c-font-12 c-font-lower button_back btn-top-mob">&lt; {{ 'back_to_previous_page' | translate }}</a>
          <!-- <br>
            <button *ngIf="!this.userLogging" (click)="this.openmodalloginService.send()" style="white-space: normal;background: #b21f2d; color: #ffffff;margin-top: 10px !important; margin-bottom: 10px !important;" class=" pull-right btn" >{{ 'isreseller' | translate }}</button> -->

        </div>


      </div>
    </div>
  </div>
</div>


<ng-image-fullscreen-view
    [images]="imageObject"
    [imageIndex]="selectedImageIndex"
    [show]="showFlag"
    (close)="closeEventHandler()"></ng-image-fullscreen-view>




<ng-container *ngIf="!this.item">
  <div class="c-content-box c-size-xs">
    <div class="container-fluid c-container-50">
      <div class="row">
        <div class="col-lg-1 col-md-2 hidden-sm hidden-xs">
          <div class="row c-product-gallery-thumbnail">
            <div class="galleryDisplay">
              <ng-container *ngFor="let sideimg of this.sideimges">
                <div class="col-xs-12 c-product-thumb c-bg-white">
                  <img [src]="defaultImage" alt="" class="c-border c-border-grey-4">
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-6 col-xs-12 pull-left hidden-sm hidden-xs cbp-caption-active cbp-caption-pushTop cbp-ready cbp-cols-86">
          <div class="c-product-gallery-content galleryDisplay gallery74">
            <div class="item" style="height: 650px; display: flex;">
              <a href="javascript:;" class="pull-left" style="top: 50%; z-index: 2; cursor: pointer; position: absolute; left: 0;">
                <img src="assets/images/lefticon.png" alt="" style="height: 25px; margin-right: 5px;">
              </a>
              <img [src]="defaultImage" style="height: 100%;width: fit-content;margin-left: auto;margin-right: auto;">
              <a href="javascript:;" class="pull-right" style="top: 50%; z-index: 2; cursor: pointer; position: absolute; right: 0;">
                <img src="assets/images/righticon.png" alt="" style="height: 25px; margin-left: 5px;">
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4 pull-left col-xs-12 c-prod-info">
          <div class="sketlon-text-title" style="width: 35%;"></div>
          <div class="sketlon-text-title" style="width: 25%;"></div>
          <div class="sketlon-box"></div>
          <div class="sketlon-btn"></div>
          <br>
          <div class="sketlon-text-title" style="width: 25%;"></div>
          <br>
          <div class="sketlon-text-title"></div>
          <div class="sketlon-text-title"></div>
          <div class="sketlon-text-title"></div>
          <div class="sketlon-text-title"></div>
          <div class="sketlon-text-title"></div>
        </div>
      </div>
    </div> 
  </div>
</ng-container>

<ng-container *ngIf="this.item">
  <div class="c-content-box c-size-xs">
    <div class="container-fluid c-container-50">
      <div class="row">
  
  
        <div *ngIf="!this.deviceDesktop" class="col-xs-12 hidden-lg hidden-md">
  
          <div *ngIf="this.item && this.item.novelty && this.item.datelimitnovelty && this.item.datelimitnovelty>=this.item.datenow" class="prodNovelty visible-sm visible-xs">{{ 'new' | translate }}</div>
          <div *ngIf="item && item.withPromotion && item.withPromotion==1 && this.userLogging" class="prodPromo c-font-14 c-font-uppercase ccwhite" style="top: 50px;"> -{{ showDiscount(this.item, this.idcolor, this.idsize) }}%</div>
  
  
  
          <ng-container   *ngIf="this.item && this.item.itemwithattr==1 ">
  
            <ng-container *ngIf="this.keyImageSel!=-2  && this.item &&   !this.deviceDesktop">
            <img style="width:100%" (click)="showLightbox(this.keyImageSel)" [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="(this.browserwebp && this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].webp) ? this.apiService.imagesDir+this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].webp :  this.apiService.imagesDir+this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].photo" />
  
            </ng-container>
            <ng-container *ngIf="this.keyImageSel==-2  && this.item && this.item.video_id && !this.deviceDesktop">
  
                <div id="player" (plyrReady)="plyrReady()" plyr style="margin-top: 25%;margin-bottom: 25%;"    [plyrOptions]="{ muted:true, autoplay:true, loop:{ active: true } }"    [plyrPlaysInline]="true" [plyrSources]="[{src: this.item.video_id, provider: this.item.video_player  }]"  ></div>
  
              </ng-container>
  
  
            <owl-carousel-o *ngIf=" (this.item.video_id && cicleleng(this.item.colors[this.idcolor])<2) || cicleleng(this.item.colors[this.idcolor])>1" #owlElement class="slider-active owl-styles-nav product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
              <ng-container   *ngFor="let img of  cicle(this.item.colors[this.idcolor])  ; let i1=index    ">
                <ng-template carouselSlide>
                  <div class="item"><img (click)="this.keyImageSel=i1" [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp :  this.apiService.imagesDir+img.photo"  class="col-xs-12 noPadding  "/></div>
                </ng-template>
              </ng-container>
  
  
  
  
                <ng-container *ngIf=" this.item && this.item.video_id && !this.deviceDesktop">
                  <ng-template carouselSlide>
                    <div (click)="this.keyImageSel = -2; " class="item"   >
                      <img   class="   "   [src]="this.item.videothunb.thumbnail_large"  >
                      <i style="position: absolute;  left: 0;  right: 0;  z-index: 2; width: 10px;    margin: 0 auto; top: 48%;color: red;" class="fa fa-youtube-play"></i>
                      </div>
                    </ng-template>
                </ng-container>
  
  
  
            </owl-carousel-o>
  
          </ng-container>
  
          <ng-container   *ngIf="this.item && this.item.itemwithattr==0 ">
  
  
            <ng-container *ngIf="this.keyImageSel!=-2  && this.item &&   !this.deviceDesktop">
            <img style="width:100%" (click)="showLightbox(this.keyImageSel)" [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="(this.browserwebp && this.item.images[this.keyImageSel].webp) ? this.apiService.imagesDir+this.item.images[this.keyImageSel].webp :  this.apiService.imagesDir+this.item.images[this.keyImageSel].photo" />
  
            </ng-container>
            <ng-container *ngIf="this.keyImageSel==-2  && this.item && this.item.video_id && !this.deviceDesktop">
  
                <div id="player"  (plyrReady)="plyrReady()" plyr style="margin-top: 25%;margin-bottom: 25%;"    [plyrOptions]="{ muted:true, autoplay:true, loop:{ active: true } }"    [plyrPlaysInline]="true" [plyrSources]="[{src: this.item.video_id, provider: this.item.video_player  }]"  ></div>
  
              </ng-container>
  
            <owl-carousel-o *ngIf="(this.item.video_id && this.item.images.length<2) || this.item.images.length>1   " #owlElement1 class="slider-active owl-styles-nav product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
              <ng-container   *ngFor="let img of  this.item.images ; let i1=index    ">
                <ng-template carouselSlide>
                  <div class="item"><img (click)="this.keyImageSel=i1" [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp : this.apiService.imagesDir+img.photo"  class="col-xs-12 noPadding  "/></div>
                </ng-template>
              </ng-container>
  
  
              <ng-container *ngIf=" this.item && this.item.video_id && !this.deviceDesktop">
                <ng-template carouselSlide>
                  <div (click)="this.keyImageSel = -2; " class="item"   >
                    <img   class="   "   [src]="this.item.videothunb.thumbnail_large"  >
                    <i style="position: absolute;  left: 0;  right: 0;  z-index: 2; width: 10px;    margin: 0 auto; top: 48%;color: red;" class="fa fa-youtube-play"></i>
                    </div>
                  </ng-template>
              </ng-container>
            </owl-carousel-o>
  
          </ng-container>
  
  
  
        </div>
  
  
  
        <div *ngIf="this.deviceDesktop" class="col-lg-1 col-md-2 hidden-sm hidden-xs">
  
          <div class="row c-product-gallery-thumbnail">
            <div class="galleryDisplay "   cont="0" style="display: block;">
  
              <ng-container *ngIf="this.item && this.item.itemwithattr==1 && this.item.colors && this.item.colors[this.idcolor]"  >
                <div  *ngFor="let img of  cicle(this.item.colors[this.idcolor])  ;  let i1=index  " (click)="this.keyImageSel = i1;" class="col-xs-12 c-product-thumb   c-bg-white">
                  <img   class="c-border c-border-grey-4" [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp :  this.apiService.imagesDir+img.photo" img_order="{{ i1 }}">
                </div>
              </ng-container>
  
  
              <ng-container *ngIf="this.item && this.item.itemwithattr==1 && this.item.video && this.item.video_play>0"  >
                <div    (click)="this.keyImageSel = -2; " class="col-xs-12 c-product-thumb   c-bg-white">
                  <img   class="c-border c-border-grey-4  "   [src]="this.item.videothunb.thumbnail_large"  >
                  <i style="position: absolute;  left: 0;  right: 0;  z-index: 2; width: 10px;    margin: 0 auto; top: 48%;color: red;" class="fa fa-youtube-play"></i>
                </div>
              </ng-container>
  
              <ng-container *ngIf="this.item &&  !this.item.itemwithattr " >
                <div   *ngFor="let img of  this.item.images ;   let i1=index    "  (click)="this.keyImageSel = i1;" class="col-xs-12 c-product-thumb   c-bg-white">
                  <img   class="c-border c-border-grey-4" [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp :  this.apiService.imagesDir+img.photo" img_order="{{ i1 }}">
                </div>
              </ng-container>
  
            </div>
  
  
  
          </div>
        </div>
  
  
  
  
        <div class="col-lg-7 col-md-6 col-xs-12 pull-left hidden-sm hidden-xs cbp-caption-active cbp-caption-pushTop cbp-ready cbp-cols-86" id="grid-container" style="height: 0px;"><div class="">
          <div *ngIf="item && item.withPromotion && item.withPromotion==1 && this.userLogging" class="prodPromo c-font-14 c-font-uppercase ccwhite"> -{{ showDiscount(this.item, this.idcolor, this.idsize) }}%</div>
          <div class="prodNovelty" *ngIf="!(item && item.withPromotion && item.withPromotion==1) && this.item && this.item.novelty && this.item.datelimitnovelty && this.item.datelimitnovelty>=this.item.datenow" >{{ 'new' | translate }}</div>
          <div class="c-product-gallery-content galleryDisplay gallery74" style="display: flex;">
  
  
  
            <ng-container *ngIf="this.item && this.item.itemwithattr==1 && this.item.colors && this.item.colors[this.idcolor][this.idsize]"  >
              <div  style="width: 100%;text-align: center;" class="item">
  
                  <a    href="javascript:void(0)" (click)="cicleImageKey(0,1)"   style="top: 50%;z-index: 2;cursor: pointer;position:absolute;left:0" class="pull-left"  >  <img style="    height: 25px;    margin-right: 5px;" alt="<" src="assets/images/lefticon.png">  </a>
                <lib-ngx-image-zoom *ngIf="this.keyImageSel>-2"
                  (click)="showLightbox(this.keyImageSel)"
                [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)"
                [title]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)"
                enableLens="true"
                lensWidth = "1500"
                lensHeight="1500"
                 class="col-xs-12 noPadding class_prod_image_zoom"
      [thumbImage]="(this.browserwebp && this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].webp) ? this.apiService.imagesDir+this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].webp :  this.apiService.imagesDir+this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].photo"
      [fullImage]="(this.browserwebp && this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].webp) ? this.apiService.imagesDir+this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].webp :  this.apiService.imagesDir+this.item.colors[this.idcolor][this.idsize].images[this.keyImageSel].photo"
  ></lib-ngx-image-zoom>
  
  <ng-container *ngIf="this.keyImageSel==-2 && this.item && this.item.video_id && this.deviceDesktop">
  
    <div id="player" plyr   style="width:calc(100% - 10px)"   [plyrOptions]="{ muted:true, autoplay:true, loop:{ active: true } }"    [plyrPlaysInline]="true" [plyrSources]="[{src: this.item.video_id, provider: this.item.video_player  }]"  ></div>
  
    </ng-container>
  
  <a    href="javascript:void(0)" (click)="cicleImageKey(1,1)"   style="top: 50%;z-index: 2;cursor: pointer;position:absolute;right:0" class="pull-right" >  <img style="    height: 25px;    margin-left: 5px;" alt=">" src="assets/images/righticon.png">  </a>
  
              </div>
            </ng-container>
  
            <ng-container *ngIf="this.item && !this.item.itemwithattr"  >
              <div style="width: 100%;text-align: center;" *ngIf="this.item && item.itemwithattr==0" class="item">
  
  <a    href="javascript:void(0)" (click)="cicleImageKey(0,0)"   style="top: 50%;z-index: 2;cursor: pointer;position:absolute;left:0" class="pull-left"  >  <img style="    height: 25px;    margin-right: 5px;" alt="<" src="assets/images/lefticon.png">  </a>
                <lib-ngx-image-zoom
                (click)="showLightbox(this.keyImageSel)"
                [alt]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)"
                [title]="this.item.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)"
                enableLens="true"
                lensWidth = "1500"
                lensHeight="1500"
                 class="col-xs-12 noPadding class_prod_image_zoom"
      [thumbImage]="(this.browserwebp && this.item.images[this.keyImageSel].webp) ? this.apiService.imagesDir+this.item.images[this.keyImageSel].webp :  this.apiService.imagesDir+this.item.images[this.keyImageSel].photo"
      [fullImage]="(this.browserwebp && this.item.images[this.keyImageSel].webp) ? this.apiService.imagesDir+this.item.images[this.keyImageSel].webp :  this.apiService.imagesDir+this.item.images[this.keyImageSel].photo"
  ></lib-ngx-image-zoom>
  <a    href="javascript:void(0)" (click)="cicleImageKey(1,0)"   style="top: 50%;z-index: 2;cursor: pointer;position:absolute;right:0" class="pull-right" >  <img style="    height: 25px;    margin-left: 5px;" alt=">" src="assets/images/righticon.png">  </a>
  
  
              </div>
            </ng-container>
  
  
          </div>
  
  
  
        </div></div>
  
        <div class="col-md-4 pull-left col-xs-12 c-prod-info">
  
          <h1 class="title_h3 noPadding col-xs-12 c-font-bold c-font-16">{{ (this.item && this.item.langs[this.translate.currentLang]) ? this.item.langs[this.translate.currentLang].description : '' }}</h1>
          <div class="product-price noPadding col-xs-12 c-margin-b-10 ">{{ 'ref' | translate }} <span class="itemCode">{{ this.item ? this.item.code : '' }}</span>
            <div class="rating rating-top" (click)="scroll(viewreviews)">
              <ng-container *ngIf="average_rating">
                <ngb-rating style="font-size: 15px; width: fit-content;" [rate]="this.average_rating" [readonly]="true" [max]="5" tooltipClass="tooltip-custom-class" ngbTooltip="{{average_rating}} {{ 'of' | translate }} 5 {{ 'stars' | translate }}" [starTemplate]="scoreTemplate2"></ngb-rating>
                <ng-template #scoreTemplate2 let-fill="fill">
                  <span class="star star-top" [class.full]="fill === 100">
                    <span class="half half-top" [style.width.%]="fill">&#9733; </span>&#9733;
                  </span>
                </ng-template>
              </ng-container>
            </div>
            <span class="rating-desc-top" (click)="scroll(viewreviews)" *ngIf="average_rating">({{totalreviews}} <ng-container *ngIf="totalreviews > 1">{{ 'reviews' | translate }}</ng-container> <ng-container *ngIf="totalreviews == 1">{{ 'review' | translate }}</ng-container>)</span>
          </div>
  
          <div  *ngIf="item && item.withPromotion && item.withPromotion==1" class="product-price noPadding col-xs-12 c-margin-b-10 ">{{ 'promotionFrom' | translate }} {{ formatDateLocal(this.item.startPromo) }} {{ 'to' | translate }} {{ formatDateLocal(this.item.endPromo) }}</div>
  
          <div class="product-price noPadding col-xs-12 c-margin-b-10 "><a *ngIf="this.item && this.item.brandName" [href]="" [routerLink]="['/show', this.lowercase.transform(this.urlpipePipe.transform(this.item.menuBrand[this.translate.currentLang]))+'-m'+this.item.menuBrandId, this.lowercase.transform(this.urlpipePipe.transform(this.item.brandName))+'-b'+this.item.idbrand  ] | localize" onclick="return false;" class="c-font-bold"  >{{ this.item ? this.item.brandName : '' }}</a></div>
  
          <p class="product-price noPadding col-xs-12 c-margin-b-10 hidden">
            &nbsp;				</p>
  
            <div class="clearfix"></div>
  
            <div class="col-sm-12 col-xs-12 noPadding">
  
              <div class="row b-margin-20">
                <div class="col-xs-12 c-color-wrapper">
                  <label *ngIf="(this.item && this.item.itemwithattr==1) && this.userLogging" class="c-input-title helveticaltsdr c-font-15 c-margin-b-10 ccblack"><span >{{ 'choosecolorquant' | translate }}</span>: <label class="  helveticaltsdr c-font-15 c-margin-b-10 ccblack showcolor">{{ this.item ? showbyColorName() : '' }}</label></label>
                  <ng-container   *ngIf="this.item && this.item.itemwithattr==1 ">
  
  
  
  
  
  
  
                      <span class="c-custom-labels"   >
  
              <ng-container   *ngFor="let color of reordercolor(this.item.colors); let i=index ">
  
              <ng-container   *ngFor="let size of reordersize(color) ; let i3=index ">
                                      <label [class]=" (this.idcolor==color[size.idsize].colorid && this.idsize==size.idsize) ?  'btn c-btn-square active' : 'btn c-btn-square '  ">
                    <div class="labelColor" style="background-size: 100% 100%;">
                                              <img alt="{{ this.item.langs[this.translate.currentLang].description }} - {{ showColorName(color) }} - {{ 'Name_APP' | translate }}" [src]="getImg(color)">
                                          </div>
                    <input style="position: absolute;    clip: rect(0, 0, 0, 0);    pointer-events: none;" name="color" data-colorname="{{ showColorName(color) }}" (change)="changeColor(color[size.idsize].colorid, size.idsize)" value="{{ i }}" type="radio">
                    <div class="text-center col-xs-12 noPadding mt-1">
                                            <del class="c-font-12" *ngIf="  item.withPromotion && item.withPromotion==1  ">{{ showPrice(this.item, 1, this.idcolor, this.idsize) }}€</del>
                                            <p class="noMargin c-font-13 c-theme-font c-font-bold">{{ showPrice(this.item, 2, this.idcolor, this.idsize) }}€</p>
                                            <p class="noMargin c-font-10 c-font-bold">{{ 'sizeabbr' | translate }}: {{ size.number }}</p>
                                            <p class="noMargin c-font-10 c-font-bold">{{ 'stock' | translate }}: {{ size.stock | number: '1.0-0' }}</p>
  
                      <div *ngIf="!this.item.notstock" class="c-spinner-invert quantWrapper" style="margin: 0 auto !important;width: 40px;">
                                                <input (click)="changeColor(color[size.idsize].colorid, size.idsize)" [value]="size.qtdCart > 0  ? (size.qtdCart | number: '1.0-0') : '' " class="form-control input-sm c-square quantInput  prodQuant" data_max="4" data_min="0" (change)="stockajust(size.stock, this.item.iditem, color[size.idsize].colorid, size.idsize)" [id]="'qtd_stock_'+this.item.iditem+'_'+color[size.idsize].colorid+'_'+size.idsize"   type="text">
                        <div class="clearfix"></div>
                        <div class="noPadding" style="width:40px !important;">
  
                          <button class="btn btn-xs btn-default c-square " (click)="descProd(size.stock, this.item.iditem, color[size.idsize].colorid, size.idsize)" style="width:20px;float:left;padding:1px 3px !important;" type="button" data_min="0" >
                            <i class="fa fa-minus" style="font-size:10px;"></i>
                          </button>
                          <button class="btn btn-xs btn-default c-square noMargin"  (click)="incProd(size.stock, this.item.iditem, color[size.idsize].colorid, size.idsize)" style="width:20px;float:left;padding:1px 3px !important;" type="button" >
                            <i class="fa fa-plus" style="font-size:10px;"></i>
                          </button>
                        </div>
                      </div>
  
                    </div>
  
                    </label>
  
                  </ng-container>
              </ng-container>
              </span>
  
  
  
  
  
                  </ng-container>
                </div>
              </div>
  
              <div class="clearfix"></div>
              &nbsp;				</div>
  
              <button *ngIf="this.item && this.item.notstock" type="button"   class="btn    c-btn-square cartBtn" style="height:50px; padding-left: 22px; padding-right: 22px;">{{ 'soldoff' | translate }}</button>
              <!-- <ng-container *ngIf="this.userLogging"> -->
                <button *ngIf="this.item && !this.item.notstock" type="button" (click)="buyProduct()" class="btn c-theme-bg c-font-white c-btn-square cartBtn" style="height:50px; padding-left: 22px; padding-right: 22px;">{{ 'add_to_cart' | translate }}</button>
                <button *ngIf="this.item && !this.item.notstock" type="button" (click)="addToFavorite()" class="btn c-btn-square wishBtn" style="height:50px">
                  <ng-container *ngIf="this.item && this.item.itemwithattr==1 && this.item.colors[this.idcolor][this.idsize].wishlist==0">
                    <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                      <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                    </svg>
                    <span style="margin-left: 5px;">{{ 'add_to_wishlist' | translate }}</span>
                  </ng-container> 
                  <ng-container *ngIf="this.item && this.item.itemwithattr==1 && this.item.colors[this.idcolor][this.idsize].wishlist==1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                    </svg>
                    <span style="margin-left: 5px;">{{ 'added_wishlist' | translate }}</span>
                  </ng-container>
                </button>
                <ng-container *ngIf="this.salesPoints &&  this.salesPoints > 0 && this.userLogging">
                  <div class="point-container" style="margin-top: 10px;">
                    <ng-container *ngIf="this.salesPoints>1">
                      <span class="orange"[innerHTML]="('points_buy_multiple' | translate).replace(':1:', this.salesPoints)"></span>
                    </ng-container>
                    <ng-container *ngIf="this.salesPoints==1">
                      <span class="orange" [innerHTML]="('points_buy' | translate).replace(':1:', this.salesPoints)"></span>
                    </ng-container>
                  </div>
                </ng-container>
                <!-- <ng-container *ngIf="this.ac_points && this.ac_points.length >0; else singlepointcart">
                  <ng-container *ngFor="let point of this.ac_points">
                    <div class="point-container" *ngIf="point.idpointtype == 3" style="margin-top: 10px;">
                      <ng-container *ngIf="point.points>1">
                        <span class="orange"[innerHTML]="('points_buy_multiple' | translate).replace(':1:', point.points)"></span>
                      </ng-container>
                      <ng-container *ngIf="point.points==1">
                        <span class="orange" [innerHTML]="('points_buy' | translate).replace(':1:', point.points)"></span>
                      </ng-container>
                    </div>
                  </ng-container>
                  <span></span>
                </ng-container>
                <ng-template #singlepointcart>
                  <ng-container *ngIf="this.ac_points">
                    <div class="point-container" *ngIf="this.ac_points.idpointtype == 3" style="margin-top: 10px;">
                      <ng-container *ngIf="this.ac_points.points>1">
                        <span class="orange" [innerHTML]="('points_buy_multiple' | translate).replace(':1:', this.ac_points.points)"></span>
                      </ng-container>
                      <ng-container *ngIf="this.ac_points.points==1">
                        <span class="orange" [innerHtml]="('points_buy' | translate).replace(':1:', this.ac_points.points)"></span>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-template> -->

              <!-- </ng-container> -->

              <div *ngIf="this.userLogging && this.showsharelink" class="col-lg-12" style="background-color: #f7f7f7; padding-bottom: 12px; margin-top: 20px;">
                <div class="btn" type="button" style="margin-top: 15px; border: 1px solid #e5e5e5;" (click)="generateLink()">
                  <i class="fa fa-send"></i>
                  <b>{{ 'sharelink_btn' | translate }}</b>
                </div> 
                <div class="modal-copy btn" style="display: none;">
                  <i class="fa fa-check"></i> {{ 'copied' | translate }}
                </div>
                
                <!-- <div *ngIf="this.linkCode" class="form-group col-md-12">
                  <div class="input-group c-square">
                    <input #linkinput type="text" value="{{this.linkCode}}" disabled style="width: 100%; height: 35px;">
                    <span class="input-group-btn">
                    <button class="btn c-btn-grey-3 c-bg-black  " style="height: 35px;border-radius:0px" type="button" (click)="copylink(this.linkCode)">
                      <i class="fa fa-copy"></i>
                    </button>
                    </span>
                    </div>
                </div> -->

                <ng-container *ngIf="this.ac_points">
                  <ng-container *ngIf="this.ac_points.length >0; else singlepoint">
                    <ng-container *ngFor="let point of this.ac_points">
                      <div class="col-lg-12 point-container" *ngIf="point.idpointtype == 7" style="margin-top: 10px; padding-left: 0;">
                        <ng-container *ngIf="point.points>1">
                          <span class="points-description" [innerHTML]="('sharelink_success_points_multiple' | translate).replace(':1:', point.points)"></span>
                        </ng-container>
                        <ng-container *ngIf="point.points==1">
                          <span class="points-description" [innerHTML]="('sharelink_success_points' | translate).replace(':1:', point.points)"></span>
                        </ng-container>
                      </div>
                      <div class="col-lg-12 point-container" *ngIf="point.idpointtype == 8" style="padding-left: 0;">
                        <ng-container *ngIf="point.points>1">
                          <span class="points-description" [innerHTML]="('sharelink_success_points_2_multiple' | translate).replace(':1:', point.points)" ></span>
                        </ng-container>
                        <ng-container *ngIf="point.points==1">
                          <span class="points-description" [innerHTML]="('sharelink_success_points_2' | translate).replace(':1:', point.points)"></span>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #singlepoint>
                    <div class="col-lg-12 point-container" *ngIf="this.ac_points.idpointtype == 7" style="margin-top: 10px; padding-left: 0;">
                      <ng-container *ngIf="point.points>1">
                        <span class="points-description" [innerHTML]="('sharelink_success_points_multiple' | translate).replace(':1:', this.ac_points.points)"></span>
                      </ng-container>
                      <ng-container *ngIf="point.points==1">
                        <span class="points-description" [innerHTML]="('sharelink_success_points' | translate).replace(':1:', this.ac_points.points)"></span>
                      </ng-container>
                    </div>
                    <div class="col-lg-12 point-container" *ngIf="this.ac_points.idpointtype == 8" style="padding-left: 0;">
                      <ng-container *ngIf="point.points>1">
                        <span class="points-description" [innerHTML]="('sharelink_success_points_2_multiple' | translate).replace(':1', this.ac_points.points)"></span>
                      </ng-container>
                      <ng-container *ngIf="point.points==1">
                        <span class="points-description" [innerHTML]="('sharelink_success_points_2' | translate).replace(':1:', this.ac_points.points)"></span>
                      </ng-container>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
             
              <div class="clearfix"></div>
              <div *ngIf="this.typeclient==1 && (!this.item || !this.item.notstock) && userLogging" class="product-price noPadding col-xs-12 c-margin-t-20 c-font-17">{{ 'pricenovat' | translate }}</div>
  
              <div class="col-lg-1 col-lg-offset-4 col-md-2 col-md-offset-3 col-sm-3 col-sm-offset-2 noPadding hidden">
                <button type="button" class="btn c-btn-black btn-block c-btn-square c-btn-border-1x c-font-black text-center c-bg-white noPadding-sides c-border-black c-font-theme-hover">
                  <i class="fa fa-heart noMargin" aria-hidden="true"></i>
                </button>
              </div>
              <div class="clearfix"></div>
              <div class="noPadding col-xs-12 c-margin-t-10 c-font-14"></div>
  
              <div class="clearfix"></div>

              <div class="product-price noPadding col-xs-12   c-margin-t-20 " [innerHtml]=" this.item ?  this.item.langs[this.translate.currentLang].compositionmaterial : '' ">
  
              </div>
              <div class="product-price noPadding col-xs-12   c-margin-t-20 " [innerHtml]=" this.item ?  this.item.langs[this.translate.currentLang].composition : '' ">
  
              </div>
  
              <div class="clearfix"></div>
              <!-- <ng-container *ngIf="(this.item && this.item.pvp_recommended) && this.userLogging">
                <div class="product-price noPadding col-xs-12 c-margin-b-30 c-margin-t-20 ">
                  <div class="row">
                    <div class="col-xs-12 col-md-12">
  
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12" style="padding-left: 0" *ngIf="this.item.pvp_recommended != '0.00'">
                  <label class="c-font-15">{{ 'price_recommend' | translate }}: </label>
                  <span class="c-font-14"> {{ getPriceRecomended(this.item.pvp_recommended) }} €</span>
                </div>
              </ng-container> -->
  
              <div class="product-price noPadding col-xs-12 c-margin-b-30 c-margin-t-20 ">
            <div class="row">
                          <div    class="col-xs-12 col-md-12">
  
              </div>
                          <div *ngIf="this.item && this.item.countryOrigin" class="col-xs-12 col-md-12">
                <label class="c-font-17">{{ 'madein' | translate }}:</label>
                <ng-container *ngIf="this.item.countryOrigin && this.item.countryOrigin === 'Portugal'">
                  <img src="assets/images/pt.png" alt="Portugal" width="12" height="12" style="margin-left: 5px;">
                </ng-container>
                <ng-container *ngIf="this.item.countryOrigin && this.item.countryOrigin === 'Italy'">
                  <img src="assets/images/it.png" alt="Italy" width="12" height="12" style="margin-left: 5px;">
                </ng-container>
                <span class="c-font-17"> {{ this.item.countryOrigin }}</span>
              </div>
                      </div>
          </div>

          <div class="product-price noPadding col-xs-12 c-margin-b-30 c-margin-t-20 share-container"> 
            <div class="row">
                <div class="col-xs-12 col-md-12"></div>
                <!-- 
                <div *ngIf="this.item" class="col-xs-12 col-md-12">
                  <span class="social-label"><b>{{'share' | translate}}:</b></span>
                  <share-buttons theme="circles-dark"
                    [include]="['facebook']"
                    [showIcon]="true"
                    [showText]="false"
                    [url]="this.apiService.PHP_API_URL_DEFAULT + this.router.url"
                    description="Angular Share Buttons"
                    class="">
                  </share-buttons>
                </div>
                -->
                <div class="col-xs-12 col-md-12">
                  <span class="social-label"><b>{{'share' | translate}}:</b></span>
                  
                    <div><a href="https://www.facebook.com/sharer/sharer.php?u={{this.apiService.PHP_API_URL_DEFAULT + this.router.url}}&amp;src=sdkpreparse">
                      <img style="width: 35px; height: 30px;" src="https://www.svgrepo.com/show/158427/facebook.svg" alt="Facebook"></a></div>

                </div>
            </div>
          </div>
            </div>
  
            <div class="clearfix"></div>
            </div>
          </div>
        </div>
</ng-container>

<!-- REVIEWS -->
<ng-container *ngIf="(this.reviews && this.reviews.length == 0)">
  <div class="col-xs-12">
    <div class="col-lg-12 col-xs-12" style="margin-bottom: 12px;margin-top: 12px;">
      <h3 class="c-font-15">{{ 'reviews' | translate }}: ({{totalreviews}})</h3>
      <h4 *ngIf="this.reviews && this.reviews.length == 0" class="c-font-15">{{ 'no_commensts_yet' | translate }}</h4>
    </div>
  </div>
</ng-container>
  <div class="col-xs-12" style="margin-bottom: 12px;margin-top: 12px;" #viewreviews id="viewreviews">
    <ng-container *ngIf="(this.reviews && this.reviews.length > 0)">
      <div class="reviews-info">
        <div class="rating">
          <ngb-rating style="font-size: 25px;" [rate]="this.average_rating" [readonly]="true" [max]="5" tooltipClass="tooltip-custom-class" ngbTooltip="{{average_rating}} {{ 'of' | translate }} 5 {{ 'stars' | translate }}" [starTemplate]="scoreTemplate"></ngb-rating>
          <ng-template #scoreTemplate let-fill="fill">
            <span class="star" [class.full]="fill === 100">
              <span class="half" [style.width.%]="fill">&#9733; </span>&#9733;
            </span>
          </ng-template>
        </div>
        <div class="average-rating">{{ 'average_rating' | translate }}: <span class="rating-desc">{{average_rating}}/5</span></div>
        <div class="total-reviews">{{ 'total_rating' | translate }}: <span class="rating-desc">{{totalreviews}}</span></div>
      </div>
    </ng-container>
    <ng-container *ngIf="userLogging && !hidecomment"> 
        <hr>
        <div class="col-lg-12 col-xs-12" style="margin-bottom: 12px;">
          <h3><b>{{'review_this_product' | translate}}:</b></h3>
          <h4 *ngIf="this.reviews && this.reviews.length == 0">{{ 'no_commensts_yet' | translate }}</h4>
        </div>

        <form [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
          <div class="row container-post-review">
            <div class="col-lg-2 col-sm-12 col-xs-12">
              <div class="title">{{ 'rating' | translate }}:</div>
              <ngb-rating formControlName="rating" [max]="5" style="font-size: 25px;" class="user-rating"></ngb-rating><br>
              <div *ngIf="submittedReview && f.rating.errors" class="invalid-feedback">
                <div *ngIf="f.rating.errors.required"> <span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12 col-xs-12">
              <div class="title">{{ 'comment_title' | translate }}:</div>
              <input type="text" formControlName="title" placeholder="{{ 'title' | translate }}" [ngClass]="{ 'is-invalid': submittedReview && f.title.errors }"  maxlength="250" class="form-control c-square valRequired"/>
              <div *ngIf="submittedReview && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required"><span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12 col-xs-12">
              <div class="title">{{ 'comment_description' | translate }}:</div>
              <textarea rows="1"  maxlength="500" style="width: 100%;resize: vertical;" formControlName="comment" class="form-control" placeholder="{{ 'leave_a_comment' | translate }}"></textarea>
              <div *ngIf="submittedReview && f.comment.errors" class="invalid-feedback">
                <div *ngIf="f.comment.errors.required"><span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 col-xs-12">
              <div class="title">{{ 'add_images' | translate }}:</div>
              <label for="images" class="drop-container">
                <span class="drop-title">{{ 'drop_files_here' | translate }}</span>
                <input type="file" id="images" accept="image/png,image/jpeg" (change)="uploadFile($event)" multiple>
              </label>
            </div>
            <div class="col-lg-1 col-sm-12 col-xs-12">
              <div class="submit-comment-container">
                <button class="btn c-theme-bg c-btn-square c-font-white c-font-14 c-font-lower mt-2 submit-comment" type="submit">{{ 'comment' | translate }}</button>
              </div>
            </div>
            <div class="col-xs-12">
              <ng-container *ngIf="images_64 && images_64.length > 0">
                <div class="images-container">
                  <div class="image-upload-view" *ngFor="let img of images_64; let i = index">
                    <div class="delete-image" (click)="deleteFile(i)"><span>&#10006;</span></div>
                    <img [src]="'data:image/png;base64,'+ img" class="imge-view">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </form>

        <ng-container *ngIf="this.ac_points">
          <ng-container *ngIf="this.ac_points.length >0; else singlepointre">
            <ng-container *ngFor="let point of this.ac_points">
              <div class="col-lg-12 point-container" *ngIf="point.idpointtype == 4" style="margin-top: 10px;">
                <ng-container *ngIf="point.points>1">
                  <span class="points-description">{{('review_points_multiple' | translate).replace(":1:", point.points)}}</span>
                </ng-container>
                <ng-container *ngIf="point.points==1">
                  <span class="points-description">{{('review_points' | translate).replace(":1:", point.points)}}</span>
                </ng-container>
              </div>
              <div class="col-lg-12 point-container" *ngIf="point.idpointtype == 5">
                <ng-container *ngIf="point.points>1">
                  <span class="points-description">{{('review_points_photo_multiple' | translate).replace(":1:", point.points)}}</span>
                </ng-container>
                <ng-container *ngIf="point.points==1">
                  <span class="points-description">{{('review_points_photo' | translate).replace(":1:", point.points)}}</span>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #singlepointre>
            <div class="col-lg-12 point-container" *ngIf="this.ac_points.idpointtype == 4" style="margin-top: 10px;">
              <ng-container *ngIf="this.ac_points.points>1">
                <span class="points-description">{{('review_points_multiple' | translate).replace(":1:", this.ac_points.points)}}</span>
              </ng-container>
              <ng-container *ngIf="this.ac_points.points==1">
                <span class="points-description">{{('review_points' | translate).replace(":1:", this.ac_points.points)}}</span>
              </ng-container>
            </div>
            <div class="col-lg-12 point-container" *ngIf="this.ac_points.idpointtype == 5" style="margin-top: 10px;">
              <ng-container *ngIf="this.ac_points>1">
                <span class="points-description">{{('review_points_photo_multiple' | translate).replace(":1:", this.ac_points.points)}}</span>
              </ng-container>
              <ng-container *ngIf="this.ac_points==1">
                <span class="points-description">{{('review_points_photo' | translate).replace(":1:", this.ac_points.points)}}</span>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
    </ng-container>
    <hr>
    <div class="reviews row">
        <div class="col-lg-4" style="display: table-cell;" *ngFor="let review of reviews">
          <div class="review-container">
            <div class="images-review">
              <div class="no-image" *ngIf="(!review.images || review.images.length == 0); else showimages"><span>{{ 'no_images' | translate }}</span></div>
              <ng-template #showimages>
                <div class="images">
                  <img (click)="openImageReview(i, review.images)" [src]="(this.browserwebp && review.images[0].webp) ? this.apiService.imagesDir+review.images[0].webp : this.apiService.imagesDir+review.images[0].photo" class="image-review">
                </div>
              </ng-template>
            </div>
            <div class="content-review">
              <div class="rating">
                <ngb-rating style="width: fit-content;" [(rate)]="review.rating" [readonly]="true" [max]="5" tooltipClass="tooltip-custom-class" ngbTooltip="{{review.rating}} {{ 'of' | translate }} 5 {{ 'stars' | translate }}"></ngb-rating>
                <div class="comment-username">{{ review.created_at | date:"dd/MM/yyyy HH:mm" }} <span class="text-lowercase">{{ 'by' | translate }}</span> {{review.name }}</div>
              </div>
              <div class="comment-title">{{review.title }}</div>
              <div class="comment-description">{{review.comment }}<span class="viewitemdeltail" (click)="getReviewById(review.iditemreview)"> [ {{'see_more' | translate }}... ]</span></div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 btn-container" *ngIf="this.loadAll == '0'">
          <button class="btn" (click)="getProductReviews(this.translate.currentLang, '1')">{{ 'load_more' | translate }}</button>
        </div>
    </div>
  </div>
  <ng-image-fullscreen-view
    [images]="imageReviews"
    [imageIndex]="imageReviewIndex"
    [show]="showimageReviews"
    (close)="closeImageReviews()">
  </ng-image-fullscreen-view>
<ng-container *ngIf="this.relateditemarr.length>0">
  <div class="col-xs-12" *ngIf="this.relateditemarr && this.relateditemarr.length>0">
    <div class="clearfix"></div>
    <div class="col-xs-12 c-margin-t-50 noPadding">
      <p class="c-font-17 c-font-black c-border-bottom c-border-black"> {{ 'itemrelated' | translate }} </p>


      <ng-container *ngFor="let product of this.relateditemarr; let i_product=index ">
        <div class="col-md-2 col-sm-4 col-xs-12 productWrapper c-margin-b-20 c-bg-white c-related-prod">
          <a rel="noopener" onclick="return false;" [href]='this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(product.langs[this.translate.currentLang].description)+"-"+product.iditem) ' (click)="urlmount(product, product.langs[this.translate.currentLang].description, product.iditem )">
            <div class="imgProd c-border c-border-grey-4">
              <div *ngIf="product.withPromotion && product.withPromotion==1 && this.userLogging" class="prodPromo related c-font-14 c-font-uppercase ccwhite" style="top: 50px;"> -{{ showDiscountRelated(product) }}%</div>
              <img [alt]="product.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="getoneimg(product)">
            </div>
            <div class="prodCont c-border noBorder-top c-border-grey-4">
              <span class="title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center" style="height: 50px;">{{ product.langs[this.translate.currentLang].description }}</span>
                <ng-container>
                  <div class="product-price col-sm-12 col-xs-12 text-center pull-right noPadding-sides noMargin">
                    <del class="c-font-12" *ngIf="  product.withPromotion && product.withPromotion==1  ">{{ getPriceRelated(product, 1) }} €</del>
                    <span ><ins class="c-font-16 c-theme-font"> {{ getPriceRelated(product, 2) }} €</ins></span>&nbsp;
                  </div>
                </ng-container>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
</ng-container>
<!-- <ng-container *ngIf="!this.relateditemarr || this.relateditemarr.length == 0">
  <div class="col-xs-12">
    <div class="clearfix"></div>
    <div class="col-xs-12 c-margin-t-50 noPadding">
      <p class="c-font-17 c-font-black c-border-bottom c-border-black"> {{ 'itemrelated' | translate }}: </p>
  </div>
  </div>
  <ng-container *ngFor="let product of this.loadingOtherprods; let i_product=index ">
    <div class="col-md-2 col-sm-4 col-xs-12 productWrapper c-margin-b-20 c-bg-white c-related-prod">
      <a href="javascript:;">
        <div class="imgProd c-border c-border-grey-4">
          <img [src]="defaultImage">
        </div>
        
      </a>
      <div class="prodCont c-border noBorder-top c-border-grey-4">
        <div class="sketlon-text-container" style="height: 22.84px; margin: 8px 0;">
          <span class="sketlon-text title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center"></span>
        </div>
        <ng-container *ngIf="this.userLogging">
          <div class="product-price col-sm-12 col-xs-12 text-center pull-right noPadding-sides noMargin">
            <span ><ins class="c-font-16 c-theme-font sketlon-text"></ins></span>&nbsp;
          </div>
        </ng-container>
      </div>
    </div> 
  </ng-container>
</ng-container> -->

<modal #ModalReview id="ModalReview">
  <modal-header></modal-header>
  <modal-content>
    <div class="row" *ngIf="this.reviewDetail">
      <div class="col-lg-4 col-xs-4">
        <div class="">
          <div class="no-image" *ngIf="(!reviewDetail.images || reviewDetail.images.length == 0); else showimages"><span>{{ 'no_images' | translate }}</span></div>
          <ng-template #showimages>
            <div class="item">
              <a href="javascript:void(0)" (click)="cicleImgModal(0,1)" style="top: 50%;z-index: 2;cursor: pointer;position:absolute;left:0" class="pull-left"><img style="height: 25px;margin-right: 5px;" alt="<" src="assets/images/lefticon.png"></a>
              <img style="width: 100%;" [src]="(this.browserwebp && this.reviewDetail.images[this.keyImageSelModal].webp) ? this.apiService.imagesDir+this.reviewDetail.images[this.keyImageSelModal].webp :  this.apiService.imagesDir+this.reviewDetail.images[this.keyImageSelModal].photo" alt="">
              <a href="javascript:void(0)" (click)="cicleImgModal(1,1)"   style="top: 50%;z-index: 2;cursor: pointer;position:absolute;right:0" class="pull-right" >  <img style="    height: 25px;    margin-left: 5px;" alt=">" src="assets/images/righticon.png">  </a>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-lg-8 col-xs-8" *ngIf="this.reviewDetail">
        <div class="rating">
          <ngb-rating style="width: fit-content;" [(rate)]="reviewDetail.rating" [readonly]="true" [max]="5" tooltipClass="tooltip-custom-class" ngbTooltip="{{reviewDetail.rating}} {{ 'of' | translate }} 5 {{ 'stars' | translate }}"></ngb-rating>
          <div class="comment-username">{{ reviewDetail.created_at }} {{ 'by' | translate }} {{reviewDetail.name }}</div>
        </div>
        <div class="comment-title">{{reviewDetail.title }}</div>
        <div class="comment-description">{{reviewDetail.comment }}</div>
        <div class="response" *ngIf="reviewDetail.response && reviewDetail.response != ''">
          <hr style="margin:5px">
          <div class="response-author">{{ 'comp_name' | translate }}:</div>
          <div class="response">{{reviewDetail.response }}</div>
        </div>
      </div>
    </div>
  </modal-content>
</modal>