

<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li><a [routerLink]="(['/account'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'myaccount' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'orders' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="c-layout-sidebar-menu c-theme ">

			<!--   -->

			<div class="c-sidebar-menu-toggler">
					<span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
					<a href="javascript:;" class="c-content-toggler" data-toggle="collapse" data-target="#sidebar-menu-1">
						<span class="c-line"></span>
						<span class="c-line"></span>
						<span class="c-line"></span>
					</a>
				</div>
				<ul class="c-sidebar-menu collapse in" id="sidebar-menu-1">
					<li class="c-dropdown c-open">
						<a href="javascript:;" class="c-toggler">{{ 'myaccount' | translate }}
							<span class="c-arrow"></span>
						</a>
						<ul class="c-dropdown-menu">
							<li class="">
								<a [routerLink]="(['/points'] | localize)" href="javascript:void(0);" *ngIf="showPoints">{{ 'my_points' | translate }}</a>
							</li>
							<li class=" ">
								<a [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
							</li>
							<li class=" ">
								<a [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
							</li>
							<li class="c-active  ">
								<a [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
							</li>
                            <li class="">
								<a [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
							</li>
							<li>
								<a (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
							</li>
						</ul>
					</li>
				</ul>

			<!-- -->
			</div>



			<div class="c-layout-sidebar-content ">
			<div class="c-margin-b-40 c-order-history-2">
				<div class="table-responsive">


					<div class="mat-app-background basic-container ">
						<div class="example-container ">
							<div class="example-loading-shade"
							*ngIf="isLoadingResults || isRateLimitReached">
							<mat-spinner *ngIf="isLoadingResults"></mat-spinner>
							<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
								{{ 'ERROR_CONNECT_API' | translate }}
							</div>
						</div>


						  <input matInput     placeholder="{{ 'filter' | translate }}" #inputSearch>


						<div class="example-table-container mat-elevation-z8">

							<table mat-table [dataSource]="dataTable" class="example-table"
							matSort matSortActive="datedocument" matSortDisableClear matSortDirection="desc">

							<ng-container matColumnDef="numberdoc">
								<th style="min-width: 160px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ordernr' | translate }}</th>
								<td mat-cell *matCellDef="let row">
									<span style="min-width: 125px; display: inline-block;">{{row.numberdoc}}</span>
								    <ng-container *ngIf="row.showreviewbtn">
										<ng-container *ngIf="!row.disablebtnreview">
											<a class="btn btn-sm c-btn-sbold btn-review" [routerLink]="(['/itemreview', row.iddocument ] | localize)">{{ 'review_items' | translate }} <i class="icon-arrow-right"></i></a>
										</ng-container>
										<ng-container *ngIf="row.disablebtnreview">
											<a class="btn btn-sm c-btn-sbold btn-review disabled">{{ 'review_items' | translate }} <i class="icon-arrow-right"></i></a>
										</ng-container>
									</ng-container>
								</td>
							</ng-container>


							<ng-container matColumnDef="date">
								<th style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'date' | translate }}</th>
								<td mat-cell *matCellDef="let row">{{row.date }}</td>
							</ng-container>


							<ng-container matColumnDef="total">
								<th style="min-width: 75px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'total' | translate }}</th>
								<td mat-cell *matCellDef="let row">{{row.total.toString().replace('.', ',')  }} {{row.currencysymbol}}</td>
							</ng-container>


							<ng-container matColumnDef="paymentmethod">
								<th style="min-width: 285px;" mat-header-cell *matHeaderCellDef mat-sort-header >
									{{ 'paymentdesc' | translate }}
								</th>
								<td mat-cell *matCellDef="let row">
									<ng-container *ngIf="row.paymentmethod=='MB' && row.state!='Recusado'">
										<ng-container *ngIf="!row.datepayment && !row.cancellationreason">
											<button (click)="showmb(row)" class="btn c-btn-red btn-sm c-btn-sbold    btn-danger" style="border-radius:0;">{{ 'mbbutton' | translate }}</button>
										</ng-container>
										<ng-container *ngIf="row.datepayment || row.cancellationreason">
											{{ row.paymentmethod | translate }}
										</ng-container>
									</ng-container>
									<ng-container *ngIf="row.paymentmethod!='MB' && row.state!='Recusado'">
										<ng-container *ngIf="row.paymentmethod=='TB' || row.datepayment || row.cancellationreason">
											{{ row.paymentmethod | translate }}
										</ng-container>
										<ng-container *ngIf="!row.datepayment && !row.cancellationreason && !row.iddocinvoice">
											<br>
											<button (click)="changePaymentMean(row)" class="btn c-btn-red btn-sm c-btn-sbold    btn-danger" style="border-radius:0; margin-bottom: 5px;">{{ 'choose_method_resume' | translate }}</button>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="  row.state=='Recusado'">
										{{ row.paymentmethod | translate }}
									</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="shipmode">
								<th style="min-width: 80px;" mat-header-cell *matHeaderCellDef mat-sort-header  >
									{{ 'shipmode' | translate }}
								</th>
								<td mat-cell *matCellDef="let row">
									<span *ngIf="!row.trackingnumber && !row.trackingurl">{{row.shipmode }} </span>
									<span *ngIf="row.trackingnumber && !row.trackingurl">{{row.shipmode }} <br> {{row.trackingnumber }}</span>
								<span><a *ngIf="row.trackingnumber && row.trackingurl" class="tracking_number" href='{{row.trackingurl }}' target='_blank'>{{row.shipmode }} {{row.trackingnumber }}</a> </span>
							</td>
							</ng-container>

							<ng-container matColumnDef="state">
								<th style="min-width: 90px;" mat-header-cell *matHeaderCellDef mat-sort-header  >
									{{ 'state' | translate }}
								</th>
								<td mat-cell *matCellDef="let row">
									<ng-container *ngIf="(!row.datepayment && !row.cancellationreason && row.state!='Recusado' && !row.iddocinvoice ); else otherstate">
										<span *ngIf="(!row.datepayment && !row.cancellationreason && row.state!='Recusado' && !row.iddocinvoice )">{{ 'waiting_payment' | translate }}</span>
									</ng-container>
									<ng-template #otherstate>
										<span *ngIf="row.datepayment && row.state=='Emitido' else defaultstate">{{ 'payd' | translate }}</span>
										<ng-template #defaultstate>
											<span>{{ row.statelang[this.translate.currentLang] ? row.statelang[this.translate.currentLang] : row.state }}</span>
										</ng-template>
									</ng-template>
								</td>
							</ng-container>
							
							<ng-container matColumnDef="icon">
								<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>

								</th>
								<td mat-cell *matCellDef="let row" style="max-width: 165px;">


									<a style="padding:2px 8px 0px 8px;" class="btn btn-xs c-btn-yellow c-btn-uppercase c-btn-bold c-btn-square" [href]="this.apiService.PHP_API_SERVER+'?controller=order&action=get&doc='+btoa(row.iddocument)" target="_blank">
																			<i class="fa fa-file-pdf-o noPadding noMargin"></i>
																		</a>
								
									



								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
					</div>

					<mat-paginator [hidePageSize]="true" [length]="resultsLength" [pageSize]="10"></mat-paginator>
				</div>
			</div>


		</div>
</div>
</div>
</div>
</div>


<modal #ModalChangePayment    >

		<modal-header>
     {{ 'changepaymentmean' | translate  }}
    </modal-header>

  <modal-content>


    <div class=" row">
      <div class="  col-md-12 col-xs-12 col-sm-12">
        <ng-container   *ngIf="this.payments">
          <ng-container   *ngFor="let payment of this.payments;">
            <div   class="c-radio">
              <input value="{{ payment.idpaymentwebsite }}" (change)="this.selectPayment=payment.idpaymentwebsite;selectPaymentAbbr=payment.abbr"  abrv="{{ payment.abbr }}" type="radio" name="payment"   class="c-radio" id="radioP{{ payment.idpaymentwebsite }}">
              <label class="c-font-bold c-font-14" for="radioP{{ payment.idpaymentwebsite }}">
                <span class="inc"></span><span class="check"></span>
                <span class="box"></span> {{ payment.abbr | translate  }}
              </label>
            </div>



						<ng-container *ngIf="selectPaymentAbbr=='MBW' && this.selectPayment==payment.idpaymentwebsite ">
							<label class="control-label" for="telephone">{{ 'telephone' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
							<input type="text" [required]="this.selectPayment==payment.idpaymentwebsite ? 'true' : 'false'"   [(ngModel)]="contat_MBW" name="contat_MBW"  maxlength="20" id="contat_MBW"   class="form-control c-square valRequired valNumber" />
						</ng-container>


          </ng-container>
        </ng-container>



      </div>
    </div>


  </modal-content>

  <modal-footer>
    <ng-container   *ngIf="this.selectPayment">
      <button type="button"    (click)="changepayment()"   class="pull-right btn btn-lg c-theme-btn c-btn-square c-btn-uppercase c-btn-bold ">{{ 'validate' | translate }}</button>
    </ng-container>
    </modal-footer>
</modal>

<ng-container *ngIf="!this.hiddenLoad">
<div id="loading" [hidden]="this.hiddenLoad" style=" opacity: 0.9;">
	<div class="mybox" id="loading-image">
		<div class="loada" id="loadingGeral"  ></div>
		<p class="noMargin">&nbsp;</p>
		<h3 class="title_h3 " id="txtLoad"></h3>
	</div>
</div>
<ng-container>
