<!-- breadcrumbs area start -->

<div class="c-content-box c-size-xs c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="row">
			<div class="col-md-12 c-margin-t-10">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'login' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>




<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="row">


			<div class="col-md-6 leftDivider hidden-sm hidden-xs rightPadding">
				<span class="title_h3 c-font-16 helveticaltsd c-font-bold">{{ 'create_account' | translate }}</span>
				<p class="c-font-14" style="padding-top: 2px;" [innerHtml]="'createaccounttext' | translate"></p>
				<div class="clearfix"></div>
				<a [routerLink]="(['/register'] | localize)" href="javascript:void(0);" class="btn c-theme-bg btn-lg c-font-uppercase c-font-white c-btn-bold c-btn-square c-btn-signup c-font-14 helveticaltsd c-margin-t-10">
					<i class="fa fa-user-plus" style="top: -2px;position: relative;"></i>
					<span>{{ 'register' | translate }}</span>
				</a>
			</div>




			<div class="col-md-6 rightDivider leftPadding">
				<span class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20">{{ 'clientarea' | translate }}</span>
				<form  [formGroup]="signinForm" (ngSubmit)="onSubmit()">
					<div class="form-group">
						<label class="control-label" for="email">{{ 'email' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
						<input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (change)="changeEmailClear($event)" formControlName="email" id="email" class="form-control c-square valRequired valEmail" />
						<div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
						   <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
						   <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
						</div>
					</div>
					<div class="form-group">
						<label class="control-label" for="password">{{ 'password' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
						<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
						<div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
							<div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
							<div *ngIf="f.password.errors.minlength">{{ 'min6char' | translate }}</div>
						</div>
					</div>


					<div class="form-group row">
						<div class="form-group col-sm-6 col-xs-6 pad0">
							<div class="c-checkbox">
								<input id="checkbox15" formControlName="rememberme" name="rememberme" class="c-check" type="checkbox">
								<label for="checkbox15" class="c-font-14 helveticaltsd">
									<span class="inc"></span>
									<span class="check"></span>
									<span class="box"></span> {{ 'remember_me' | translate }}</label>
							</div>
						</div>
						<div class=" col-md-6 col-xs-6 text-right float-left">
							<a (click)="openModal(ModalPassword)" href="javascript:void(0)" class="c-btn-forgot c-font-12 helveticaltsd "> {{ 'forgot_your_password' | translate }}</a>
						</div>
					</div>

					<div class="clearfix"></div>
					<div style="margin-bottom: 25px;" class="form-group">
						<div class="col-md-12 row  ">
							<button type="submit" class="btn c-btn-black btn c-font-uppercase c-btn-bold c-btn-slim c-btn-border-2x c-btn-square c-btn-signup c-font-14 helveticaltsd "><span>{{ 'login' | translate }}</span></button>
 						</div>
					</div>
					<div class="col-md-12 row points-description" *ngIf="this.signinpoints && this.signinpoints.points">
						<span>
							<ng-container *ngIf="signinpoints.points>1">
								{{('signin_points_multiple' | translate).replace(":1:", signinpoints.points)}}
							</ng-container>
							<ng-container *ngIf="signinpoints.points==1">
								{{('signin_points' | translate).replace(":1:", signinpoints.points)}}
							</ng-container>

						</span>
					</div>
				</form>
			</div>

			<div class="col-md-6 leftDivider d-md-none hidden-lg hidden-md d-sm-block d-md-block " style="margin-top: 10px;">
				<span class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20">{{ 'create_account' | translate }}</span>
				<p  [innerHtml]="'createaccounttext' | translate"></p>
				<div class="clearfix"></div>
				<a [routerLink]="(['/register'] | localize)" href="javascript:void(0);" class="btn c-theme-bg btn-lg c-font-uppercase c-font-white c-btn-bold c-btn-square c-btn-signup c-font-14 helveticaltsd">
					<i class="fa fa-user-plus" style="top: -2px;position: relative;"></i>&nbsp;
					<span>{{ 'register' | translate }}</span>
				</a>
			</div>
		</div>
	</div>
</div>



<modal #ModalPassword  class="modalRemoveFooter">
    <modal-header>

    </modal-header>

    <modal-content>
			<div class=" row">
				<div class="  col-md-12 col-xs-12 col-sm-12">
			<span class="title_h3 c-font-24 c-font-sbold full-width">{{ 'recover_password' | translate }}.</span>
			<div class="full-width"><p class="full-width">{{ 'email_to_retrieve_password' | translate }}</p></div>
			<div class="form-group full-width">
				<input type="email" #emailrecoverPass class="form-control  border-grey input-lg c-square valRequired valEmail" name="email" placeholder="{{ 'write_your_email' | translate }}">
			</div>
			<div class="form-group full-width">
				<button type="button" (click)="recoverPass()" class="pull-right  btn c-btn-black c-btn-slim  c-btn-square c-btn-signup">{{ 'send' | translate }}</button>
			</div>
				</div>
			</div>
    </modal-content>

    <modal-footer>



    </modal-footer>
</modal>
