import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch } from '../must-match.validator';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Title, Meta }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { TitlechangeService } from '../titlechange.service';
import { LocalstorageService } from '../localstorage.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  resetForm:FormGroup;
  submitted = false;
  token:any;
  subscribe:any;
  constructor(public titlechangeService:TitlechangeService,private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title, private route: ActivatedRoute, private router: Router,public apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder,private localstorageService: LocalstorageService) {

  this.localstorageService.set("previouspage", '/resetpassword');
	this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]

    }, {
		validator: [MustMatch('password', 'confirmPassword')]
	});

	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/resetpassword');
      this.metatags(lang.lang);
    });

  if(!this.route.snapshot.paramMap.get('token')){
    this.router.navigate([this.localize.translateRoute('/signin')] );
  }
  this.token = this.route.snapshot.paramMap.get('token');

  }

  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle);this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(9,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }
        /*Object.keys(obj.info).forEach(key => {
          this.metaService.removeTag('name="'+obj.info[key].name+'"');
        });*/
        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }



  get f() { return this.resetForm.controls; }

  ngOnInit(): void {
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.localize.parser.currentLang+'/resetpassword');
	this.metatags(this.localize.parser.currentLang);
  }
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    var formData = new FormData();
    Object.keys(this.resetForm.controls).forEach(key => {
      if(this.resetForm.controls[key].value){
        formData.append("userweb["+key+"]", this.resetForm.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });
    formData.append("userweb[token]", this.token);
    this.apiService.changepassword(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
          this.submitted = false;
          Swal.fire(translations);
          this.router.navigate([this.localize.translateRoute('/signin')] );
        });
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });

  }

}
