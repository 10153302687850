

<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li><a [routerLink]="(['/account'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'myaccount' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'editinfo' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="c-layout-sidebar-menu c-theme ">
			<!--   -->

			<div class="c-sidebar-menu-toggler">
					<span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
					<a href="javascript:;" class="c-content-toggler" data-toggle="collapse" data-target="#sidebar-menu-1">
						<span class="c-line"></span>
						<span class="c-line"></span>
						<span class="c-line"></span>
					</a>
				</div>
				<ul class="c-sidebar-menu collapse in" id="sidebar-menu-1">
					<li class="c-dropdown c-open">
						<a href="javascript:;" class="c-toggler">{{ 'myaccount' | translate }}
							<span class="c-arrow"></span>
						</a>
						<ul class="c-dropdown-menu">
							<li class="">
								<a [routerLink]="(['/points'] | localize)" href="javascript:void(0);" *ngIf="this.showPoints">{{ 'my_points' | translate }}</a>
							</li>
							<li class=" ">
								<a [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
							</li>
							<li class="c-active  ">
								<a [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
							</li>
							<li class=" ">
								<a [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
							</li>
                            <li class="">
								<a [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
							</li>
							<li>
								<a (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
							</li>
						</ul>
					</li>
				</ul>

			<!-- -->
		</div>




		<div class="c-layout-sidebar-content ">
			<div class="row">
				<div class="col-xs-12 c-margin-b-20">
					<form  [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                        <div class="">
							<div class="row">
<input type="hidden" formControlName="type" name="type">
								<div class="form-group col-md-4">
									<label class="control-label" for="compname">{{ 'companynamereg' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
									<input [readonly]="this.userInfo && (this.userInfo.name &&  this.userInfo.name!='') ? 'readonly' : ''" type="text" [ngClass]="{ 'is-invalid': submitted && f.compname.errors }" formControlName="compname"   maxlength="50" id="compname" class="form-control c-square valRequired " />
									<div *ngIf="submitted && f.compname.errors" class="invalid-feedback">
										 <div *ngIf="f.compname.errors.required">{{ 'msg_valRequired' | translate }}</div>
									 </div>
								</div>
								<div class="form-group col-md-4">
									<label class="control-label" for="obs">{{ 'name' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
									<input [readonly]="this.userInfo && (this.userInfo.obs && this.userInfo.obs!='') ? 'readonly' : ''" type="text" [ngClass]="{ 'is-invalid': submitted && f.obs.errors }" formControlName="obs" maxlength="32" id="obs" class="form-control c-square valRequired valIgnore" />
									<div *ngIf="submitted && f.obs.errors" class="invalid-feedback">
										 <div *ngIf="f.obs.errors.required">{{ 'msg_valRequired' | translate }}</div>
									 </div>
								</div>

								<div class="form-group col-md-4">
                                    <label class="control-label">{{ 'dateofbirth' | translate }}</label>
																		<ng-container *ngIf="this.userInfo && (this.userInfo.dateofbirth &&  this.userInfo.dateofbirth!='')">
																		<input   readonly  [value]="this.userInfo.dateofbirth" class="form-control c-square   valIgnore">
																		</ng-container>

																		<ngx-datepicker [style]="(this.userInfo && (this.userInfo.dateofbirth &&  this.userInfo.dateofbirth!='')) ? 'display:none' : ''"  [options]="config" formControlName="dateofbirth"></ngx-datepicker>



								</div>

                            </div>

                            <div class="row">
                                <div class="form-group col-md-12">
																	<label class="control-label" for="address">{{ 'address' | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
																	<input type="text" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address" id="address" class="form-control valRequired c-square" />
																		<div *ngIf="submitted && f.address.errors" class="invalid-feedback">
																		 <div *ngIf="f.address.errors.required">{{ 'msg_valRequired' | translate }}</div>
																	 </div>
								</div>
								<div class="form-group col-md-12">
									<label class="control-label" for="address2">{{ 'addresscomplement' | translate }} </label>
									<input type="text" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" formControlName="address2"   maxlength="32" id="address2" class="form-control c-square"/>
										<div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
										 <div *ngIf="f.address2.errors.required">{{ 'msg_valRequired' | translate }}</div>
									 </div>
								</div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="form-group col-md-6">
																					<label class="control-label" for="postalcode">{{ "postalcod" | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
																					<input type="text" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" formControlName="postalcode" maxlength="32"  id="postalcode" (change)="getpostalcode()" class="form-control valRequired c-square" />
																					<div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
																						 <div *ngIf="f.postalcode.errors.required">{{ 'msg_valRequired' | translate }}</div>
																					 </div>
										</div>
                                        <div class="form-group col-md-6">
																					<label class="control-label" for="locality">{{ "locality" | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
																					<input type="text"  [ngClass]="{ 'is-invalid': submitted && f.locality.errors }" formControlName="locality" maxlength="32"  id="locality" class="form-control valRequired c-square" />
																					<div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
																						 <div *ngIf="f.locality.errors.required">{{ 'msg_valRequired' | translate }}</div>
																						 <div *ngIf="f.locality.errors.maxlength">{{   ('msg_maxval' | translate).replace(":1:", 32)    }}</div>
																					 </div>
										</div>
                                    </div>
                                </div>
                            </div>
							<div class="row">
                                <div class="form-group col-md-6">
																	<label class="control-label full-width float-left">{{ 'country' | translate }}</label>
																	<select *ngIf=" this.countries "   [ngClass]="{ 'is-invalid': submitted && f.idcountry.errors }" formControlName="idcountry"   id="idcountry" class="form-control c-square c-theme countryControl full-width float-left" >
																		<option value="" >{{ 'selectcountry' | translate }}</option>

																			<ng-container *ngFor="let country of this.countries ;  ">
																									<option value="{{ country.fullid }}"  >{{ country.description[this.translate.currentLang] }}</option>
																			</ng-container >
																		</select>
																		<div *ngIf="submitted && f.idcountry.errors" class="invalid-feedback">
																			 <div *ngIf="f.idcountry.errors.required">{{ 'msg_valRequired' | translate }}</div>
																		 </div>
                                </div>
								<div class="form-group col-md-6">
									<label class="control-label" for="nif"><span class="niftext">{{ 'nif' | translate }}</span><span class="niffrtext" style="display:none;">{{ 'nif_fr' | translate }}</span><i *ngIf="f.type.value==1" class="c-theme-font fa fa-asterisk"></i></label>
									<input type="text"  [attr.disabled]="(this.userInfo && this.userInfo.nif && this.userInfo.nif!='') ? 'disabled' : null" [ngClass]="{ 'is-invalid': submitted && f.nif.errors }" formControlName="nif" id="nif" class="form-control c-square nifControl " />
						<div *ngIf="submitted && f.nif.errors" class="invalid-feedback">
						 <div *ngIf="f.nif.errors.NifValidatorPT">{{ 'msg_valNif' | translate }}</div>
						 <div *ngIf="f.nif.errors.NifRequired">{{ 'msg_valRequired' | translate }}</div>
					 </div>
								</div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="form-group col-md-6">
																					<label class="control-label" for="email">{{ 'email' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
																					<input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" id="email" class="form-control c-square valRequired valEmail" />
																					<div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
																						 <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
																						 <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
																					 </div>
										</div>
                                        <div class="form-group col-md-6">
																					<label class="control-label" for="telephone">{{ 'telephone' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
																					<input type="text"   maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.mobilephone.errors }" formControlName="mobilephone" id="telephone" class="form-control   c-square valRequired valNumber" />
																					<div *ngIf="submitted && f.mobilephone.errors" class="invalid-feedback">
																					 <div *ngIf="f.mobilephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
																					</div>
										</div>
                                    </div>
                                </div>
                            </div>
							<div class="row">
								<div class="col-md-12">
								<b>{{	'change_password_two_fields' | translate }}</b>
									</div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="form-group col-md-6">
																					<label class="control-label" for="password">{{ 'password' | translate }}</label>
																					<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
																						<div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
																						 <div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
																						 <div *ngIf="f.password.errors.minlength">{{ 'min6char' | translate }}</div>
																					 </div>
										</div>
                                        <div class="col-md-6">
																					<label class="control-label" for="passwordconf">{{ 'confirmpassword' | translate }}</label>
																					<input type="password" id="passwordconf" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" formControlName="confirmPassword" class="form-control c-square valRequired valMatch" />
																						<div *ngIf="this.submitted && f.confirmPassword.errors" class="invalid-feedback">
																						 <div *ngIf="f.confirmPassword.errors.required">{{ 'msg_valRequired' | translate }}</div>
																						 <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'msg_valMatchP' | translate }}</div>
																					 </div>
										</div>
                                    </div>
                                </div>
                            </div>


                            <div class="row c-margin-t-30">
                                <div class="form-group col-md-12 text-right" role="group">
                                    <button class="btn btn-lg c-theme-btn c-btn-square c-btn-uppercase c-btn-bold" type="submit">{{ 'save' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
				</div>
			</div>

		</div>





	</div>
</div>
