import { Component, OnInit, OnDestroy ,ViewChild, HostListener, ViewEncapsulation  } from '@angular/core';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2'

import { ModalManager } from 'ngb-modal';
import { SharedService } from '../shared.service';
import { Title, Meta, DomSanitizer }     from '@angular/platform-browser';
import {Router} from "@angular/router";
import { LocalstorageService } from '../localstorage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { CheckwebpService } from '../checkwebp.service';
import { OwlOptions  } from 'ngx-owl-carousel-o';

import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit,OnDestroy {
  @ViewChild('ModalDetail') ModalDetail;

  browserwebp:any;
  objectKeys = Object.keys;
  modalDetailRef:any;
  selectItem:any;
  banners: any;
  items:any;
  subscribe:any;
  carouselOptions: OwlOptions  = {
      nav : false,
      dots: true,
      autoplay: true,
      loop: false,
      rewind: true,
      items:1,
      autoWidth:true,
       autoHeight:true,
        autoplayTimeout: 5000,
		autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
  }
  carouselOptionsItems: OwlOptions = {
      loop: false,
  		margin:0,
      autoplay: true,
      navText: ['<div class="prevBtn"><img alt="<" src="assets/images/lefticon.png"></div>', '<div class="nextBtn"><img alt=">" src="assets/images/righticon.png"></div>'],
  		nav:true,
      autoHeight:true,
  		items: 2
  }
  carouselOptionsDetail: OwlOptions = {
        loop:true,
		margin:0,
    autoplay: true,
    autoHeight: true,
    autoWidth: true,
        navText: ['<div class="prevBtn"><img alt="<" src="assets/images/lefticon.png"></div>', '<div class="nextBtn"><img alt=">" src="assets/images/righticon.png"></div>'],
		nav:true,
		items: 1
    }
    deviceInfo:any;
    deviceDesktop:any;
    defaultImage:any;
    defaultImage470:any;
    defaultImage900:any;
    userLoggingName:any;

    loadedbanners: boolean = true;

  constructor(public titlechangeService:TitlechangeService,protected sanitizer: DomSanitizer, private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public checkwebp: CheckwebpService,private seoService: SeoService, public localize: LocalizeRouterService, private deviceService: DeviceDetectorService, public localstorageService: LocalstorageService, private router: Router,  private metaService: Meta,  public titleService: Title,public apiService: ApiService, private modalService: ModalManager, public translate: TranslateService, public sharedService: SharedService) {
    this.banners=[];
    this.deviceDesktop=false;
    const isDesktopDevice = this.deviceService.isDesktop();
    if(isDesktopDevice){
      this.deviceDesktop=true;
    }

    this.localstorageService.remove('createBreadcrumbsAll');

    this.browserwebp = this.checkwebp.canUseWebP();
    if(this.browserwebp){
      this.defaultImage = "assets/images/imgdefault.webp";
      this.defaultImage470 = "assets/images/470x350.webp";
      this.defaultImage900 = "assets/images/900x620.webp";
    }else{
      this.defaultImage = "assets/images/imgdefault.png";
      this.defaultImage470 = "assets/images/470x350.jpg";
      this.defaultImage900 = "assets/images/900x620.jpg";
    }

    let w = window.innerWidth;
    if(w>=600){
      this.carouselOptionsItems.items = 3;
    }
    if(w>=1000){
      this.carouselOptionsItems.items = 5;
    }
    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
    //  this.getWarning(lang.lang);
      this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'');
      this.metatags(lang.lang);
    });

    if(this.localstorageService.get("userLoggingName")){
      this.userLoggingName=this.localstorageService.get("userLoggingName");
    }

  }



  getWarning(lang){
    this.apiService.getWarning(lang, 0).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(!obj.info || Object.values(obj.info).length==0){
          return 0;
        }
        let warning_ =  this.localstorageService.get("warning_");
        let datenow:any = new Date();
        datenow = datenow.toISOString().split('T')[0];
        let newwar = [];
        if(warning_){
          newwar = warning_;
          let entry = false;
          for (let index = 0; index < warning_.length; index++) {
             if(warning_[index][0]==obj.info.idwebsitewarning.toString() && warning_[index][1]==datenow){
               entry = true; break;
             }
             if(warning_[index][0]==obj.info.idwebsitewarning.toString()){
               newwar.splice(index, 1);
             }
          }
          if(!entry){
            newwar.push([obj.info.idwebsitewarning.toString(), datenow]);

            this.localstorageService.set("warning_", newwar);
            Swal.fire({
              title: obj.info.name,
              imageUrl: this.apiService.imagesDir+obj.info.image,
              imageWidth: 800,
              html: obj.info.description,
              allowOutsideClick: true,
  		          position : 'center'
             });
          }
        }else{

          this.localstorageService.set("warning_", [[obj.info.idwebsitewarning.toString(), datenow]]);

          Swal.fire({
            title: obj.info.name,
            imageUrl: this.apiService.imagesDir+obj.info.image,
            imageWidth: 800,
            html: obj.info.description,
            allowOutsideClick: true,
		          position : 'center'
           });


        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  transform_html(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();

    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }

  public setTitle( newTitle: string) {
  let e = document.createElement('textarea');
  e.innerHTML = newTitle;
  let decoded = e.childNodes.length === 0 ? newTitle : e.childNodes[0].nodeValue;
	  this.titleService.setTitle( decoded ); this.titlechangeService.send();
  }

  urlStringconvert(url:any, name_promo:any, id_banner:any){
    if(url==""){
      return false;
    }
    console.log("NAME PROMO: ",name_promo)
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2];


    this.angulartics2.eventTrack.next({
    action: 'promo_click',
    properties: {
      label: 'promo_click',
      content_name: name_promo,
      content_ids: id_banner,
      value: id_banner,
      "event":"Internal Promotions",
      gtmCustom:{
        'ecommerce': {
          'purchase': undefined,
          'click': undefined,
          'add': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'remove': undefined
        }
      }
    }
  });

    if(result==this.apiService.PHP_API_URL_DEFAULT){
      let r = decodeURI(url.replace(this.apiService.PHP_API_URL_DEFAULT, ""));
      this.router.navigateByUrl(r );
    }else{
      window.location.href = url;
    }
  }




  metatags(lang){
    if(!lang){
      return;
    }
    this.apiService.getMetatags(1,9,lang, 'MENU_HOME').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }


        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  checkoutGo(){
    this.modalService.close(this.modalDetailRef);
  }

  ngOnInit(): void {

    this.getWarning(this.localize.parser.currentLang);
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'');

    this.seoService.clearAlternateUrl();
		this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt', 'pt');
		this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en', 'en');
		this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr', 'fr');
		this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr', 'x-default');

      this.getBanners();


      this.getItemsHomePage(this.localize.parser.currentLang);
      this.metatags(this.localize.parser.currentLang);
  }

  ngAfterViewInit():void{


  }



  getItemsHomePage(lang: String){
    return 0;
    this.apiService.getItemsHomePage(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.items = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getBanners(){
    this.apiService.getBanners().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.banners = obj.info;
        setTimeout(() => {
          this.loadedbanners = true;
        }, 100)
      }else{
        this.loadedbanners = true;
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  loadImg(item: any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        if(this.browserwebp && item.colors[item.colorpriority][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[item.colorpriority][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[item.colorpriority][key].images[0].photo;
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        if(this.browserwebp && item.colors[color][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[color][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[color][key].images[0].photo;
      }
    }else{
        if(this.browserwebp && item.images[0].webp){
          return this.apiService.imagesDir+item.images[0].webp;
        }
        return this.apiService.imagesDir+item.images[0].photo;
    }
  }


  getColorSelect(item: any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        return item.colorpriority;
      }else{
        let color = Object.keys(item.colors)[0];
        return color;
      }
    }
    return 0;
  }


  getBackgorundColor(color:any){
    let key = Object.keys(color)[0];
    if(this.browserwebp && color[key].images[0].webp){
      return this.apiService.imagesDir+color[key].images[0].webp;
    }
    return  this.apiService.imagesDir+color[key].images[0].photo ;
  }

  showPrice(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceOriginalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceOriginalTxt;
        }
      }else{
        return item.priceOriginal;
      }
    }else{
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceFinalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinalTxt;
        }
      }else{
        return item.priceFinal;
      }
    }

  }

  showDiscount(item:any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        return  Math.round(100-( 100*item.colors[item.colorpriority][key].priceFinal/item.colors[item.colorpriority][key].priceOriginal) );
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        return Math.round(100-( 100*item.colors[color][key].priceFinal/item.colors[color][key].priceOriginal) );
      }
    }else{
      return Math.round(100-(100*item.priceFinal/item.priceOriginal) );
    }
  }



  openModal(item){
    this.selectItem = item;


    if(item.itemwithattr==1){
      if(item.colorpriority && item.colors[item.colorpriority] ){
        this.selectItem.colorActive = item.colorpriority;
      }else{
        this.selectItem.colorActive = Object.keys(item.colors)[0];
      }
      this.selectItem.sizeActive = Object.keys(item.colors[this.selectItem.colorActive])[0];
    }
        this.modalDetailRef = this.modalService.open(this.ModalDetail, {
            size: 'md',
            windowClass: 'modal-md'  ,
            hideCloseButton: false,
            centered: true,
            animation: true,
            keyboard: false,
            closeOnOutsideClick: false,
			backdropClass: 'modal-backdrop'
        })
    }

	cicle(color: any){
	  let key = Object.keys(color.value)[0];
	  return color.value[key].images;
	}

    closeModal(){
        this.modalService.close(this.modalDetailRef);
    }

	loadPriceFastBuy(op:Number){
	  if(!this.selectItem){
		return 0;
	  }
	  if(op==1){
		if(this.selectItem.itemwithattr==1){
		  return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceOriginalTxt;
		}else{
		  return this.selectItem.priceOriginalTxt;
		}
	  }else{
		if(this.selectItem.itemwithattr==1){
		  return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinalTxt;
		}else{
		  return this.selectItem.priceFinalTxt;
		}
	  }
	}

  changeColor(color){
    this.selectItem.colorActive = color;
    this.selectItem.sizeActive = Object.keys(this.selectItem.colors[this.selectItem.colorActive])[0];
  }

  changeSize(size){
    this.selectItem.sizeActive =size;
  }
	showcolorInfo(color:any, op:any){
	  let key = Object.keys(color.value)[0];
	  if(op==1){
		return color.value[key].code;
	  }else{
		if(!color.value[key].langs[this.localize.parser.currentLang]){
		  return color.value[key].name;
		}
		return color.value[key].langs[this.localize.parser.currentLang].name;
	  }
	}

    buyProduct(){
      let arr:any=[];
      let obj: any = {};
      if(this.selectItem && this.selectItem.itemwithattr==1){
        obj.idcolor = this.selectItem.colorActive;
        obj.idsize = this.selectItem.sizeActive;
        obj.fullcode = this.selectItem.code+"-"+obj.idcolor+"-"+obj.idsize;
        obj.idcolor_integration = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].idcolor_integration ;
        obj.idsize_integration = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].idsize_integration ;
      }else{
        obj.fullcode = this.selectItem.code;
      }
      obj.iditem = this.selectItem.iditem;
      obj.idmanager_company = this.selectItem.idmanager_company;
      obj.iditem_integration = this.selectItem.iditem_integration;
      obj.quantity = 1;
      arr.push(obj);
      this.apiService.addCart(arr).subscribe((obj: any)=>{

        if(typeof obj!=="undefined" && obj.code==200){
          this.sharedService.sendClickEvent(this.localize.parser.currentLang);
          this.translate.get( obj.msg ).subscribe(translations => {
          Swal.fire({
            position: 'top-end',
            customClass: {
              container: 'swal-index'
            },
            icon: 'success',
            title: translations,
            showConfirmButton: false,
            timer: 1000
          });
          });
        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }



}
