


<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'myaccount' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>




<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="c-layout-sidebar-menu c-theme ">


			<!--   -->

			<div class="c-sidebar-menu-toggler">
					<span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
					<a href="javascript:;" class="c-content-toggler" data-toggle="collapse" data-target="#sidebar-menu-1">
						<span class="c-line"></span>
						<span class="c-line"></span>
						<span class="c-line"></span>
					</a>
				</div>
				<ul class="c-sidebar-menu collapse in" id="sidebar-menu-1">
					<li class="c-dropdown c-open">
						<a href="javascript:;" class="c-toggler">{{ 'myaccount' | translate }}
							<span class="c-arrow"></span>
						</a>
						<ul class="c-dropdown-menu">
							<li class="">
								<a [routerLink]="(['/points'] | localize)" href="javascript:void(0);" *ngIf="this.showPoints">{{ 'my_points' | translate }}</a>
							</li>
							<li class=" c-active ">
								<a [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
							</li>
							<li class=" ">
								<a [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
							</li>
							<li class=" ">
								<a [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
							</li>
                            <li class="">
								<a [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
							</li>
							<li>
								<a (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
							</li>
						</ul>
					</li>
				</ul>

			<!-- -->


		</div>
		<div class="c-layout-sidebar-content " *ngIf="this.userInfo">
			<div class="row">
				<div class="col-md-4 col-sm-6 col-xs-12 c-margin-b-20">
					<span class="title_h3 c-font-uppercase c-font-bold">{{ this.userInfo.name }}</span>
					<p class="c-font-uppercase c-font-bold c-font-14 noMargin">{{ this.userInfo.obs }}</p>
					<ul class="list-unstyled">
						<li>
						{{ this.userInfo.address }}  {{ this.userInfo.address2 }}<br/>
						{{ this.userInfo.postalcode }} {{ this.userInfo.locality }}<br/>
						{{ this.userInfo.countryDesc }}
						</li>
						<li><span class="niftext">{{ 'nif' | translate }}</span>: {{ this.userInfo.nif }}</li>
						<li>{{ 'telephone' | translate }}: {{ this.userInfo.mobilephone }}</li>
						<li>{{ 'email' | translate }}: {{ this.userInfo.email }}</li>
					</ul>
				</div>
				<div  class="col-md-2 col-sm-4 col-xs-12 c-margin-b-20">
					<span *ngIf="this.userInfo.rappel>0" class="title_h5 c-font-uppercase c-font-bold">{{ getRappel() }}</span>
				</div>
			</div>
		</div>
	</div>
</div>
