import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
 import { ModalManager } from 'ngb-modal';
import { Options } from 'select2';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch, NifValidatorPT } from '../must-match.validator';
import { LocalstorageService } from '../localstorage.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
declare var $: any;
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-showorder',
  templateUrl: './showorder.component.html',
  styleUrls: ['./showorder.component.scss']
})
export class ShoworderComponent implements OnInit {
  @ViewChild('ModalChangePayment') modalChangePayment;
   userForm:FormGroup;
  resolveCaptcha:any;
  submitted = false;
  countries:any;
  telephone_callingcode:any;
  optionsCalling: Options;
  contat_MBW:any;
  modalChangePaymentRef:any;
  selectPaymentAbbr:any;
  idorder:any;
  orderInfo:any;
  payments:any;
  selectPayment:any;
  wait:any;
  storedIDS:any;
  subscribe:any;
  firstRate:number = 0;
  alreadyClient:any;
  constructor(public titlechangeService:TitlechangeService,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title,private router: Router, public localstorageService: LocalstorageService, private route: ActivatedRoute, public apiService: ApiService, public translate: TranslateService, private modalService: ModalManager, private formBuilder: FormBuilder) {
    this.idorder = this.route.snapshot.paramMap.get('orderid');
    this.wait=false;
    this.storedIDS = JSON.parse(this.localstorageService.get("orderspc"));
    this.alreadyClient=false;
    if(this.localstorageService.get("userLogging")==true){
      let queryt:any = {};
      if(this.route.snapshot.queryParams['alertMsg']){
        queryt.queryParams = { alertMsg:'TB' } ;
      }
      this.router.navigate([this.localize.translateRoute('/orders')], queryt );
    }

	this.userForm = this.formBuilder.group({
      firstname: new FormControl('',[  Validators.required]),
      lastname: new FormControl('',[  Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('' ),
      postalcode: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      idcountry: new FormControl('', [Validators.required]),
      nif: new FormControl(''  ),
      newsletter: new FormControl('' ),
      telephone_callingcode: new FormControl('351' ),
      telephone: new FormControl('',[Validators.pattern("^[0-9]{9}$")]),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")  ] ),
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]

    }, {
		validator: [MustMatch('password', 'confirmPassword'), NifValidatorPT('idcountry', 'nif')]
	});

	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/showorder');
      this.metatags(lang.lang);
    });
  }



  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle );this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(6,9,lang, 'MENU_ORDER').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  get f() { return this.userForm.controls; }


  getCountry(){
    this.apiService.getCountry().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.countries = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

   onSubmit() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    var formData = new FormData();
    Object.keys(this.userForm.controls).forEach(key => {
      if(this.userForm.controls[key].value){
        formData.append("userweb["+key+"]", this.userForm.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });

    this.apiService.createUserwebwithoutNewsletter(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
          this.submitted = false;
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        this.alreadyClient=true;
        });
      }else{
        this.submitted = false;
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });

   }

  getCallingcode(){
    this.apiService.getCallingcode().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.telephone_callingcode = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  ngOnInit(): void {
      this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.localize.parser.currentLang+'/showorder');
    this.apiService.getordergenerate().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info>0){

        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });

    this.apiService.getOrder(this.idorder).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.session==0){
          if(this.storedIDS){
            if(!this.storedIDS.includes(Number(this.idorder)) && !this.storedIDS.includes(this.idorder.toString())){

              //return;
            }
          }
        }
        if(this.storedIDS && this.storedIDS.length>=50){
          this.storedIDS.splice(-25,25);
          this.localstorageService.set('orderspc', JSON.stringify(this.storedIDS));
        }

        if(obj.info && typeof obj.info == 'object' && Object.keys(obj.info).length > 0){
          this.orderInfo = obj.info;

          let first = this.orderInfo.client.name.substr(0,this.orderInfo.client.name.indexOf(' '));
          let last = this.orderInfo.client.name.substr(this.orderInfo.client.name.indexOf(' ')+1);
          this.userForm.controls['firstname'].setValue(first);
          this.userForm.controls['lastname'].setValue(last);

          this.userForm.controls['address'].setValue(this.orderInfo.client.address);
          this.userForm.controls['address2'].setValue(this.orderInfo.client.address2);
          this.userForm.controls['postalcode'].setValue(this.orderInfo.client.postalcode);
          this.userForm.controls['locality'].setValue(this.orderInfo.client.locality);
          this.userForm.controls['nif'].setValue(this.orderInfo.client.nif);
          if(this.orderInfo.client.idregion){
            this.userForm.controls['idcountry'].setValue(this.orderInfo.client.idcountry+"-"+this.orderInfo.client.idregion);
          }else{
            this.userForm.controls['idcountry'].setValue(this.orderInfo.client.idcountry);
          }

          this.userForm.controls['email'].setValue(this.orderInfo.client.email);
          this.userForm.controls['telephone_callingcode'].setValue(this.orderInfo.client.telephone_callingcode);
          this.userForm.controls['telephone'].setValue(this.orderInfo.client.telephone);

        }


        if(obj.products.length>0){
        this.angulartics2.eventTrack.next({
          action: 'Purchase',
          properties: {
            label: 'Purchase',
            currency: "EUR",
            content_type: 'product_group',
            "event":"Purchase",
            gtmCustom:{
              'ecommerce': {
                'remove': undefined,
                'click': undefined,
                'add': undefined,
                'checkout': undefined,
                'detail': undefined,
                'impressions': undefined,
                'purchase': {
                  'actionField': {'id': obj.number, 'revenue':obj.total, 'shipping':obj.shipping},    // 'detail'
                  'products': obj.products
                 }
               }
            }
          }
        });
        // GA4 Purchase
        this.angulartics2.eventTrack.next({
          action: 'purchase',
          properties: {
            label: 'Purchase',
            currency: "EUR",
            content_type: 'product_group',
            "event":"purchase",
            gtmCustom:{
              'ecommerce': {
                'remove': undefined,
                'click': undefined,
                'add': undefined,
                'checkout': undefined,
                'detail': undefined,
                'impressions': undefined,
                'purchase': {
                  'currency':'EUR', 'transaction_id': obj.number, 'value':obj.total, 'shipping':obj.shipping,    // 'detail'
                  'items': obj.products,
                 }
               }
            }
          }
        });
      }



      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });


    this.getCountry();
    this.getCallingcode();
	this.metatags(this.translate.currentLang);

	this.optionsCalling = {
      templateSelection: function (data, container) {
		return "+"+data.text;
	 },
      templateResult: function (data, container) {
		return "+"+data.text;
	 }
    };
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag('rel=canonical');
  }

  btoa(str) {
    return window.btoa(str);
  }

  changepayment(){
    if(this.wait){
      return;
    }
    if(this.selectPaymentAbbr=="MBW"){
      if(!this.contat_MBW || this.contat_MBW==""){
        Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } }); return;
      }
      if(this.contat_MBW.match(/^[+]{0,1}[0-9]{9,16}$/)==null){
          Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } });
        return;
      }
    }
    this.apiService.changepayment(this.idorder, this.selectPayment, this.translate.currentLang, this.orderInfo.details.nameclient, this.orderInfo.client.email, this.contat_MBW).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.newUrl && obj.newUrl==1){
          window.location.href = obj.newUrlDesc;
        }else{
          this.closeModal();
          this.ngOnInit();this.wait=false;
        }
      }else if(typeof obj!=="undefined" && obj.code==202){
        this.closeModal();
        this.ngOnInit();this.wait=false;
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
      }else{
        this.wait=false; this.closeModal();
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


   changePaymentMean(){
     let countryclient  =  this.orderInfo.addressclient.countryabbr ;
     if(this.orderInfo.addressclient.idregion!=null){
       countryclient  +=  "-"+this.orderInfo.addressclient.idregion ;
     }
     let country  = "";
     let address = "";
     let postalcode  = "";
     let locality = "";
     if(this.orderInfo.addressdelivery){
         country  =  this.orderInfo.addressdelivery.countryabbr ;
         if(this.orderInfo.addressdelivery.idregion!=null){
           country  +=  "-"+this.orderInfo.addressdelivery.idregion ;
         }
         address  =  this.orderInfo.addressdelivery.address ;
         postalcode  =  this.orderInfo.addressdelivery.postalcode ;
         locality  =  this.orderInfo.addressdelivery.city ;
     }else{
         country  =  this.orderInfo.addressclient.countryabbr ;
         if(this.orderInfo.addressclient.idregion!=null){
           country  +=  "-"+this.orderInfo.addressclient.idregion ;
         }
         address  =  this.orderInfo.addressclient.address ;
         postalcode  =  this.orderInfo.addressclient.postalcode ;
         locality  =  this.orderInfo.addressclient.city ;
     }

     this.apiService.getShippingsAbbr(countryclient, country, address, postalcode, locality, this.orderInfo.shipping.idshippingmode).subscribe((obj: any)=>{
       if(typeof obj!=="undefined" && obj.code==200){
          this.payments=obj.info;
         this.openModal();
       }else{
         this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
       }
     });
   }


  openModal(){
        this.modalChangePaymentRef = this.modalService.open(this.modalChangePayment, {
            size: 'md',
            windowClass: 'modal-md'  ,
            hideCloseButton: false,
            centered: true,
            animation: true,
            keyboard: false,
            closeOnOutsideClick: false,
			      backdropClass: 'modal-backdrop'
        });
        this.modalChangePaymentRef.onOpen.subscribe(() => {
                $("modal .fade.show").addClass("in");
            });
    }

	replacementString(s: String, val: any){
	   return s.replace(":1:", val);
	 }

    closeModal(){
        this.modalService.close(this.modalChangePaymentRef);
    }

}
