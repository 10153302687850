import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { SessionService } from '../session.service';
import { Router, ActivatedRoute   } from '@angular/router';
import { SharedService } from '../shared.service';
import { LogoutService } from '../logout.service';
import { Title, Meta, DomSanitizer }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  subscribe:any;
  subscribe2:any;
  blogs:any;
  postsrecents:any;
  search:any;
  constructor(public titlechangeService:TitlechangeService,private activatedRoute: ActivatedRoute,protected sanitizer: DomSanitizer,private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title,public logoutservice: LogoutService,public sharedService: SharedService, private route: Router, public sessionService: SessionService, public apiService: ApiService, public translate: TranslateService) {

  }


    searchToObject() {
    var pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for ( i in pairs ) {
      if ( pairs[i] === "" ) continue;

      pair = pairs[i].split("=");
      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }

    return obj;
  }


  ngAfterViewInit(): void {
    this.subscribe2 = this.activatedRoute.queryParams.subscribe(params => {
       if(params.search){
         this.search = params.search;
         this.getBlogs(this.translate.currentLang, params.search);
       }else{
         this.search = "";
         this.getBlogs(this.translate.currentLang, "");
       }
     });
  }


  ngOnInit(): void {
    let r = this.searchToObject();
    if(!r && !r['search']){
      this.search = r['search'];
    }else{
      this.search = "";
    }
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/blog' );
    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/blog', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/blog', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/blog', 'en');
     this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/blog', 'fr');





     this.getBlogs(this.translate.currentLang, this.search);

    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/blog');
        this.metatags(lang.lang);
        this.getBlogs(lang.lang, this.search);
      });
      this.metatags(this.translate.currentLang);

  }

  transform_html(value) {
    let a = this.sanitizer.bypassSecurityTrustHtml(value);
    return a ;
  }


  searchblog(l){
    this.search = l.value;
     this.route.navigate([this.localize.translateRoute('/blog')], { queryParams: { search: l.value } });
    l.value = "";
  }


  getBlogs(lang:any, search?:any){
    this.apiService.getBlogs(lang, search).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.blogs=obj.info;
        this.postsrecents=obj.postsrecents;
      }else if(obj.code==302){ this.sessionService.sendsession(false, ""); this.sharedService.sendClickEvent(this.translate.currentLang); this.route.navigate([this.localize.translateRoute('/')] ); }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  ngOnDestroy(): void {
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    if(this.subscribe2){
      this.subscribe2.unsubscribe();
    }
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }


  public setTitle( newTitle: string) {
     this.titleService.setTitle( newTitle  );
     this.titlechangeService.send();
  }

metatags(lang){
  this.apiService.getMetatags(12,9,lang, 'MENU_BLOG').subscribe((obj: any)=>{
    if(typeof obj!=="undefined" && obj.code==200){
      if(obj.title){
        this.setTitle( obj.title.content);
      }
      this.apiService.GET_TAGS_META.forEach(key => {
        this.metaService.removeTag('name="'+key+'"');
      });
      if(obj.info.length==0){
        return;
      }

      this.metaService.addTags(obj.info);
    }else{
      this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
    }
  });
}

}
