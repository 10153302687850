import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  LocalizeRouterModule, LocalizeParser, ManualParserLoader, CacheMechanism,
  LocalizeRouterSettings
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';

import { HomeComponent } from './home/home.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ShowComponent } from './show/show.component';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { PageComponent } from './page/page.component';
import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SigninComponent } from './signin/signin.component';
import { AccountComponent } from './account/account.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ShoworderComponent } from './showorder/showorder.component';

import { Angulartics2Module } from 'angulartics2';
//import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { BlogComponent } from './blog/blog.component';
import { BlogdetailComponent } from './blogdetail/blogdetail.component';
import { RedirectComponent } from './redirect/redirect.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { ItemreviewComponent } from './itemreview/itemreview.component';
import { PointsComponent } from './points/points.component';
export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, { ...settings, alwaysSetPrefix: true }, http, "./assets/locales.json?v=2");
}
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: '!show', component: ShowComponent  },




  { path: '!show/:desc1', component: ShowComponent   },

  { path: '!show/:desc1/:desc2', component: ShowComponent   },

  { path: '!show/:desc1/:desc2/:desc3', component: ShowComponent   },

  { path: '!show/:desc1/:desc2/:desc3/:desc4', component: ShowComponent   },
  { path: '!show/:desc1/:desc2/:desc3/:desc4/:desc5', component: ShowComponent   },


  { path: '!product/:description', component: ProductComponent  },
  { path: '!product/:description/:color', component: ProductComponent  },
  { path: '!product/:description/:color/:size', component: ProductComponent  },
  { path: '!contact', component: ContactComponent  },
  { path: '!page/:desc/:page', component: PageComponent  },
  { path: '!register', component: RegisterComponent  },
  { path: '!checkout', component: CheckoutComponent  },
  { path: '!checkout/:back', component: CheckoutComponent  },
  { path: '!signin', component: SigninComponent  },
  { path: '!account', component: AccountComponent  },
  { path: '!profile', component: ProfileComponent  },
  { path: '!orders', component: OrdersComponent  },
  { path: '!showorder/:orderid', component: ShoworderComponent  },
  { path: '!resetpassword/:token', component: ResetpasswordComponent  },
  { path: '**', component: RedirectComponent },
  { path: '!404', component: PagenotfoundComponent },
  { path: '!blog', component: BlogComponent },
  { path: '!blog/:desc', component: BlogdetailComponent },
  { path: '!wishlist', component: WishlistComponent },
  { path: '!itemreview/:orderid', component: ItemreviewComponent  },
  { path: '!points', component: PointsComponent  },

];

export function shouldTranslateMap(param: string): string {
  if (isNaN(+param)) {
    return 'map';
  }
  return null;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {  scrollPositionRestoration: "enabled", anchorScrolling: "enabled" }), Angulartics2Module.forRoot({ pageTracking: { excludedRoutes: [/.*/] } }),
LocalizeRouterModule.forRoot(routes, {

            parser: {
              provide: LocalizeParser,
              useFactory: HttpLoaderFactory,
              deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            },
            cacheMechanism: CacheMechanism.Cookie,
            cookieFormat: '{{value}};{{expires:20}};path=/'

        })],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule { }
