

<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'contacts' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>


<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="c-content-feedback-1 c-option-1">
            <div class="row">




							<div class="col-md-6">
                    <div class="c-contact noMargin">
                        <div class="c-content-title">
                            <span class="title_h3 c-font-uppercase ">{{ 'getintouch' | translate }}</span>
                            <div class="c-line-left noPadding  col-xs-12 c-theme-bg"  style="width:100%;"></div>
							<div class="clearfix"></div><br>
                            <p class="c-font-lowercase">{{ 'anyquestion' | translate }}</p>
                        </div>
                        <form  [formGroup]="contactForm" (ngSubmit)="onSubmit()">

                            <div class="form-group">
															<input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name"  placeholder="{{ 'name' | translate }}" class="form-control valRequired">
															<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
																 <div *ngIf="f.name.errors.required">{{ 'msg_valRequired' | translate }}</div>
														 </div>
														 </div>
                            <div class="form-group">
															<input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  formControlName="email"  placeholder="{{ 'email' | translate }}" class="form-control valEmail valRequired">
															<div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
																 <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
																 <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
														 </div>
														 </div>
                            <div class="form-group">
															<input type="text" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" formControlName="telephone"  placeholder="{{ 'telephone' | translate }}" class="form-control valNumber">
															<div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
																 <div *ngIf="f.telephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
																 <div *ngIf="f.telephone.errors.pattern">{{ 'msg_valTelephone' | translate }}</div>
														 </div>
														 </div>
                            <div class="form-group">
															<textarea rows="8" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" formControlName="message" placeholder="{{ 'message' | translate }} ..." aria-required="true"
																	aria-invalid="false" class="form-control c-theme c-square input-lg valRequired"></textarea>
																	<div *ngIf="submitted && f.message.errors" class="invalid-feedback">
																		 <div *ngIf="f.message.errors.required">{{ 'msg_valRequired' | translate }}</div>
																 </div>
                            </div>
							<div class="form-group mb-2" style="height: 33px;">
								<div class="col-lg-4 noPadding">
									<div class="custom-file form-control">
										<input type="file" class="custom-file-input" id="importFile" accept=".pdf, .png, .jpg, .gif, .doc" (change)="onFileChange($event.target.files)">
										<label class="custom-file-label" #labelImport for="importFile">{{ 'attachments' | translate }}</label>
									</div>
								</div>
							</div>
							<p class="mb-5" style="font-size: 14px;">{{ 'attachment_type_files' | translate }}</p>


                            <button type="submit" class="btn c-theme-btn c-btn-uppercase btn-lg c-btn-bold c-btn-square">{{ 'send' | translate }}</button>
                        </form>
                    </div>
                </div>



								<div class="col-md-6" *ngIf="this.contacts">
					<div class="c-content-title">
						<span class="title_h3 c-font-uppercase">{{ 'questionsaboutweb' | translate }}</span>
						<div class="c-line-left noPadding  col-xs-12 c-theme-bg" style="width:100%;"></div>
					</div>
					<div class="clearfix"></div><br>
                    <div class="c-body">
						<div class="c-section">
                            <div class="c-content-label c-font-uppercase c-font-bold c-theme-font c-bg-white noPadding">{{ 'contacts' | translate }}</div>
                            <p>
																	<ng-container *ngIf="this.contacts.presentation[this.translate.currentLang].email">
																		 <strong>E: </strong> {{ this.contacts.presentation[this.translate.currentLang].email }}
																	</ng-container>

																	<ng-container *ngIf="this.contacts.presentation[this.translate.currentLang].contact">
																		<br/>
																		 <strong>T: </strong> {{ this.contacts.presentation[this.translate.currentLang].contact }} <span style="font-size: 12px;"> {{ this.contacts.presentation[this.translate.currentLang].contactobs }}</span>
																	</ng-container>


                        </div>
						<div class="c-section">
                            <div class="c-content-label c-font-uppercase c-font-bold c-theme-font c-bg-white noPadding">{{ 'company' | translate }}</div>
								<p>{{ this.contacts.name }}
                                <br/>{{ this.contacts.nif }}
								</p>
                        </div>
                        <div class="c-section">
                            <div class="c-content-label c-font-uppercase c-font-bold c-theme-font c-bg-white noPadding">{{ 'headquarters' | translate }} /  {{ 'wharehouse' | translate }}  </div>
                            <p>{{ this.contacts.address }}
                                <br/>{{ this.contacts.postalcode }}, {{ this.contacts.locality }}
								<br/>Portugal
								</p>
                        </div>

												<ng-container *ngIf="this.contacts && this.contacts.presentation[this.translate.currentLang] && (this.contacts.presentation[this.translate.currentLang].facebook || this.contacts.presentation[this.translate.currentLang].instagram || this.contacts.presentation[this.translate.currentLang].pinterest || this.contacts.presentation[this.translate.currentLang].linkedin )">

                        <div class="c-section">
                            <div class="c-content-label c-font-uppercase c-font-bold c-theme-font c-bg-white noPadding">Social</div>
                            <br/>
                            <ul class="c-content-iconlist-1 c-theme">
															<ng-container *ngIf="this.contacts.presentation[this.translate.currentLang].facebook">
                                <li> <a  [href]="this.contacts.presentation[this.translate.currentLang].facebook" target="_blank"><i class="fa fa-facebook"></i></a> </li>
															</ng-container>
															<ng-container *ngIf="this.contacts.presentation[this.translate.currentLang].instagram">
                                <li> <a  [href]="this.contacts.presentation[this.translate.currentLang].instagram" target="_blank"><i class="fa fa-instagram"></i></a> </li>
															</ng-container>
															<ng-container *ngIf="this.contacts.presentation[this.translate.currentLang].pinterest">
                                <li> <a  [href]="this.contacts.presentation[this.translate.currentLang].pinterest" target="_blank"><i class="fa fa-pinterest"></i></a> </li>
															</ng-container>
															<ng-container *ngIf="this.contacts.presentation[this.translate.currentLang].linkedin">
                                <li> <a  [href]="this.contacts.presentation[this.translate.currentLang].linkedin" target="_blank"><i class="fa fa-linkedin"></i></a> </li>
															</ng-container>

                            </ul>
                        </div>

						</ng-container>
                    </div>
                </div>


		<div class="col-xs-12 c-margin-t-30">

			<agm-map style="height:400px" [latitude]="lat" [zoom]="zoom" [longitude]="lng">
			  <agm-marker [latitude]="lat" [longitude]="lng">
					<agm-info-window>Moda Server Pro {{ 'supplier' | translate }} {{ 'leather_goods' | translate }}</agm-info-window>
				</agm-marker>
			</agm-map>

		</div>

	 </div>
	</div>
 </div>
</div>
