import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlpipe'
})
export class UrlpipePipe implements PipeTransform {

  transform(value: string): string {
    if(!value){
      return value;
    }
    return value.replace(/\*/g, '').replace(/\,/g, '').replace(/\+/g, '').replace(/\?/g, '').replace(/\)/g, '').replace(/\(/g, '').replace(/\}/g, '').replace(/\{/g, '').replace(/\%/g, '').replace(/\#/g, '').replace(/!/g, '').replace(/$/g, '').replace(/§/g, '').replace(/@/g, '').replace(/|/g, '').replace(/`/g, '').replace(/´/g, '').replace(/'/g, '').replace(/\\/g, '').replace(/\//g, '').replace(/%/g, '').replace(/"/g, '').replace(/ /g, '-').replace(/&/g, 'e').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

}
