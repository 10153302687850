import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
 import { ModalManager } from 'ngb-modal';
import { Options } from 'select2';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch, NifValidatorPT } from '../must-match.validator';
import { LocalstorageService } from '../localstorage.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
declare var $: any;
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { TitlechangeService } from '../titlechange.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-itemreview',
  templateUrl: './itemreview.component.html',
  styleUrls: ['./itemreview.component.scss']
})
export class ItemreviewComponent implements OnInit {

  idorder:any;
  itemsReview: any;
  document: any;
  indexselected: any;

  subscribe: any;

  constructor(public titlechangeService:TitlechangeService,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title,private router: Router, public localstorageService: LocalstorageService, private route: ActivatedRoute, public apiService: ApiService, public translate: TranslateService, private modalService: ModalManager, private formBuilder: FormBuilder) { 
    this.idorder = this.route.snapshot.paramMap.get('orderid');

    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/itemreview');
        this.metatags(lang.lang);
      });
  }

  ngOnInit(): void {
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.localize.parser.currentLang+'/itemreview');
    this.getItemsReview();
    this.metatags(this.translate.currentLang);
  }

  ngOnDestroy(): void {
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag('rel=canonical');
  }

  getItemsReview(){
    this.apiService.getOrderItems(this.idorder).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.session==0){
          this.router.navigate([this.localize.translateRoute('/')] );
        }
        this.itemsReview = obj.info;
        this.document = obj.document;

      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  openbox($ev : any){
    $ev = $ev.target;
    var outerBox = $($ev).parents(".accordion-box");
    var target = $($ev).parents(".accordion");

    if ($($ev).next(".acc-content").is(":visible")) {
      //return false;
      $($ev).removeClass("active");
      $($ev).next(".acc-content").slideUp(300);
      $(outerBox).children(".accordion").removeClass("active-block");
    } else {
      $(outerBox).find(".accordion .acc-btn").removeClass("active");
      $($ev).addClass("active");
      $(outerBox).children(".accordion").removeClass("active-block");
      $(outerBox).find(".accordion").children(".acc-content").slideUp(300);
      target.addClass("active-block");
      $($ev).next(".acc-content").slideDown(300);
    }
  }

  uploadFile(ind:any, event: Event){
    this.indexselected = ind;
    if(this.itemsReview[ind].filelist && this.itemsReview[ind].filelist.length >= 8){ this.translate.get('limit_images_upload').subscribe(translations => { Swal.fire(translations);}); }
    const element = event.currentTarget as HTMLInputElement;
    let fileList: any | null = element.files;
    if (fileList) {
      for (let index = 0; index < fileList.length; index++) {
        var reader = new FileReader();
        reader.onload =this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(fileList[index]);
        this.itemsReview[ind].filelist.push(fileList[index]);
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.itemsReview[this.indexselected].images_64.push(btoa(binaryString))
  }

  deleteFile(ind:any,index:any){
    this.itemsReview[ind].images_64.splice(index,1);
    this.itemsReview[ind].filelist.splice(index,1);
  }

  submitReview(ind:any){

    if(this.itemsReview[ind].submittedReview){ return; }
    this.itemsReview[ind].submittedReview = true;
    if(this.itemsReview[ind].title.trim() == '' || this.itemsReview[ind].comment.trim() == '' || this.itemsReview[ind].rating == 0 || this.itemsReview[ind].created){
      return;
    }

    var formData = new FormData();
    formData.append("review[title]", this.itemsReview[ind].title.toString());
    formData.append("review[comment]", this.itemsReview[ind].comment.toString());
    formData.append("review[rating]", this.itemsReview[ind].rating.toString());
    if(this.itemsReview[ind].itemwithattr == 1){
      formData.append("review[idcolor]", this.itemsReview[ind].idcolor.toString());
      formData.append("review[idsize]", this.itemsReview[ind].idsize.toString());
    }

    if(this.itemsReview[ind].filelist && this.itemsReview[ind].filelist.length > 0){
      for (let index = 0; index < this.itemsReview[ind].filelist.length; index++) {
        formData.append("image["+index+"]", this.itemsReview[ind].filelist[index]);
      }
    }

    this.apiService.reviewItem(formData, this.itemsReview[ind].iditem, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.itemsReview[ind].created = true;
        this.itemsReview[ind].images_64 = []; this.itemsReview[ind].filelist = [];
        this.translate.get(obj.msg).subscribe(translations => {
          Swal.fire({
            position: 'top-end',
            customClass: {
              container: 'swal-index'
            },
            icon: 'success',
            title: translations,
            showConfirmButton: false,
            timer: 2000
          });
        });
      }
      else{
        this.translate.get(obj.msg).subscribe(translations => {Swal.fire(translations);});
        if(obj.msg === 'USER_NOT_FOUND3'){ this.router.navigate([this.localize.translateRoute('/')] ); }
      }
    })
  }
  
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );this.titlechangeService.send();
 }

 metatags(lang){
   this.apiService.getMetatags(43,9,lang, 'metatags_itemsreviews').subscribe((obj: any)=>{
     if(typeof obj!=="undefined" && obj.code==200){
       if(obj.title){
         this.setTitle( obj.title.content);
       }
       this.apiService.GET_TAGS_META.forEach(key => {
         this.metaService.removeTag('name="'+key+'"');
       });
       if(obj.info.length==0){
         return;
       }

       this.metaService.addTags(obj.info);
     }else{
       this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
     }
   });
 }

 getcolorname(color:any){
    let colorname = "";
    if(color.langs && color.langs[this.translate.currentLang]){
      colorname =  color.langs[this.translate.currentLang].name
    }
    else{
      colorname = color.name;
    }
    return colorname;
 }
}
