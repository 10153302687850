import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { SessionService } from '../session.service';
import { Router, ActivatedRoute   } from '@angular/router';
import { SharedService } from '../shared.service';
import { LogoutService } from '../logout.service';
import { Title, Meta, DomSanitizer }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import {SharelangchangeService} from "../sharelangchange.service";
import {Location, LowerCasePipe} from '@angular/common';
import { TitlechangeService } from '../titlechange.service';
import { UrlpipePipe } from '../urlpipe.pipe';
@Component({
  selector: 'app-blogdetail',
  templateUrl: './blogdetail.component.html',
  styleUrls: ['./blogdetail.component.scss']
})
export class BlogdetailComponent implements OnInit {
  subscribe:any;
  subscribe2:any;
  subscribe3: any;
  id:any;
  blog:any;
  postsrecents:any;
  langs:any;
  checkchangelang:any;
  constructor(public titlechangeService:TitlechangeService,private location: Location, public sharelangchangeService:SharelangchangeService, private activatedRoute: ActivatedRoute,protected sanitizer: DomSanitizer,private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title,public logoutservice: LogoutService,public sharedService: SharedService, private route: Router, public sessionService: SessionService, public apiService: ApiService, public translate: TranslateService,private lowercase:LowerCasePipe,private urlpipePipe:UrlpipePipe) {

    if(!this.activatedRoute.snapshot.paramMap.get('desc')){
      this.route.navigate([this.localize.translateRoute('/404')], {skipLocationChange: true}); return;
    }
    let url = this.geturlvalue(this.activatedRoute.snapshot.paramMap.get('desc'))
    if(!url){
      this.route.navigate([this.localize.translateRoute('/404')], {skipLocationChange: true}); return;
    }
    this.id = url;


    this.subscribe2 = this.activatedRoute.params.subscribe(params => {

      let url = this.geturlvalue(params['desc'])
      if(!url){
        this.route.navigate([this.localize.translateRoute('/404')], {skipLocationChange: true}); return;
      }
      this.id = url;
      this.getBlog(this.translate.currentLang);
      this.metatags(this.translate.currentLang);
    });

    this.subscribe =  this.sharelangchangeService.get().subscribe((obj)=>{
        //this.metatags(lang.lang);
        //this.getBlog(lang.lang);

        if(this.langs && this.langs[obj[0]]){
          window.location.href = '/'+obj[0]+'/blog/'+this.langs[obj[0]].url;
        }


      //  this.route.snapshot.url[op].path
      });

      this.subscribe3=  this.translate.onLangChange.subscribe(lang=>{
        this.updateUrl(lang.lang);
        this.metatags(lang.lang);
      })


  }


  getBlog(lang:any){
    this.apiService.getBlogbyid(lang, this.id).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.blog=obj.info;
        this.langs = obj.langs;
        this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/blog/'+this.blog.url );
        this.seoService.clearAlternateUrl();

        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/blog/'+obj.langs["fr"].url, 'x-default');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/blog/'+obj.langs["pt"].url, 'pt');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/blog/'+obj.langs["en"].url, 'en');
         this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/blog/'+obj.langs["fr"].url, 'fr');

        this.postsrecents=obj.postsrecents;
      }else if(obj.code==302){ this.sessionService.sendsession(false, ""); this.sharedService.sendClickEvent(this.translate.currentLang); this.route.navigate([this.localize.translateRoute('/')] ); }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  searchblog(l){
     this.route.navigate([this.localize.translateRoute('/blog')], { queryParams: { search: l.value } });
    l.value = "";
  }

  geturlvalue(s){
    let t = [];
    let num ;
    for (let _i = s.length-1; _i >= 0; _i--) {
       num = s[_i];
      if((/[a-zA-Z-]/).test(num)){
        break;
      }
      t.push(num);
    }
    let t1=t.reverse().join("").toString();
    return t1;
  }

  ngOnInit(): void {


      this.metatags(this.translate.currentLang);
      this.getBlog(this.translate.currentLang);
  }


  ngOnDestroy(): void {
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    if(this.subscribe2){
      this.subscribe2.unsubscribe();
    }
    if(this.subscribe3){
      this.subscribe3.unsubscribe();
    }
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }


  public setTitle( newTitle: string) {
     this.titleService.setTitle( newTitle  );this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(this.id,10,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  updateUrl(lang){
    if(this.blog && this.langs && this.langs[lang] && this.langs[lang].url){
      this.activatedRoute.snapshot.url[1].path = this.langs[lang].url;
    }
  }

}
