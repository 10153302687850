import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckwebpService {

  constructor() { }

  canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) {
          return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      }
      return false;
  }
}
