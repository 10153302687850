
<div class="c-content-box c-size-xs c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="col-md-12  c-margin-t-10 noPadding">
			<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
				<li>
					<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate  }}</a>
				</li>
				<li>|</li>
        <ng-container *ngIf="this.idorder "  >
          <li>
				<a [routerLink]="(['/showorder', this.idorder] | localize)"  href="javascript:void(0)" > {{ 'detail' | translate }}</a>
        </li>
        <li>|</li>
          <li>
        <a [routerLink]="(['/showorder', this.idorder] | localize)"  href="javascript:void(0)" *ngIf="this.orderInfo"> {{   this.orderInfo.document.numberdoc }}</a>
        </li>
        </ng-container>
			</ul>
		</div>
	</div>
</div>



<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
 <div class="container-fluid c-container-50">
   <div class="row">

<ng-container *ngIf=" this.orderInfo  ">


			<div class="col-xs-12 col-md-12  ">
				<div class="featured-box  info-order-box">
					<div class="box-content">
						<ng-container *ngIf="!this.orderInfo.document.datepayment && this.orderInfo.document.cancellationreason ==null &&  this.orderInfo.document.methoddescriptionabbr!='TB' ">
               	<span class="c-font-16" [innerHTML]=" replacementString('orderfinish_notpay' | translate, '<b>'+ this.orderInfo.document.numberdoc+'</b>')   " ></span>
 	           </ng-container>
						<ng-container *ngIf="this.orderInfo.document.datepayment ">
							<ng-container *ngIf="(this.orderInfo.document.clientcondition==2 || this.orderInfo.document.clientcondition==4) || this.alreadyClient ">
							<span class="c-font-16" [innerHTML]=" replacementString('orderfinish_pay_accountexist' | translate, '<b>'+ this.orderInfo.document.numberdoc+'</b>')   " ></span>
							</ng-container>
							<ng-container *ngIf="(this.orderInfo.document.clientcondition==1 || this.orderInfo.document.clientcondition==3) && this.alreadyClient==false ">
							<span class="c-font-16" [innerHTML]=" replacementString('orderfinish_pay_accountexist' | translate, '<b>'+ this.orderInfo.document.numberdoc+'</b>')   " ></span>
							</ng-container>
						</ng-container>
            <ng-container *ngIf=" this.orderInfo && this.orderInfo.document.methoddescriptionabbr=='TB'">
              <span class="c-font-16" [innerHTML]="  'msgTBOrder' | translate " ></span>
            </ng-container>
					</div>
				</div>

			</div>
			<div class="col-xs-12 col-md-12 ">


        <a class="btn  c-btn-black btn-sm c-btn-sbold  " [href]="this.apiService.PHP_API_SERVER+'?controller=order&action=get&doc='+btoa(this.idorder)" target="_blank">
          <i class="fa fa-file-pdf"></i>&nbsp;
          <span>{{ 'doctitle' | translate }}</span>
        </a>


				<div class=" row ">
					<div class=" col-xs-12 ">
            <br>
						<span class="mt-0 title_h3 c-font-16 helveticaltsd c-font-bold ccblack background-color-theme full-width float-left m0 col-md-12 c-margin-b-40 fullWidth">
							{{ 'resume_order_client' | translate }}


						</span>

						<table class="cart-totals cart-totals-checkout full-width table-orderinfo " style="width:100%">
							<thead>
								<tr>
									<th class="product-image" colspan="2">{{ 'product' | translate }}</th>

									<th class="product-name text-center">{{ 'quant' | translate }}</th>
									<th class="product-subtotal text-right">{{ 'total' | translate }}</th>
								</tr>
							</thead>
							<tbody>
							 <ng-container *ngFor="let itm of this.orderInfo.lines ">
								<ng-container *ngIf="itm.rate!=0 && this.firstRate==0">
									<tr ng-init="this.firstRate=1" class="padding_separate_table"><td></td><td></td><td></td><td></td></tr>
								</ng-container>

								<tr>
								  <ng-container *ngIf="itm.prodinfo && itm.prodinfo.images && itm.prodinfo.images[0] && itm.prodinfo.images[0][0]">
									<td class="product-image text-left">
									  <img style="width:50px; height:auto;margin:0 auto;" [src]="this.apiService.imagesDir+itm.prodinfo.images[0][0].photo">
									</td>
									<td class="product-name text-left">{{ itm.itemdescription }}</td>
								  </ng-container>
								  <ng-container *ngIf="!(itm.prodinfo && itm.prodinfo.images && itm.prodinfo.images[0] && itm.prodinfo.images[0][0])">
									<td class="product-name text-left" colspan="2">{{ itm.itemdescription }}</td>
								  </ng-container>
								  <td class="text-center">{{ (itm.quantity | number:'1.0-0' ) }}</td>
								  <td class="text-right">{{ ((this.orderInfo.document.typevalue==1 ? itm.priceshow*itm.quantity  : itm.priceunit*itm.quantity) | number:'1.2-2').replace(",", " ").replace(".", ",")  }} {{ this.orderInfo.document.currencysymbol }}</td>
								</tr>
						     </ng-container>
							</tbody>
						</table>
            <br>
						<ng-container *ngIf="this.orderInfo.lines ">
						<table class="cart-totals full-width cart-total-table" style="float: right;    min-width: 145px;">
							<tbody>
								<tr class="total">
									<th>{{ 'total' | translate }}<br><span style="font-size:11px;font-weight:normal;">{{ 'vatincluded' | translate }}</span></th>
									<td class="text-right">
										<span class="amount ">{{ (this.orderInfo.document.total).replace(",", " ").replace(".", ",")  }}</span>
										<span class="text-right">{{ this.orderInfo.document.currencysymbol }}</span>
									</td>
								</tr>
							</tbody>
						</table>
						</ng-container>
						<ng-container *ngIf="!this.orderInfo.document.datepayment && this.orderInfo.document.cancellationreason ==null && this.orderInfo.document.methoddescriptionabbr!='TB'">
						<table class="cart-choose-payment full-width mt-20 mb-20">
							<tbody>
								<tr class="total">
									<th>&nbsp;</th>
									<td><button (click)="changePaymentMean()" class="btn c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;">{{ 'choose_method_resume' | translate }}</button></td>
								</tr>
							</tbody>
						</table>
						</ng-container>
					</div>
				</div>
				<div class=" ">
					<div class=" ">
						<div class="row">
							<div class="col-xs-12 col-md-6 mt-20">
								<span class="   title_h3 c-font-16 col-md-12 helveticaltsd c-font-bold ccblack background-color-theme full-width float-left m0  c-margin-b-40 fullWidth">{{ 'clientinfo' | translate }}</span>
								<p class="m0"><strong>{{ 'name' | translate }}:</strong> {{ this.orderInfo.details.nameclient }}</p>
								<p class="m0"><strong>{{ 'nif' | translate }}:</strong> {{ this.orderInfo.details.nifclient }}</p>
								<p class="m0"><strong>{{ 'email' | translate }}:</strong> {{ this.orderInfo.client.email }}</p>

								<p class="m0"><strong>{{ 'address' | translate }}:</strong>
									{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address : '' }}
									{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address2 : '' }} <br/>
									{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.postalcode : '' }}, {{ this.orderInfo.addressclient ? this.orderInfo.addressclient.city : '' }}
								</p>
								<p class="m0"><strong>{{ 'country' | translate }}:</strong> {{ this.orderInfo.addressclient ? this.orderInfo.addressclient.country : '' }}</p>
							</div>
							<div class="col-xs-12 col-md-6 mt-20">
								<span class="  title_h3 c-font-16 col-md-12 helveticaltsd c-font-bold ccblack background-color-theme full-width float-left m0  c-margin-b-40 fullWidth">{{ 'deliveryinfo' | translate }}</span>
								<p class="m0"><strong>{{ 'personchargresp' | translate }}:</strong> {{ (this.orderInfo.addressdelivery.name).split(";;")[0] }}</p>
                <p class="m0"><strong>{{ 'telephone' | translate }}:</strong> {{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.contact : '' }}</p>
								<p class="m0"><strong>{{ 'address' | translate }}:</strong>
									{{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.address : '' }}
									{{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.address2 : '' }} <br/>
									{{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.postalcode : '' }}, {{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.city : '' }}
								</p>
								<p class="m0"><strong>{{ 'country' | translate }}:</strong> {{ this.orderInfo.addressclient ? this.orderInfo.addressdelivery.country : '' }}</p>
								<p class="m0" *ngIf="this.orderInfo.shipping">
									<strong>{{ 'shipmode' | translate }}:</strong> {{  'shipping_'+this.orderInfo.shipping.abbrshipingmode | translate}}
									<span *ngIf="this.orderInfo.shipping.shippingcompany"> - {{  this.orderInfo.shipping.shippingcompany}}</span>
								</p>
							</div>
							<div class="col-xs-12 col-md-12 mt-20">
								<span class="mt-20 title_h3 col-md-12 c-font-16 helveticaltsd c-font-bold ccblack background-color-theme full-width float-left m0  c-margin-b-40 fullWidth">{{ 'paymentdesc' | translate }} </span>
								<ng-container *ngIf="this.orderInfo.document.datepayment">
									<ng-container *ngIf=" this.orderInfo.document.method=='CC'">
										<div class="col-md-12 text-left"><img src="assets/images/visa_master_icon.png" width="150px" /></div>
									</ng-container>
									<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='PYPL'">
										<div class="col-md-12 text-left"><img src="assets/images/paypal_icon.png" width="150px" /></div>
									</ng-container>
									<ng-container *ngIf=" this.orderInfo.document.method=='MB'">
									  <div class="col-md-12 text-left"><img src="assets/images/mb_text_icon.png" width="150px" /></div>
									</ng-container>
                  <ng-container *ngIf=" this.orderInfo.document.method=='MBW'">
									  <div class="col-md-12 text-left"><img src="assets/images/mbw_text_icon.png" width="150px" /></div>
									</ng-container>
									<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='TB' && this.orderInfo.bank.length>0">
									  <div class="  ">
                      <p class="m0">  {{  'TB' | translate }}</p>
										<p class="m0"><strong>{{ 'IBAN' | translate }}:</strong> {{  this.orderInfo.bank[0].nib }}</p>
										<p class="m0"><strong>{{ 'bank' | translate }}:</strong> {{  this.orderInfo.bank[0].bank }}</p>
										<p class="m0"><strong>{{ 'SWIFT' | translate }}:</strong> {{  this.orderInfo.bank[0].swift }}</p>
									  </div>
									</ng-container>
								</ng-container>

								<ng-container *ngIf="!this.orderInfo.document.datepayment && this.orderInfo.document.cancellationreason ==null">
									<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='PYPL'">
										<div class="col-md-12 text-left"><img src="assets/images/paypal_icon.png" width="150px" /></div>
									</ng-container>
									<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='CC'">
										<div class="col-md-12 text-left"><img src="assets/images/visa_master_icon.png" width="150px" /></div>
									</ng-container>
                  <ng-container *ngIf=" this.orderInfo.document.method=='MBW'">
									  <div class="col-md-12 text-left"><img src="assets/images/mbw_text_icon.png" width="150px" /></div>
									</ng-container>
									<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='MB'">
									  <div class="col-md-12 text-left"><img src="assets/images/mb_text_icon.png" width="150px" /></div>
									  <p class="m0"><strong>{{ 'entity' | translate }}:</strong> {{  this.orderInfo.document.entity }}</p>
									  <p class="m0"><strong>{{ 'reference' | translate }}:</strong> {{  this.orderInfo.document.reference }}</p>
									  <p class="m0"><strong>{{ 'value' | translate }}:</strong> {{  (this.orderInfo.document.total | number:'1.2-2').replace(",", " ").replace(".", ",")  }}{{ this.orderInfo.document.currencysymbol }}</p>
									</ng-container>
									<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='TB' && this.orderInfo.bank.length>0">
									  <div class="  ">
                      <p class="m0">  {{  'TB' | translate }}</p>
										<p class="m0"><strong>{{ 'IBAN' | translate }}:</strong> {{  this.orderInfo.bank[0].nib }}</p>
										<p class="m0"><strong>{{ 'bank' | translate }}:</strong> {{  this.orderInfo.bank[0].bank }}</p>
										<p class="m0"><strong>{{ 'SWIFT' | translate }}:</strong> {{  this.orderInfo.bank[0].swift }}</p>
									  </div>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>



  <div class="container-fluid c-container-50" [hidden]="true">
    <div class="c-layout-sidebar-content c-border-2x pt-20  c-margin-t-10 c-margin-b-20">
      <div class="row">
        <div class="form-group col-md-6">
          <fieldset>
            <legend>{{ 'clientinfo' | translate }}</legend>
            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'name' | translate }} </label>
              <input type="text"   value="{{ this.orderInfo.details.nameclient }}" class="form-control c-square " disabled />
            </div>

            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'email' | translate }} </label>
              <input type="text"   value="{{ this.orderInfo.client.email }}" class="form-control c-square " disabled />
            </div>

            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'nif' | translate }} </label>
              <input type="text"   value="{{ this.orderInfo.details.nifclient }}" class="form-control c-square " disabled />
            </div>
            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'telephone' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.contact : '' }}"  class="form-control c-square" />
            </div>

            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'address' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address : '' }}"  class="form-control c-square" />
            </div>

            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'addresscomplement' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address2 : '' }}"  class="form-control c-square" />
            </div>

            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'postalcode' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.postalcode : '' }}"  class="form-control c-square" />
            </div>
            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'locality' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.city : '' }}"  class="form-control c-square" />
            </div>
            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'country' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.country : '' }}"  class="form-control c-square" />
            </div>
          </fieldset>
        </div>

      </div>
    </div>
  </div>
</ng-container >

<ng-container *ngIf=" !this.orderInfo  ">
  {{ 'ORDER_NOT_FOUND' | translate }}
</ng-container >

</div>
</div>
</div>

<modal #ModalChangePayment    >

	<modal-header>
	{{ 'paymentchoose' | translate  }}
	</modal-header>
  <modal-content>
    <div class=" row">
      <div class="  col-md-12 col-xs-12 col-sm-12">
        <ng-container   *ngIf="this.payments">
          <ng-container   *ngFor="let payment of this.payments;">
            <div   class="c-radio">
              <input value="{{ payment.idpaymentwebsite }}" (change)="this.selectPayment=payment.idpaymentwebsite;selectPaymentAbbr=payment.abbr"  abrv="{{ payment.abbr }}" type="radio" name="payment"   class="c-radio" id="radioP{{ payment.idpaymentwebsite }}">
              <label class="c-font-bold c-font-14" for="radioP{{ payment.idpaymentwebsite }}">
                <span class="inc"></span><span class="check"></span>
                <span class="box"></span> {{ payment.abbr | translate  }}
              </label>
            </div>

            <ng-container *ngIf="selectPaymentAbbr=='MBW' && this.selectPayment==payment.idpaymentwebsite ">
							<label style="    margin-left: 30px;"  class="control-label">{{ 'telephone' | translate }} <sup><i style="font-size: 9px;" class="c-theme-font pl-02   fa fa-asterisk"></i></sup></label>
							<input style="margin-left: 30px;width: calc(100% - 15px);" type="text" [required]="this.selectPayment==payment.idpaymentwebsite ? 'true' : 'false'"   [(ngModel)]="contat_MBW" name="contat_MBW"  maxlength="20" id="contat_MBW"   class="form-control c-square valRequired valNumber" />
						</ng-container>

          </ng-container>
        </ng-container>



      </div>
    </div>


  </modal-content>

  <modal-footer>
    <ng-container   *ngIf="this.selectPayment">
      <button type="button"     (click)="changepayment()"   class="btn pull-right   c-theme-btn c-btn-square c-btn-uppercase c-btn-bold buyButton col-xs-12  ">{{ 'pay' | translate }}</button>
    </ng-container>
    </modal-footer>
</modal>
