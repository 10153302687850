import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private subject = new Subject<any>();
  public lang: String;
  constructor() { }

  sendClickEvent(lang: String) {
    this.lang = lang;
    this.subject.next();
  }
  getClickEvent( ): Observable<any>{
    return this.subject.asObservable();
  }

}
