import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogoutService } from '../logout.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { HttpClient, HttpParams  } from '@angular/common/http';
import {merge, Observable, Subscription, Subject, fromEvent, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, debounceTime,  distinctUntilChanged, filter} from 'rxjs/operators';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { ModalManager } from 'ngb-modal';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';
declare var $: any;
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { SessionService } from '../session.service';
import { SharedService } from '../shared.service';
import { Router, ActivatedRoute   } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TitlechangeService } from '../titlechange.service';
import { LowerCasePipe } from '@angular/common';
import { WishlistService } from '../wishlist.service';
import { UrlpipePipe } from '../urlpipe.pipe';
import { CheckwebpService } from '../checkwebp.service';
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  breadcrumbs: any[] = [];
  subscribe:any;

  wishlistItems: any[] = [];
  wishlistTotal: any;
  browserwebp;

  showPoints: boolean = false;

  constructor(public titlechangeService:TitlechangeService,private activatedRoute: ActivatedRoute, public localize: LocalizeRouterService,private route: Router,public sharedService: SharedService,public sessionService: SessionService,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, private seoService: SeoService, private metaService: Meta, public titleService: Title,private modalService: ModalManager, private httpClient: HttpClient, public apiService: ApiService, public translate: TranslateService, public logoutservice: LogoutService,private lowercase:LowerCasePipe,public wishlistService:WishlistService,private urlpipePipe:UrlpipePipe,private router: Router,public checkwebp: CheckwebpService) { 
      this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
        this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/wishlist');
          this.metatags(lang.lang);
      });
      this.metatags(this.translate.currentLang);

      let objBread:any = {};
  		objBread.url = "wishlist";
  		objBread.description = "wishlist";
  		this.breadcrumbs.push(objBread);

      this.browserwebp = this.checkwebp.canUseWebP();
  }

  ngOnInit(): void {

    this.apiService.getUserPoints().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.show){
          this.showPoints = true;
        }
      }
    })

    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/wishlist' );
    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/wishlist', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/wishlist', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/wishlist', 'en');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/wishlist', 'fr');

    this.getWishList();
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle);this.titlechangeService.send();
 }

  metatags(lang){
    this.apiService.getMetatags(40,9,lang,'metatags_wishlist').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getWishList(){
    this.apiService.getWishlistItems().subscribe((obj: any) => {
      if(typeof obj!=="undefined" && obj.items){

        if(obj.updated){
          this.translate.get( 'wishlist_updated' ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } }); });
          this.wishlistService.send();
        }
        this.wishlistItems = obj.items; 
        this.wishlistTotal = obj.total_count;
      }
      else if(obj.code==302){ this.sessionService.sendsession(false, ""); this.sharedService.sendClickEvent(this.translate.currentLang); this.route.navigate([this.localize.translateRoute('/')] ); this.translate.get( 'sessionexpire' ).subscribe(translations => {  Swal.fire(translations);    }); }
      else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    })
  }

  showPrice(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceOriginalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceOriginalTxt;
        }
      }else{
        return item.priceOriginalTxt;
      }
    }else{
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceFinalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinalTxt;
        }
      }else{
        return item.priceFinalTxt;
      }
    }
  }

  showDiscount(item:any){
    if(item.typepromotion==1){
      return Math.floor( parseFloat(item.promotionvalue) );
    }
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        return   Math.floor(Math.round((100-(100*item.colors[item.colorpriority][key].priceFinal/item.colors[item.colorpriority][key].priceOriginal))* 100) / 100);
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        return Math.floor(Math.round((100-(100*item.colors[color][key].priceFinal/item.colors[color][key].priceOriginal))* 100) / 100);
      }
    }else{
      return Math.floor(Math.round((100-(100*item.priceFinal/item.priceOriginal))* 100) / 100);
    }
  }

  urlmount(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any){

    if(product.outofstock){
      return;
    }
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        id_color = this.color(product);
      }
      let id_size = idsize;
      if(!idsize){
        id_size = this.size(product, id_color);
      }
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }

    this.angulartics2GAEcommerce.ecAddProduct({
          'id': product.iditem,
          'name': desc,
          'category': cat,
          'brand': brand,
          'variant': color,
          'position': 0
        });
        this.angulartics2GAEcommerce.ecSetAction("click", {});



  this.angulartics2.eventTrack.next({
    action: 'productClick',
    properties: {
      label: 'productClick',
      currency: "EUR",
      content_type: 'product_group',
      "event":"productClick",
      gtmCustom:{
        'ecommerce': {
          'remove': undefined,
          'add': undefined,
          'purchase': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'click': {
            'actionField': {'list': 'List Items Click'},    // 'detail'
            'products': [{
              'id': product.iditem,
              'name': desc,
              'price': pval,
              'brand': brand,
              'category': cat,
              'variant': color
             }]
           },
           'view_item': {
            'currency': 'EUR', 'value': pval,    // 'detail'
            'items': [{
              'item_id': product.iditem,
              'item_name': desc,
              'price': pval,
              'item_brand': brand,
              'item_category': cat,
              'item_variant': color
             }]
           }
         }
      }
    }
  });

  setTimeout(()=>{
      if(idsize){
        this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return;
      }else
      if(idcolor){
        this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return;
      }else{
        this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return;
      }
    }, 100);
  }

  color(i:any){
    if(i.itemwithattr==1){
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return i.colorpriority;
      }else{
        return Object.keys(i.colors)[0];
      }
    }else{
      return "";
    }
  }

  size(i:any, op:any){
    if(i.itemwithattr==1){
      if(op>0){
        return Object.keys(i.colors[op])[0];
      }
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return Object.keys(i.colors[i.colorpriority])[0];
      }else{
        let col = Object.keys(i.colors)[0];
        return Object.keys(i.colors[col])[0];
      }
    }else{
      return "";
    }
  }

  loadImg(item){
    if(item.itemwithattr==1){
      if(item.wishcolor == null){
        let color = Object.keys(item.colors)[0];
        item.wishcolor = color;
      }
      let key = Object.keys(item.colors[item.wishcolor])[0];
      if(this.browserwebp && item.colors[item.wishcolor][key].images[0].webp){
        return this.apiService.imagesDir+item.colors[item.wishcolor][key].images[0].webp;
      }
      else{
        return this.apiService.imagesDir+item.colors[item.wishcolor][key].images[0].photo;
      }
    }
    else{
      if(this.browserwebp && item.images[0].webp){
        return this.apiService.imagesDir+item.images[0].webp;
      }
      return this.apiService.imagesDir+item.images[0].photo;
    }
  }

  parseText(desc, limit){
    let e = document.createElement('div');
    e.innerHTML = desc;
    let r = e.childNodes[0].nodeValue;
    if(desc.length<=limit){
      return r.substring(0,limit) ;
    }
    return r.substring(0,limit)+"...";
  }

  removeLine(iditem, idcolor){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    });
    this.apiService.removewishlist(iditem, idcolor).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.wishlistService.send();
        let a = this.translate.instant('ITEM_SUCCESS_WISHLIST_REMOVE');
        this.getWishList();
        Toast.fire({
          icon: 'success',
          html: '<h5>'+a+'</h5>'
        });
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getColorName(item, idcolor){
    let key = Object.keys(item.colors[idcolor])[0];
    if(item.colors[idcolor][key].langs && item.colors[item.wishcolor][key].langs[this.translate.currentLang] && item.colors[item.wishcolor][key].langs[this.translate.currentLang].name){
      return  item.colors[item.wishcolor][key].langs[this.translate.currentLang].name;
    }
    else{
      return item.colors[item.wishcolor][key].name;
    }
  }
}
