import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { Options } from 'select2';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch, NifValidatorPT, NifRequired , ObsRequired } from '../must-match.validator';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import {  Router, ActivatedRoute, RoutesRecognized, NavigationEnd, NavigationStart   } from '@angular/router';
import { LocalstorageService } from '../localstorage.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TitlechangeService } from '../titlechange.service';
import { filter, pairwise } from 'rxjs/operators';
import { ModalManager } from 'ngb-modal';
import { ViewportScroller } from '@angular/common';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm:FormGroup;
  submitted = false;
  countries:any;
  subscribe:any;
  telephone_callingcode:any;
  optionsCalling: Options;
  userLogging:any;

  previouspagecheckout:boolean = false;

  ac_points:any;
  @ViewChild('ModalRegisterPoints') ModalRegisterPoints;
  modalregisterpoints:any;
  pointsRegister:any;

  typeuser:any;
  showbtncheckout:any;
  hideDesktop:any

  invalidEmailWarning: any;
  invalidEmailAddress: any;

  constructor(public titlechangeService:TitlechangeService,public localize: LocalizeRouterService,private route: Router, public localstorageService: LocalstorageService,private router: ActivatedRoute,private seoService: SeoService, private metaService: Meta, public titleService: Title, public apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder, private modalService: ModalManager, private viewportScroller: ViewportScroller, private recaptchaV3Service: ReCaptchaV3Service) {

    if(this.localstorageService.get("previouspage") == '/'+this.translate.currentLang+'/checkout'){
      this.previouspagecheckout = true;
    }

    if(this.localstorageService.get("userLogging")){
      this.userLogging=this.localstorageService.get("userLogging");
    }
    if(this.userLogging){
      this.route.navigate( [this.localize.translateRoute('/')]   );return;
    }
    this.registerForm = this.formBuilder.group({
      compname: new FormControl('',[  Validators.required]),
      type: new FormControl('',[  Validators.required]),
      obs: new FormControl('' ),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('' ),
      postalcode: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      idcountry: new FormControl('', [Validators.required]),
      nif: new FormControl(''),
      newsletter: new FormControl(''),

      mobilephone: new FormControl('' ),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")  ] ),
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', Validators.required]

    }, {
            validator: [MustMatch('password', 'confirmPassword'), NifValidatorPT('idcountry', 'nif'), NifRequired('type', 'nif'), ObsRequired('type', 'obs')]
        });


	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{

        this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/register' );

      this.metatags(lang.lang);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(window.innerWidth <= 991){
      this.hideDesktop =true;
    }
    else{
      this.hideDesktop = false;
    }
  }


  get f() { return this.registerForm.controls; }

  ngOnInit(): void {

    if(window.innerWidth <= 991){
      this.hideDesktop =true;
    }
    else{
      this.hideDesktop = false;
    }
    
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/register' );
    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/register', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/register', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/register', 'en');
     this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/register', 'fr');

	  this.metatags(this.translate.currentLang);
    this.getCountry();
    this.getCallingcode();
    this.getCart();

	this.optionsCalling = {
      templateSelection: function (data, container) {
		return "+"+data.text;
	 },
      templateResult: function (data, container) {
		return "+"+data.text;
	 }
    };
  }

  ngOnDestroy(): void {
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }

    this.seoService.clearAlternateUrl();
    this.seoService.removeTag('rel=canonical');
    this.seoService.removeTag('rel=alternate');
  }

  public setTitle( newTitle: string) {
	  this.titleService.setTitle( newTitle);this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(2,9,lang, 'MENU_REGISTER').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }
      /*  Object.keys(obj.info).forEach(key => {
          this.metaService.removeTag('name="'+obj.info[key].name+'"');
        });*/
        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getCart(){
    this.apiService.getCart(0).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info && obj.info.length>0){
          this.showbtncheckout = true;
        }
        else{
          this.showbtncheckout = false;
        }
      }
    })
  }


  getCountry(){
    this.apiService.getCountry().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){

           const lang = this.translate.currentLang;

        obj.infoarray.sort((a,b) => (a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0))

          this.countries = obj.infoarray;

      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getCountryByTypeClient(type:any){
    let idwebsite = 2;
    if(type ==0){ idwebsite = 2}else{ idwebsite = 1 }
    this.apiService.getCountry(idwebsite).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){

           const lang = this.translate.currentLang;

        obj.infoarray.sort((a,b) => (a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0))

          this.countries = obj.infoarray;
          // this.scrollAnchor();

      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getCallingcode(){
  /*  this.apiService.getCallingcode().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.telephone_callingcode = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });*/
  }

  getPoints(typewebsite){
    this.apiService.getPoints("1,6", typewebsite).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.ac_points = obj.info;
      }
    })
  }

  onSubmit() {

    if(this.registerForm && this.registerForm.controls.email && this.registerForm.controls.email.value){
      this.registerForm.controls.email.setValue( this.registerForm.controls.email.value.toString().trim() );
      this.registerForm.controls.email.setValue( this.registerForm.controls.email.value.toString().toLowerCase() );
    }

    if(this.registerForm && this.registerForm.controls.nif && this.registerForm.controls.nif.value){
      this.registerForm.controls.nif.setValue( this.registerForm.controls.nif.value.toString().trim() );
    }

    if(this.registerForm.controls.type.value == 1 && this.registerForm.controls.nif.value == ''){
      this.translate.get( 'msg_valNif' ).subscribe(translations => {  Swal.fire(translations);    });return;
    }

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    let re;
    re = /^[+]?[0-9]{9,13}$/;
    re = /^\s*([^\s]\s*){6,15}$/;
    if(!re.test(this.registerForm.controls['mobilephone'].value.toString())  && (this.registerForm.controls['idcountry'].value.toString()=="179" || this.registerForm.controls['idcountry'].value.toString()=="179-1" || this.registerForm.controls['idcountry'].value.toString()=="179-2" || this.registerForm.controls['idcountry'].value.toString()=="179-3") ){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.submitted = false;
        return;
    }else{
      re = /^[+]?[0-9]{7,16}$/;
      re = /^\s*([^\s]\s*){6,15}$/;
      if(!re.test(this.registerForm.controls['mobilephone'].value.toString())){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.submitted = false;
        return;
      }
    }

    this.recaptchaV3Service.execute('register').subscribe((token: string)=> {
      if(token){
        var formData = new FormData();
        formData.append("captcha", token.toString());
        Object.keys(this.registerForm.controls).forEach(key => {
          if(this.registerForm.controls[key].value){
            formData.append("userweb["+key+"]", this.registerForm.controls[key].value.toString());
          }else{
            formData.append("userweb["+key+"]", "");
          }
        });
    
        this.typeuser = this.registerForm.controls.type.value;
        this.apiService.createuserweb(formData, this.translate.currentLang).subscribe((obj: any)=>{
          if(typeof obj!=="undefined" && obj.code==200){
    
            if(obj.points_register){
              this.pointsRegister = obj.points_register;
            }

            // If the email registered could not be vefified.
            if(obj.msg == 'usersavedwithinvalidemail'){
              this.invalidEmailWarning = 1;
              this.invalidEmailAddress = obj.replace;
            }
    
            this.submitted = false;
            this.registerForm.controls['nif'].setValue("");
            this.openModalRegisterPoints();

            // Wait some time before redirecting, otherwise the modal does not show up properly            
            if(this.previouspagecheckout && this.registerForm.controls.type.value == 0){
              setTimeout(() => {
                this.closeModalRegisterPoints();
                this.route.navigate([this.localize.translateRoute('/checkout')] );
              }, 7000);
            }

            this.registerForm.reset();
            setTimeout(() => {
              this.closeModalRegisterPoints();
              this.route.navigate([this.localize.translateRoute('/signin')] );
            }, 7000);
            

          }else{
            this.translate.get( obj.msg ).subscribe(translations => { if(typeof obj.replace!=="undefined"){ translations = translations.replace(/:1:/, obj.replace); }  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
          }
        });
      }
    })
  }

  openModalRegisterPoints(){
    this.modalregisterpoints = this.modalService.open(this.ModalRegisterPoints, {
      size: 'md',
      windowClass: 'modal-md'  ,
      hideCloseButton: false,
      centered: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: 'modal-backdrop'
  });
  this.modalregisterpoints.onOpen.subscribe(() => {
    $("modal .fade.show").addClass("in");
  });
  }

  closeModalRegisterPoints(){
    this.modalService.close(this.modalregisterpoints);
  }


  getpostalcode(){
    if(this.registerForm.get('idcountry').value==179 || this.registerForm.get('idcountry').value=="179-1" || this.registerForm.get('idcountry').value=="179-2"  || this.registerForm.get('idcountry').value=="179-3"){
      this.apiService.getpostalcode(this.registerForm.get('postalcode').value).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
        //  this.registerForm.controls['locality'].setValue(obj.designation) ;
        }else{
          this.translate.get( 'msg_valCP' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }

  scrollAnchor(): void { 
    const yOffset = -60; 
    let element = document.getElementById('user_points');
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});

  }



}
