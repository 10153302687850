import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogoutService } from '../logout.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { HttpClient, HttpParams  } from '@angular/common/http';
import {merge, Observable, Subscription, Subject, fromEvent, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, debounceTime,  distinctUntilChanged, filter} from 'rxjs/operators';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { ModalManager } from 'ngb-modal';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';
declare var $: any;
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { SessionService } from '../session.service';
import { SharedService } from '../shared.service';
import { Router, ActivatedRoute   } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TitlechangeService } from '../titlechange.service';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})
export class PointsComponent implements OnInit {

  displayedColumns: string[] = ['updated_at', 'idpointtype', 'points', 'expirationdate', 'totalpoints'];
  dataTable: tableStruture[] = [];

  httpDatabase: HttpDatabase | null;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() keyPressOnChild = new EventEmitter();

  hiddenLoad:any;
  wait:any;
  subscribe:any;
  breadcrumbs: any[] = [];

  isLoadingResults = true;
  isRateLimitReached = false;
  resultsLength = 0;
  userPoints = 0;
  points:any;
  gap: any;

  eurotopoints:any = 1;

  constructor(public titlechangeService:TitlechangeService,private activatedRoute: ActivatedRoute, public localize: LocalizeRouterService,private route: Router,public sharedService: SharedService,public sessionService: SessionService,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, private seoService: SeoService, private metaService: Meta, public titleService: Title,private modalService: ModalManager, private httpClient: HttpClient, public apiService: ApiService, public translate: TranslateService, public logoutservice: LogoutService) { 

    this.wait=false;
    this.hiddenLoad=true;
    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/points');
      this.metatags(lang.lang);
      this.getGap(lang.lang);
    });
    this.metatags(this.translate.currentLang);

    let objBread:any = {};
    objBread.url = "points";
    objBread.description = "points";
    this.breadcrumbs.push(objBread);
  }

  ngOnInit(): void {

    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/points' );
    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/points', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/points', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/points', 'en');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/points', 'fr');

    this.getUserPoints();
    this.getAllPointTypes();
    this.getGap(this.translate.currentLang);
 
  }

  ngAfterViewInit() {
    this.httpDatabase = new HttpDatabase(this.httpClient, this.apiService);
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.httpDatabase!.getPoints(
          this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
      }),
      map(data => {

         if(typeof data['code']!=="undefined" && data['code']==302){ this.sessionService.sendsession(false, ""); this.sharedService.sendClickEvent(this.translate.currentLang); this.route.navigate([this.localize.translateRoute('/')] ); }
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data['total_count'];

        return data['info'];
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    ).subscribe(data => this.dataTable = data);
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }

  getUserPoints(){
    this.apiService.getUserPoints().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.userPoints = obj.total;
      }else{
        this.userPoints = 0;
      }
    });
  }

  getAllPointTypes(){
    this.apiService.getPoints("1,2,3,4,5,6,7,8,12").subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.points = obj.info;
      }
      else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle);this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(40,9,lang,'userpoints').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getGap(lang:String){
    this.apiService.getGap(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.gap = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }
}

export interface tableApi {
  items: tableStruture[];
  total_count: number;
}

export interface tableStruture {
  idpointtype: string;
  points: string;
  // addpoints: string;
  // delpoints: string;
  // pendingpoints: string;
  // usedpoints: string;
  updated_at: string;
  expirationdate: string;
  totalpoints: string;
}

export class HttpDatabase {
  constructor(private httpClient: HttpClient, private apiService: ApiService ) {}

  getPoints(sort: string, order: string, page: number, pageSize:number): Observable<tableApi> {
    const href = this.apiService.PHP_API_SERVER;
    const requestUrl =`${href}?controller=points&action=getuserhistoric&sort=${sort}&order=${order}&page=${page + 1}&pagesize=${pageSize}`;
    return this.httpClient.get<tableApi>(requestUrl,  {withCredentials: true});
  }
}