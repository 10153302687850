import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  KeyExtra:any = "moda_";
  localStorage: Storage;
  sessionStorage: Storage;
  requestDB: any;
  instanceDB: any;
  errorindex:any;
  constructor(private dbService: NgxIndexedDBService) {
    this.localStorage = window.localStorage;
    this.errorindex = false;
  }

  async getasync(key: string, tsession?: any): Promise<any> {
    if (this.isLocalStorageSupported) {
      let example:any;

      try {
        if(this.errorindex==false){
          let y = this.localStorage.getItem(this.KeyExtra+"errorindex");
          if(typeof y==="undefined"){
            this.errorindex = true;
          }
          if(y=="true"){
            this.errorindex = true;
          }
        }
        if(this.errorindex){
          return new Promise((resolve, reject) => {
            resolve(null)
          });
        }
        example =  await  this.dbService.getByKey(key, 1).toPromise();
      if(!example){
        return new Promise((resolve, reject) => {
          resolve(null)
        });
      }
      } catch (error) {
        return new Promise((resolve, reject) => {
          resolve(null)
        });
      }

      if(typeof example.name=="undefined" || example.name==null){
        return new Promise((resolve, reject) => {
          resolve(null)
        });
      }

        return example.name;
      }
    return new Promise((resolve, reject) => {
      resolve(null)
    });
  }


  get(key: string): any {
    if (this.isLocalStorageSupported) {
      return JSON.parse(this.localStorage.getItem(this.KeyExtra+key));
    }
    return null;
  }
  set(key: string, value: any, tsession?: any): boolean {
    if (this.isLocalStorageSupported) {
      if(tsession==1){
        //this.sessionStorage.setItem(this.KeyExtra+key, JSON.stringify(value));
        try {
          this.dbService.clear(key);
          this.dbService.add(key, {id:1, name: value }).subscribe((key) => {  this.localStorage.setItem(this.KeyExtra+"errorindex", "false");  this.errorindex=false;   }, (error) => {  this.localStorage.setItem(this.KeyExtra+"errorindex", "true");   this.errorindex=true;  });
        } catch (error) {
        }
        return true;
      }
      //this.localStorage.removeItem(this.KeyExtra+key);
      this.localStorage.setItem(this.KeyExtra+key, JSON.stringify(value));
      return true;
    }
    return false;
  }
  remove(key: string, tsession?: any): boolean {
    if (this.isLocalStorageSupported) {
      if(tsession==1){
        this.dbService.clear(key);
        return true;
      }
      this.localStorage.removeItem(this.KeyExtra+key);
      return true;
    }
    return false;
  }
  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }
}
