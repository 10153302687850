<div class="col-md-12 main-desk-image d-none d-lg-block" *ngIf="this.banners.length == 0"></div>

<ng-container *ngIf=" this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][1]  ">
<div class="c-content-box  c-bs-grid-reset-space" style="cursor: pointer;">
	<div class="row">
		<div class="col-md-12">
	<div class="col-md-12 container-banner_1">

		<ng-container *ngIf="this.banners[this.localize.parser.currentLang][1].length == 1; else slideCarroussel">
			<div class="col-md-12 main-desk-image d-none d-lg-block divBanner" style="position: absolute;"></div>
			<div  (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][1][0].link, this.banners[this.localize.parser.currentLang][1][0].description, this.banners[this.localize.parser.currentLang][1][0].idbanner)" class="item  hidden-sm hidden-xs ">
				<img [alt]="this.banners[this.localize.parser.currentLang][1][0].alt" [src]="(this.browserwebp && this.banners[this.localize.parser.currentLang][1][0].imagewebp) ? this.apiService.imagesDir+this.banners[this.localize.parser.currentLang][1][0].imagewebp : this.apiService.imagesDir+this.banners[this.localize.parser.currentLang][1][0].image"  class=" col-md-12 noPadding"/>
			  </div> 
 
			  <div  (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][1][0].link, banner.description, this.banners[this.localize.parser.currentLang][1][0].idbanner)" class="item hidden-lg hidden-md baner_image_desk">
			  <img [alt]="this.banners[this.localize.parser.currentLang][1][0].alt" [src]="(this.browserwebp && this.banners[this.localize.parser.currentLang][1][0].image_mobile_webp) ? this.apiService.imagesDir+this.banners[this.localize.parser.currentLang][1][0].image_mobile_webp : this.apiService.imagesDir+this.banners[this.localize.parser.currentLang][1][0].image_mobile"  class=" col-xs-12 noPadding "/>
			</div>
		</ng-container>
		<ng-template #slideCarroussel>
			<owl-carousel-o [options]="carouselOptions">
				<ng-container   *ngFor="let banner of  this.banners[this.localize.parser.currentLang][1]">
				  <ng-template carouselSlide    >
				   <div  (click)="urlStringconvert(banner.link, banner.description, banner.idbanner)" class="item  hidden-sm hidden-xs">
					 <img   [alt]="banner.alt" [src]="(this.browserwebp && banner.imagewebp) ? this.apiService.imagesDir+banner.imagewebp : this.apiService.imagesDir+banner.image"  class=" col-md-12 noPadding  "/>
				   </div>
	  
				   <div  (click)="urlStringconvert(banner.link, banner.description, banner.idbanner)" class="item hidden-lg hidden-md">
				   <img   [alt]="banner.alt" [src]="(this.browserwebp && banner.image_mobile_webp) ? this.apiService.imagesDir+banner.image_mobile_webp : this.apiService.imagesDir+banner.image_mobile"  class=" col-xs-12 noPadding  "/>
				 </div>
				 </ng-template>
			   </ng-container>
			  </owl-carousel-o>
		</ng-template>

</div>
		</div>
	</div>
</div>
</ng-container>



<!-- <div class="col-md-12 seq-banner-image d-none d-lg-block" *ngIf="!loadedbanners"></div> -->


<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][2] ">
  <div class="c-content-box c-size-md c-bg-grey-1 noPadding c-margin-t-30 ">
  	<div class="container-fluid c-container-50">
  		<div class="row">
  			<div class="c-content-title-1">
  				<div class="col-xs-12 c-margin-b-30 c-margin-t-30" [class.col-sm-6]="!this.userLoggingName" [innerHTML]="transform_html(this.banners[this.localize.parser.currentLang][2][0].description)">

  				</div>
  				<div class="col-xs-12 col-sm-4 col-md-3 c-margin-b-30  c-margin-t-30 pull-right" *ngIf="!this.userLoggingName">
  					<a class="btn btn-lg c-theme-btn c-btn-square c-btn-uppercase c-btn-bold c-margin-t-30  c-margin-b-10" [href]="['/register'] | localize">{{ 'create_account' | translate }} <i class="fa fa-chevron-right"></i></a>
  					<div class="clearfix"></div>
  					<a class="c-font-dark-1 c-font-bold c-margin-t-10 c-font-14" [href]="['/signin'] | localize">{{ 'alreadyreglogin' | translate }} <i class="fa fa-arrow-right"></i></a>
  				</div>
  			</div>
  		</div>
  	</div>
  </div>
</ng-container>



<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && (this.banners[this.localize.parser.currentLang][6] || this.banners[this.localize.parser.currentLang][7] || this.banners[this.localize.parser.currentLang][8]) ">


  <div class="c-content-box c-size-md c-bg-white  ">
	<div class="container-fluid c-container-50">
		<div class="c-content-feature-2-grid" data-auto-height="true" data-mode="base-height">
			<div class="row">
				<ng-container *ngIf="this.banners[this.localize.parser.currentLang][6]">
				<div class="col-md-4 col-sm-6">
					<div class="c-content-feature-2p mobileMargin c-bg-grey5 text-center" data-height="height">
						<i class="c-font-30 c-theme-color fa fa-car c-margin-b-10"></i>
						<div class="clearfix"></div>
						<div class="col-xs-12 noPadding pull-left" [innerHTML]="transform_html(this.banners[this.localize.parser.currentLang][6][0].description)"></div>
					</div>
				</div>
				</ng-container>
				<ng-container *ngIf="this.banners[this.localize.parser.currentLang][7]">
				<div class="col-md-4 col-sm-6">
					<div class="c-content-feature-2p mobileMargin c-bg-grey5 text-center" data-height="height">
						<i class="c-font-30 c-theme-color glyphicon glyphicon-headphones c-margin-b-10"></i>
						<div class="clearfix"></div>
						<div class="col-xs-12 noPadding pull-left" [innerHTML]="transform_html(this.banners[this.localize.parser.currentLang][7][0].description)"></div>
					</div>
				</div>
				</ng-container>
				<ng-container *ngIf="this.banners[this.localize.parser.currentLang][8]">
				<div class="col-md-4 col-sm-6">
					<div class="c-content-feature-2p c-bg-grey5 text-center" data-height="height">
						<i class="c-font-30 c-theme-color glyphicon glyphicon-refresh c-margin-b-10"></i>
						<div class="clearfix"></div>
						<div class="col-xs-12 noPadding pull-left" [innerHTML]="transform_html(this.banners[this.localize.parser.currentLang][8][0].description)"></div>
					</div>
				</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

</ng-container>




<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][3] ">
<div class="c-content-box c-size-md c-theme-bg noPadding">
	<div class="container-fluid c-container-50">
		<div class="row">
			<div class="c-content-title-1 c-center" [innerHTML]="transform_html(this.banners[this.localize.parser.currentLang][3][0].description)">

			</div>
		</div>
	</div>
</div>
</ng-container>



<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && (this.banners[this.localize.parser.currentLang][4] || this.banners[this.localize.parser.currentLang][5] ) ">
  <div class="c-content-box c-size-md c-no-bottom-padding">
	<div class="container-fluid c-container-50">
		<div class="row">
			<div class="c-content-title-1 c-center c-margin-b-50 col-sm-6 mobileMargin col-xs-12">
				<div class="col-xs-12  c-bg-grey-1 " [innerHTML]="transform_html(this.banners[this.localize.parser.currentLang][4][0].description) ">

				</div>
			</div>
			<div class="c-content-title-1  c-center c-margin-b-50 col-sm-6 mobileMargin col-xs-12">
				<div class="col-xs-12  c-bg-grey-1 " [innerHTML]="transform_html(this.banners[this.localize.parser.currentLang][5][0].description) ">

				</div>
			</div>
		</div>
	</div>
</div>
</ng-container>
