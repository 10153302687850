import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenugetService {
    private subject = new Subject<any>();
    constructor() { }

    send() {
      this.subject.next();
    }
    get( ): Observable<any>{
      return this.subject.asObservable();
    }
}
