import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta }     from '@angular/platform-browser';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { SeoService } from '../seo.service';
import { Router, ActivatedRoute, ParamMap, NavigationStart  } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TitlechangeService } from '../titlechange.service';
import { UrlpipePipe } from '../urlpipe.pipe';
import { LowerCasePipe } from '@angular/common';
import { LocalstorageService } from '../localstorage.service';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { Angulartics2 } from 'angulartics2';
import { CheckwebpService } from '../checkwebp.service';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {
  subscribe:any;
  public relateditemarr: any = [];
  userLogging: Boolean= false;
  browserwebp:any;

  constructor(public checkwebp: CheckwebpService,private angulartics2: Angulartics2,private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public localstorageService: LocalstorageService,private lowercase:LowerCasePipe, private urlpipePipe:UrlpipePipe,public titlechangeService:TitlechangeService,public localize: LocalizeRouterService, private router: Router, private seoService: SeoService, private metaService: Meta,  public titleService: Title, public apiService: ApiService, public translate: TranslateService) {
    //this.router.navigate( [this.localize.translateRoute('/')]  );return;
  	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.metatags(lang.lang);
    });
this.browserwebp = this.checkwebp.canUseWebP();
    if(this.localstorageService.get("userLogging")){
      this.userLogging=this.localstorageService.get("userLogging");
    }
  }

  ngOnInit(): void {
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.localize.parser.currentLang+'/404');

    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/404', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/404', 'en');

    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/404', 'fr');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/404', 'x-default');
	this.metatags(this.translate.currentLang);
  this.getRelated();
  }


  getoneimg(item:any,idcolor:any){
    let imgs = this.imgArr(item, idcolor);
    if(this.browserwebp && imgs[0].webp){
      return this.apiService.imagesDir+imgs[0].webp;
    }
    return this.apiService.imagesDir+ imgs[0].photo;
  }

  getPriceRelated(product){
    if(product.itemwithattr==1){
      let color = Object.keys(product.colors)[0];
      let size = Object.keys(product.colors[color])[0];
      return product.colors[color][size].priceFinalTxt;
    }else{
      return product.priceFinalTxt;
    }
  }

  imgArr(item:any,idcolor:any){
    if(item.itemwithattr==1){
      if(idcolor){
        let size = Object.keys(item.colors[idcolor])[0];
          return item.colors[idcolor][size].images;
      }else{
        if(item.colorpriority && item.colorpriority in item.colors){
          let size = Object.keys(item.colors[item.colorpriority])[0];
           return item.colors[item.colorpriority][size].images;
        }else{
          let color = Object.keys(item.colors)[0];
          let size = Object.keys(item.colors[color])[0];
          return item.colors[color][size].images;
        }
      }
    }else{
      return item.images;
    }
  }



  urlmount(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any){


    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        if(product.colorpriority && product.colors[product.colorpriority] ){
        id_color =  product.colorpriority;
        }else{
          id_color = Object.keys(product.colors)[0];
        }
      }
      let id_size = idsize;
      if(!idsize){
        id_size = Object.keys(product.colors[id_color])[0];
      }
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }

    this.angulartics2GAEcommerce.ecAddProduct({
          'id': product.iditem,
          'name': desc,
          'category': cat,
          'brand': brand,
          'variant': color,
          'position': 0
        });
        this.angulartics2GAEcommerce.ecSetAction("click", {});



  this.angulartics2.eventTrack.next({
    action: 'productClick',
    properties: {
      label: 'productClick',
      currency: "EUR",
      content_type: 'product_group',
      "event":"productClick",
      gtmCustom:{
        'ecommerce': {
          'remove': undefined,
          'add': undefined,
          'purchase': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'click': {
            'actionField': {'list': 'List Items Click'},    // 'detail'
            'products': [{
              'id': product.iditem,
              'name': desc,
              'price': pval,
              'brand': brand,
              'category': cat,
              'variant': color
             }]
           },
           'view_item': {
            'currency': 'EUR', 'value': pval,    // 'detail'
            'items': [{
              'item_id': product.iditem,
              'item_name': desc,
              'price': pval,
              'item_brand': brand,
              'item_category': cat,
              'item_variant': color
             }]
           }
         }
      }
    }
  });

setTimeout(()=>{
    if(idsize){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return;
    }else
    if(idcolor){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return;
    }else{
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return;
    }
    }, 100);
  }

  ngOnDestroy(): void {
    this.seoService.clearAlternateUrl();
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    this.seoService.removeTag('rel=canonical');
    this.seoService.removeTag('rel=alternate');
  }

  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle);this.titlechangeService.send();
  }


  getRelated(){
    this.apiService.getItemsLimit().subscribe((obj: any)=>{
       if(typeof obj!=="undefined" && obj.code==200){
         this.relateditemarr = obj.info;
       }else{
         this.relateditemarr = [];
       }
    });
  }

  metatags(lang){
    this.apiService.getMetatags(10,9,lang, 'MENU_404').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }
}
