

<div class="c-content-box c-size-xs c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="col-md-12  c-margin-t-10 noPadding">
			<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
				<li>
					<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate  }}</a>
				</li>
				<li>|</li>
        <ng-container *ngIf="this.pageInfo  && this.pageInfo[this.translate.currentLang] "  >
				<li>{{   (this.pageInfo[this.translate.currentLang].subject |  titlecase)   }}</li>
        </ng-container>
			</ul>
		</div>
	</div>
</div>





<section class="c-content-box c-overflow-hide c-margin-t-20 c-margin-b-40">
	<div class="container-fluid c-container-50">
		<div class="row">
      <div class="col-md-12 c-font-14  ">

        <ng-container *ngIf=" this.pageInfo &&  this.pageInfo[this.translate.currentLang]">
          <ng-container *ngFor="let gap of this.pageInfo[this.translate.currentLang].containers;  ">



            <div *ngIf="gap.type==0 && gap.langs[this.translate.currentLang]" class='text-justify' [innerHTML]="transform_html(gap.langs[this.translate.currentLang][0].text)" ></div>


            <ng-container *ngIf="gap.type==1 && gap.langs[this.translate.currentLang] && gap.langs[this.translate.currentLang].length==1 ">
              <img class="max-full-width" [src]="this.apiService.imagesDir+gap.langs[this.translate.currentLang][0].text" />
              <div class="clearfix"></div>
            </ng-container >

            <ng-container *ngIf="gap.type==1 && gap.langs[this.translate.currentLang] && gap.langs[this.translate.currentLang].length!=1 ">
              <div class="c-content-testimonials-4 homeSlider"  data-auto-play="5000" data-navigation="true"  data-slider="owl" data-items="1">
                <owl-carousel class="owl-theme owl-noMargin c-theme owl-single  "     [options]="carouselOptionsDetail"    >
                  <ng-container   *ngFor="let img of  gap.langs[this.translate.currentLang]  ">
                    <div class="item">
                      <img [src]="this.apiService.imagesDir+img.text" class="full-width" />
                    </div>
                  </ng-container>
                </owl-carousel>
              </div>
            </ng-container >


            <ng-container *ngIf="gap.type==2   ">
              <div class="clearfix"></div><div class="col-md-12 videoEmbed"><iframe src="{{ gap.langs[this.translate.currentLang][0].text }}?rel=0" frameborder="0" allowfullscreen></iframe></div>
            </ng-container >

          </ng-container >
        </ng-container >
      </div>
    </div>
  </div>
</section>
