import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { ModalManager } from 'ngb-modal';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute   } from '@angular/router';
import { LocalstorageService } from '../localstorage.service';
import { SessionService } from '../session.service';
import { SocialAuthService , FacebookLoginProvider } from 'angularx-social-login';
import { SharedService } from '../shared.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import {   Location } from '@angular/common';
declare var $: any;
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  @ViewChild('ModalPassword') ModalPassword;
  @ViewChild('emailrecoverPass') emailrecoverPass;
  signinForm:FormGroup;
  modalDetailRef:any;
  submitted = false;
  subscribe:any;
  subscribeFace:any;

  signinpoints:any;

  constructor(public titlechangeService:TitlechangeService,private _location: Location,private router: ActivatedRoute,private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title, public sharedService: SharedService, private authService: SocialAuthService, public sessionService: SessionService, public localstorageService: LocalstorageService,private route: Router, public apiService: ApiService, private modalService: ModalManager, public translate: TranslateService, private formBuilder: FormBuilder) {
	this.signinForm = this.formBuilder.group({
      rememberme: new FormControl('' ),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]),
      password: ['', [Validators.required, Validators.minLength(1)]]
    });
    if(this.localstorageService.get("userLogging")){
      this.route.navigate([this.localize.translateRoute('/account')] );
    }

	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{

        this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/signin');

      this.metatags(lang.lang);
    });
  }

  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle  );this.titlechangeService.send();
  }

  metatags(lang){
    if(!lang){
      return;
    }
    this.apiService.getMetatags(11,9,lang,'MENU_Login').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title && obj.title.content){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  get f() { return this.signinForm.controls; }

  ngOnInit(): void {

    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/signin' );
    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/signin', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/signin', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/signin', 'en');
     this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/signin', 'fr');

  this.apiService.getSession().subscribe((obj: any)=>{
    if(typeof obj!=="undefined" && obj.code!=200){
      this.localstorageService.set("userLogging", false);
      this.localstorageService.set("userLoggingName", "");
      this.sessionService.sendsession(false, "");
    }else{
      this.localstorageService.set("userLogging", true);

    }
  });
  this.metatags(this.translate.currentLang);
  this.getSigninPoints();


  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag('rel=canonical');
    this.seoService.removeTag('rel=alternate');
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  getSigninPoints(){
    this.apiService.getPoints(2).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.signinpoints = obj.info
      }
    })
  }
  recoverPass(){
    let pa = this.emailrecoverPass.nativeElement.value.toString();
    if(pa){
      pa = pa.toString().trim() ;
      pa = pa.toString().toLowerCase() ;
    }
    this.apiService.recoverPass(pa, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        });
        this.closeModal();
        this.emailrecoverPass.nativeElement.value="";
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  openModal(modalPassword){

       this.modalDetailRef = this.modalService.open(modalPassword, {
            size: 'md',
            windowClass: 'modal-md test'  ,
            hideCloseButton: false,
            centered: true,
            animation: true,
            backdrop: true,
            modalClass: "in",
            keyboard: false,
            closeOnOutsideClick: true,
            title: 'Something',
			      backdropClass: 'modal-backdrop'
    });
    this.modalDetailRef.onOpen.subscribe(() => {
            $("modal .fade.show").addClass("in");
        });
    }




    closeModal(){
        this.modalService.close(this.modalDetailRef);
    }

    changeEmailClear(event){
    if(this.signinForm && this.signinForm.controls.email && this.signinForm.controls.email.value){
      this.signinForm.controls.email.setValue ( this.signinForm.controls.email.value.toString().trim() );
    }
  }

  onSubmit() {
    if(this.signinForm && this.signinForm.controls.email && this.signinForm.controls.email.value){
      this.signinForm.controls.email.setValue( this.signinForm.controls.email.value.toString().trim() );
      this.signinForm.controls.email.setValue( this.signinForm.controls.email.value.toString().toLowerCase() );
    }
    this.submitted = true;
    if (this.signinForm.invalid) {
      return;
    }
    var formData = new FormData();
    Object.keys(this.signinForm.controls).forEach(key => {
      if(this.signinForm.controls[key].value){
        formData.append("userweb["+key+"]", this.signinForm.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });
    this.apiService.signin(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.submitted = false;
        this.localstorageService.set("userLogging", true);
        this.localstorageService.set("userLoggingName", obj.info);
        this.sessionService.sendsession(true, obj.info.name);
        this.sharedService.sendClickEvent(this.translate.currentLang);
        let previous = this.localstorageService.get("previouspage");
        if(previous && previous == '/resetpassword'){
          this.route.navigate([this.localize.translateRoute('/')] );
          this.localstorageService.set("previouspage",null);
        }
        else{
          this._location.back();
        }
        if(obj.updatedWishlist && obj.updatedWishlist==true){
          this.translate.get( 'wishlist_updated' ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } }); });
        }
      //  this.route.navigate([this.localize.translateRoute('/account')] );
      }else if(obj.code==302){ this.sessionService.sendsession(true, ""); this.route.navigate([this.localize.translateRoute('/')] ); }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

}
