
<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'blog' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<!-- BEGIN: BLOG LISTING -->

<div class="container-fluid c-container-50">
	<div class="row">
		<div class="col-lg-10 col-md-8 col-sm-7 col-xs-12">
			<div class="c-content-blog-post-card-1-grid">

				<div class="row" *ngIf="this.blogs">
					<div class="col-md-6  col-lg-4" *ngFor=" let ct of  this.blogs; let i=index  " style="height: 535px;">
						<div class="c-content-blog-post-card-1 c-option-2 c-bordered" style="height: 505px;">

							<a onclick="return false;" [routerLink]="['/blog/'+ ct.url] | localize" [href]="['/blog/'+ ct.url] | localize">
								<div class="c-media c-content-overlay">




									<img alt="{{ ct.image | translate }} - {{ 'Name_APP' | translate }}" class="c-overlay-object img-responsive" [src]="this.apiService.imagesDir+ct.image">
								</div>
							</a>
							<div class="c-body">
								<div class="c-title c-font-bold c-font-uppercase">
									<a onclick="return false;" [routerLink]="['/blog/'+ ct.url] | localize" [href]="['/blog/'+ ct.url] | localize" class="c-font-16">{{ ct.title }}</a>
								</div>
								<div class="c-author c-font-12">
									{{ 'by' | translate }}  <span class="c-font-uppercase"> {{ ct.font }}</span>
									<span> {{ 'on' | translate }}  {{ ct.updated_at  }}</span>
								</div>

								<div class="c-font-14-blog">
									<p [innerHTML]="ct.descriptionTitle"> </p></div>
									<p>
										<a onclick="return false;" [routerLink]="['/blog/'+ ct.url] | localize" [href]="['/blog/'+ ct.url] | localize" class="btn c-theme-btn c-theme-border c-btn-square c-font-14     blog_readmore ">{{ 'read_more' | translate }}</a>
									</p>
								</div>


							</div>

						</div>




					</div>


				</div>
			</div>
			<div class="col-lg-2 col-md-4 col-sm-5 col-xs-12">

				<div class="input-group">
					<input type="text" #inputSearchBlog name="search" class="form-control c-square c-theme-border" placeholder="">
					<span class="input-group-btn">
						<button class="btn c-theme-btn c-theme-border c-btn-square" (click)="searchblog(inputSearchBlog)" type="button">{{ 'search' | translate }}</button>
					</span>
				</div>

				<div class="c-content-ver-nav">
					<div class="c-content-title-1 c-theme c-title-md c-margin-t-40">
						<span class="c-font-bold c-font-uppercase title_h5">{{ 'recent_posts' | translate }}</span>
						<div class="c-line-left c-theme-bg"></div>
					</div>
					<ul class="c-content-recent-posts-1">

						<ng-container *ngIf="this.postsrecents">
						<li *ngFor=" let ct of  this.postsrecents; let i=index  ">
							<div class="c-image c-image-blog-thumb">
								<img alt="{{ ct.image | translate }} - {{ 'Name_APP' | translate }}" [src]="this.apiService.imagesDir+ct.image" class="img-responsive">
							</div>
							<div class="c-post">
								<a [innerHTML]="ct.descriptionTitle" onclick="return false;" [routerLink]="['/blog/'+ ct.url] | localize" [href]="['/blog/'+ ct.url] | localize" class="c-title c-font-13">
								   					</a>
									<div class="c-date c-font-12">{{ ct.updated_at  }}</div>
								</div>
							</li>
						 </ng-container>


										</ul>
									</div>

								</div>
							</div>
						</div>
