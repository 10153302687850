import { Component, OnInit , Renderer2, OnDestroy , ViewChild , ViewChildren, HostListener  } from '@angular/core';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2'
import { ModalManager } from 'ngb-modal';
import {Router, ActivatedRoute} from "@angular/router";
import {SharedService} from "./shared.service";
import {SharelangchangeService} from "./sharelangchange.service";
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {   DomSanitizer, Meta }     from '@angular/platform-browser';
declare var $: any;
import { CartsharedService } from './cartshared.service';
import { SessionService } from './session.service';
import { LocalstorageService } from './localstorage.service';
import { LogoutService } from './logout.service';
import { LoginService } from './login.service';
import { OpenmodalloginService } from './openmodallogin.service';
import { MenusharedService } from './menushared.service';
import { MenugetService } from './menuget.service';
import { CheckwebpService } from './checkwebp.service';

import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { NavigationStart, Event } from '@angular/router';
declare var ga:any;
import { TitlechangeService } from './titlechange.service';
import {   Location } from '@angular/common';
import { WishlistService } from './wishlist.service';
import { SharedPointsService } from './shared-points.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent  implements OnInit,OnDestroy{
  @ViewChild('emailNewletterInput2') emailNewletterInput2;
  @ViewChild('mobileMenu') mobileMenu;
  @ViewChildren('menuCicle') things;
  @ViewChild('ModalPasswordMn') ModalPasswordMn;
  @ViewChild('emailrecoverPassMn') emailrecoverPassMn;
@ViewChild('ModalLanguages') ModalLanguages;
@ViewChild('ModalLogin') ModalLogin;
  thingsChanges:any;
  contentPrinted=false;
  private _subscription: Subscription[];
  browserwebp: any;
  isShow: boolean;
  topPosToStartShowing = 100;

  title = 'modaserverpro';
  modalDetailRef:any;
  modalLoginRef:any;
  cart:any;
  clickEventsubscription:Subscription;
  clickEventsubscriptionSession:Subscription;
  clickEventsubscriptionLogout:Subscription;
  clickEventsubscriptionPoints:Subscription;
  cartTotal:any;
  freeShipLimit = 0;
  languageWebsite: any;
  companyPresentation: any;
  companyInfo: any;
  menu: any;
  gap: any;
  submitted = false;
  blog: any;
  userLogging: Boolean= false;
  userLoggingName: any;
  shippingTotal:any;
  shippingfeefail:any;
  vouchers:any;
  subscribe:any;
  newsletterRegister:FormGroup;
  submittedNews:any;
  signinFormMn:FormGroup;

  gapCookiepage:any;
  subscribeMenu:any;
  deviceInfo:any;
  deviceDesktop:any;
  companyPresentationArr:any;
  languagesPermit:any;
  showMenu:any;
  showsubMenu:any;
  globals_banners:any;
  showGlobalbanner:any;
  countries:any;
  countrySel:any;
  docs_pending:any;

  countryDescription = '';
  year: any;
  fixedMenuMobile: boolean = false;
  qtdWishlist:any;
  qtdPoints:any;
  showpoints: boolean = true;
  newsletterpoints: any;

  constructor(private _location: Location,public openmodalloginService:OpenmodalloginService, public titlechangeService:TitlechangeService, protected sanitizer: DomSanitizer,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce,public checkwebp: CheckwebpService,public localize: LocalizeRouterService,public deviceService: DeviceDetectorService, private routea: ActivatedRoute, private menugetService:MenugetService, public menusharedService:MenusharedService,  private loginService: LoginService,   private formBuilder: FormBuilder, public logoutservice: LogoutService, public localstorageService: LocalstorageService, private router: Router,
   public apiService: ApiService, public translate: TranslateService, public renderer: Renderer2
  , public sharedService: SharedService, public sharelangchangeService:SharelangchangeService, public cartsharedService: CartsharedService, public sessionService: SessionService, public modalService: ModalManager, private Angulartics2Facebook: Angulartics2Facebook, private Angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, private Angulartics2GoogleTagManager: Angulartics2GoogleTagManager, Angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag, private metaService: Meta,public wishlistService:WishlistService, private sharedpoints: SharedPointsService) {
    this.docs_pending=0;
    this.showMenu = false;
    this.showsubMenu=[];
    this.deviceDesktop=false;
    this.year = new Date().getFullYear();

    let self = this;
    window.addEventListener('storage', function(e) {
      if(self.userLogging != localstorageService.get("userLogging")){
        self.getMenu(self.translate.currentLang);
        self.userLogging = localstorageService.get("userLogging");
      }
    })

    const isDesktopDevice = this.deviceService.isDesktop();
    if(isDesktopDevice){
      this.deviceDesktop=true;
    }
    this._subscription=[];

    let login = this.openmodalloginService.get().subscribe(()=>{
        this.openLoginModal();
    });
    this._subscription.push(login);

    let subs987 =  this.titlechangeService.get().subscribe(()=>{

            setTimeout(()=>{

              ga.getByName('t0').set('&dl', location.href );
              angulartics2.pageTrack.next({});

              }, 50);

          });
          this._subscription.push(subs987);

          let subswish = this.wishlistService.get().subscribe((obj)=>{
            this.getwishlist();
          });
          this._subscription.push(subswish);

 this.localstorageService.remove('detailBack');

      this.browserwebp = this.checkwebp.canUseWebP();

      this.showGlobalbanner = false;

    this.submittedNews = false;
    this.newsletterRegister = this.formBuilder.group({
        email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])
      });

      this.signinFormMn = this.formBuilder.group({
      rememberme: new FormControl('' ),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      password: ['', [Validators.required, Validators.minLength(1)]]
    });

      this.clickEventsubscription =    this.sharedService.getClickEvent().subscribe(()=>{
        this.getCart(0);
        this.getwishlist();
        this.getUserPoints();
      });

      this.clickEventsubscriptionPoints = this.sharedpoints.get().subscribe(()=>{
        this.getUserPoints();
      })

      this.clickEventsubscriptionLogout =    this.logoutservice.getClickEvent().subscribe(()=>{
        this.logout();
      });

      this.clickEventsubscriptionSession =    this.sessionService.getsessionEvent().subscribe((obj)=>{
        this.userLoggingName = obj[1];this.userLogging = obj[0];
        if(obj[0]){
          this.getPresentation(this.translate.currentLang);
          this.getinfoCompany();
          this.getMenu(this.translate.currentLang);
        }
      });


    if(this.localstorageService.get("userLogging")){
      this.userLogging=this.localstorageService.get("userLogging");
    }
    if(this.localstorageService.get("userLoggingName")){
      this.userLoggingName=this.localstorageService.get("userLoggingName");
    }

    this.apiService.getSession().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==201){
        this.userLogging=false;this.localstorageService.set("userLogging", false);
        this.userLoggingName="";this.localstorageService.set("userLoggingName", "");
      }
    });

    this.subscribeMenu =  this.menugetService.get().subscribe((obj)=>{
      if(this.menu && this.menu.length>0){
        this.menusharedService.send(this.menu);
      }
    });



        Angulartics2Facebook.startTracking();
    		Angulartics2GoogleAnalytics.startTracking();
    		Angulartics2GoogleTagManager.startTracking();
        
        this.createMetaLanguage(this.translate.currentLang)

        
  }

  createMetaLanguage(lang: string){
    this.metaService.removeTag("http-equiv='content-language'");
    switch (lang) {
      case 'pt':
        this.metaService.addTag({'http-equiv': 'content-language', 'content': 'pt-PT'});
        break;
      case 'en':
        this.metaService.addTag({'http-equiv': 'content-language', 'content': 'en-US'});
        break;
      case 'fr':
        this.metaService.addTag({'http-equiv': 'content-language', 'content': 'fr-FR'});
        break;
      case 'es':
        this.metaService.addTag({'http-equiv': 'content-language', 'content': 'es-ES'});
        break;
      default:
        break;
    }
  }

  getCountry(){
    this.apiService.getCountry().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){

        const lang = this.translate.currentLang;

     obj.infoarray.sort((a,b) => (a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0))

       this.countries = obj.infoarray;
        this.countrySel = obj.countrySel;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getPointsNewsletter(){
    this.apiService.getPoints(6).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.newsletterpoints = obj.info
      }
    })
  }


  ngAfterViewInit(){
    this.getCart(0);
    this.getwishlist();
    this.getUserPoints();
  }

  onActivate(e){
    if(this.router.url=="/"+this.translate.currentLang+"/orders" && this.userLogging){
      this.apiService.getDocumentsPendingPaid().subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.docs_pending = obj.docs;
        }
      });
    }
    if(this.router.url=="/"+this.translate.currentLang){
      this.showGlobalbanner = false;
    }else{
      this.showGlobalbanner = true;
    }
  }

  changeLang(lang, country){
    this.localize.changeLanguage(lang.value);
    this.modalService.close(this.modalDetailRef);
    this.savelang(lang.value, country.value, 1);

    this.sharelangchangeService.send(lang.value);
  }

  hoverMenu(i_n:any){
    let x = document.getElementsByClassName("menuFull"+i_n) as HTMLCollectionOf<HTMLElement>;
    if(typeof x[0]!="undefined"){
      x[0].style.display = "block";
    }
  }

  nohoverMenu(i_n:any){
    let x = document.getElementsByClassName("menuFull"+i_n) as HTMLCollectionOf<HTMLElement>;
    if(typeof x[0]!="undefined"){
      x[0].style.display = "none";
    }
  }


  openLangModal(){
    this.modalDetailRef = this.modalService.open(this.ModalLanguages, {
        size: 'md',
        windowClass: 'modal-md'  ,
        centered: true,
        animation: true,
        hideCloseButton: true,
        keyboard: false,
        closeOnOutsideClick: false,
        backdropClass: 'modal-backdrop'
      });
    this.modalDetailRef.onOpen.subscribe(() => {
        $("modal .fade.show").addClass("in");
    });
  }


  changeEmailClear(event){
  if(this.signinFormMn && this.signinFormMn.controls.email && this.signinFormMn.controls.email.value){
    this.signinFormMn.controls.email.setValue ( this.signinFormMn.controls.email.value.toString().trim() );
  }
}



onSubmitLogin() {
  if(this.signinFormMn && this.signinFormMn.controls.email && this.signinFormMn.controls.email.value){
    this.signinFormMn.controls.email.setValue( this.signinFormMn.controls.email.value.toString().trim() );
    this.signinFormMn.controls.email.setValue( this.signinFormMn.controls.email.value.toString().toLowerCase() );
  }
  this.submitted = true;
  if (this.signinFormMn.invalid) {
    return;
  }
  var formData = new FormData();
  Object.keys(this.signinFormMn.controls).forEach(key => {
    if(this.signinFormMn.controls[key].value){
      formData.append("userweb["+key+"]", this.signinFormMn.controls[key].value.toString());
    }else{
      formData.append("userweb["+key+"]", "");
    }
  });
  this.apiService.signin(formData, this.translate.currentLang).subscribe((obj: any)=>{
    if(typeof obj!=="undefined" && obj.code==200){
      this.submitted = false;
      this.localstorageService.set("userLogging", true);
      this.localstorageService.set("userLoggingName", obj.info);
      this.sessionService.sendsession(true, obj.info.name);
      this.sharedService.sendClickEvent(this.translate.currentLang);
      /**/
      if(obj.info.type==1){
        this.userLoggingName = obj.info.name;this.userLogging = true;
        this.getCart(0);
        this.getwishlist();
        location.href=location.href;
      }else{
        this._location.back();
      }

    //  this.router.navigate([this.localize.translateRoute('/account')] );
  }else if(obj.code==302){ this.sessionService.sendsession(true, ""); if(obj.info.type==1){this.userLoggingName = obj.info.name;this.userLogging = true; location.href=location.href; }else{this.router.navigate([this.localize.translateRoute('/')] );} /**/  }else{
      this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
    }
  });
}

  openLoginModal(){
    this.modalLoginRef = this.modalService.open(this.ModalLogin, {
        size: 'md',
        windowClass: 'modal-md'  ,
        centered: true,
        animation: true,
        hideCloseButton: true,
        keyboard: false,
        closeOnOutsideClick: false,
        backdropClass: 'modal-backdrop'
      });
    this.modalLoginRef.onOpen.subscribe(() => {
        $("modal .fade.show").addClass("in");
    });
  }

  checkMenuActive(i_n:any){
    let _class = "";
    if(i_n==0){
      _class += " noPadding-left ";
    }
    if(this.showsubMenu[i_n]==true){
      _class += " c-open ";
    }
    return _class;
  }

  savelang(lang:any, country?:any, load?:any){
    this.apiService.changelang(lang, country).subscribe((obj: any)=>{
      if(load){
        this.getinfoCompany();
      }
    });
  }


  get frm() { return this.newsletterRegister.controls; }
  get f() { return this.signinFormMn.controls; }
  logout(){
    this.apiService.logout().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.getCart(0);
        this.getwishlist();
        this.userLogging=false;this.localstorageService.set("userLogging", false);
        this.userLoggingName="";this.localstorageService.set("userLoggingName", "");
        this.getinfoCompany();this.getMenu(this.translate.currentLang);this.getPresentation(this.translate.currentLang);
        this.router.navigate([this.localize.translateRoute('/')] );
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  checkPos(i){
    if(typeof this.showsubMenu[i]==="undefined"){
      this.showsubMenu[i] = false;
    }
  }











 replacementString(s: String, val: any, number:any){
   if(number==1){
    return s.replace(":1:", val).replace(",", " ").replace(".", ",");
   }
   return s.replace(":1:", val);
 }





 searchItem(searchInput){
   searchInput.value= searchInput.value.trim();
   if(searchInput.value==""){
     return 0;
   }
   this.router.navigate([this.localize.translateRoute('/show'),   searchInput.value+"e"]);
   searchInput.value = "";
   //window["closeSearch"]();
 }

 newsletterInput(email:any, emailNewletterInput:any){

   if(email==""){
     this.newsletterRegister.controls.email.setErrors({ required: true });
     this.submittedNews = true;
     return;
   }
   this.submittedNews = false;
   this.apiService.newsletter(email, this.translate.currentLang).subscribe((obj: any)=>{
     if(typeof obj!=="undefined" && obj.code==200){
       this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
          emailNewletterInput.value="";
       this.newsletterRegister.controls.email.setErrors(null);
       this.submittedNews = false;
       this.sharedpoints.send();
     }else{
       this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);  this.newsletterRegister.controls.email.setErrors(null);
       this.submittedNews = false;  });
     }
   });
 }


  ngOnInit() {
    this.getCountry();
    this.getMenu(this.translate.currentLang);
    this.globalsbanners();
    this.getPointsNewsletter();
    this.apiService.getlanguages(0).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        let myArray: Array<string> = []
        obj.info.forEach( (myObject, index) => {
            if(myObject.acronym.toLowerCase()!="es"){
              myArray.push(myObject.acronym.toLowerCase());
            }

        });
        this.languagesPermit = myArray;
      }
    });
    this.getPresentation(this.translate.currentLang);
this.submittedNews = false;





          this.getinfoCompany();






      this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
                this.getdescriptionmenu();
                if(lang.lang && this.companyPresentationArr && this.companyPresentationArr[lang.lang]){
                    this.companyPresentation = this.companyPresentationArr[lang.lang];
                }

                this.getTranslatations(lang.lang, 0);
                this.createMetaLanguage(lang.lang);
                this.renderer.setAttribute(document.querySelector('html'), 'lang', lang.lang);
            });



       if(this.localize.parser.currentLang){

           this.getTranslatations(this.localize.parser.currentLang, 1);
           this.renderer.setAttribute(document.querySelector('html'), 'lang', this.localize.parser.currentLang);

       }


  }


  getinfoCompany(){
    this.apiService.readCompany(0, "PT", this.translate.currentLang).subscribe((obj: any)=>{

      if(typeof obj!=="undefined" && obj.code==200){
        this.languageWebsite = obj.info.languagewebsite;
        if(!obj.info.mintotal){
          obj.info.mintotal = 0;
        }
        if(obj.info.typewebsite==0){
          this.freeShipLimit = obj.info.freeshippingb2c;
        }else{
          this.freeShipLimit = obj.info.freeshippingb2b;
        }
        if(obj.info.country){
          this.countryDescription = obj.info.country;
        }
        this.companyInfo = obj.info;
        this.cartsharedService.sendcart(this.cart, this.cartTotal, this.companyInfo, this.shippingTotal, this.vouchers, 0);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getwishlist(){
    this.apiService.getwishlist().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.qtdWishlist = obj.total;
      }else{
        this.qtdWishlist = 0;
      }
    });
  }

  getUserPoints(){
    this.apiService.getUserPoints().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.qtdPoints = obj.total;
      }else{
        this.qtdPoints = 0;
      }
      if(obj.show){
        this.showpoints = true;
      }
      else{
        this.showpoints = false;
      }
    });
  }

  globalsbanners(){
    this.apiService.getGlobalBanners().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.globals_banners = obj.info;
      }else{
      }
    });
  }

  transform_html(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnDestroy(): void {
    if(this.clickEventsubscriptionLogout){
      this.clickEventsubscriptionLogout.unsubscribe();
    }
    if(this.clickEventsubscriptionPoints){
      this.clickEventsubscriptionPoints.unsubscribe();
    }
    this.subscribe.unsubscribe();this.clickEventsubscriptionSession.unsubscribe();this.clickEventsubscription.unsubscribe();
    this.subscribeMenu.unsubscribe();
    for (var i = 0; i < this._subscription.length; i++) {
       if(this._subscription[i]){
         this._subscription[i].unsubscribe();
       }
    }
  }

  cartHover(){
    $("body").addClass("c-header-cart-shown");
  }
  cartLeave(){
    $("body").removeClass("c-header-cart-shown");
  }

  cartisHover(){
    if($("body").is('.c-header-cart-shown')){
      $("body").removeClass("c-header-cart-shown");
    }else{
      $("body").addClass("c-header-cart-shown");
    }
    this.showMenu = false;
  }

  hideLangs(){
    $(".header-menu").hide()
  }

  showLangs(){
    $(".header-menu").show()
  }

  getCart(change: any){
    if(change==0){
      let ischeckout=0;
      if(location.pathname.split("/")[2]=="checkout"){
        ischeckout=1;
      }
      this.apiService.getCart(ischeckout).subscribe((obj: any)=>{
        if(!obj.session){
           this.userLogging=false;this.localstorageService.set("userLogging", false);
           this.userLoggingName="";this.localstorageService.set("userLoggingName", "");
        }
        if(typeof obj!=="undefined" && obj.code==200){
          this.docs_pending = obj.docs;
          if(obj.info){

            this.cart = obj.info;
            this.cartTotal = obj.calc.values.summary;
            if(obj.shippingCost){
              this.shippingTotal = obj.shippingCost;
            }else{
              this.shippingTotal = 0;
            }

            if(typeof obj.shippingfee==="undefined"){
              this.shippingfeefail = 0;
            }else{
              this.shippingfeefail = obj.shippingfee;
            }

            if(obj.vouchers){
              this.vouchers = obj.vouchers;
            }
            this.cartsharedService.sendcart(this.cart, this.cartTotal, this.companyInfo, this.shippingTotal, this.vouchers,this.shippingfeefail);
          }
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }

  getTranslatations(lang: String, change: Number){
    if(!lang){
      return 0;
    }
    this.getGap(lang);
    this.getBlog("1", lang);



  }


  getBlog(limit: String, lang:String){
    /*this.apiService.getBlog(lang, limit).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.blog = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });*/
  }

  getGap(lang:String){
    this.apiService.getGap(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.gap = obj.info;
        this.gapCookiepage=obj.cookie;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getPresentation(lang:String){
    this.apiService.getPresentation(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.companyPresentation = obj.info;
        this.companyPresentationArr = obj.langs;
        if(!lang){
        //  this.companyPresentation = obj.langs[lang];
        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getMenu(lang:String){
    this.apiService.getMenu(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.menu = obj.info;
        if(!lang){
          this.getdescriptionmenu();
        }
        else{
          this.menusharedService.send(this.menu);
        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  verifyclick(op,link,i_n){
    if(op==1 && link==0){
      this.showsubMenu[i_n] = !this.showsubMenu[i_n];
    }
    if(op==2 && link==1){
      this.showsubMenu[i_n] = !this.showsubMenu[i_n];
    }
  }


  menustyle(menu){
    let r: string = "";

    if(menu.box && menu.box==1){
      r += "border-style: solid;border-width: 1px; padding:2px 8px;" ;
    }
    if(menu.box && menu.box==1 && menu.color){
      r += "border-color:"+ menu.color+" ;";
    }else{
      //r += "border-color:#000;";
    }
    if(menu.color){
      r += "color:"+menu.color+"    ";
    }
    return this.sanitizer.bypassSecurityTrustStyle(r);
  }
  menustylemobile(menu){
    let r: String = "";
    if(menu.color){
      r += "color:"+menu.color+" ;";
    }
    return r;
  }


  changeshowMenu(op){
    if(window.innerWidth>=1200){
      this.showMenu=op;
    }

  }

   cartDesc(ct:any){
     if(ct.itemwithattr==1){
       let color = Object.keys(ct.colors)[0];
       let size = Object.keys(ct.colors[color])[0];
       return ct.langs[this.translate.currentLang].description+" - " + ct.colors[color][size].langs[this.translate.currentLang].name + " - "+ct.colors[color][size].number;
     }else{
       return ct.langs[this.translate.currentLang].description;
     }
   }

   cartPrice(item:any, op:Number){
     if(op==1){
       if(item.itemwithattr==1){
           let color = Object.keys(item.colors)[0];
           let key = Object.keys(item.colors[color])[0];
           return item.colors[color][key].priceOriginalTxt;
       }else{
         return item.priceOriginalTxt;
       }
     }else{
       if(item.itemwithattr==1){
           let color = Object.keys(item.colors)[0];
           let key = Object.keys(item.colors[color])[0];
           return item.colors[color][key].priceFinalTxt;
       }else{
         return item.priceFinalTxt;
       }
     }
   }

   cartImg(item:any){
     if(item.itemwithattr==1){
         let color = Object.keys(item.colors)[0];
         let key = Object.keys(item.colors[color])[0];
         if(this.browserwebp && item.colors[color][key].images[0].webp!=null && item.colors[color][key].images[0].webp!=""){
           return this.apiService.imagesDir+item.colors[color][key].images[0].webp;
         }
         return this.apiService.imagesDir+item.colors[color][key].images[0].photo;
     }else{
         if(this.browserwebp && item.images[0].webp!=null && item.images[0].webp!=""){
           return this.apiService.imagesDir+item.images[0].webp;
         }
         return this.apiService.imagesDir+item.images[0].photo;
     }
   }

   removeItemCart(ct:any, i:any){
     let arr:any=[];
       let obji:any = {} ;

       let desc:any= "";
       let cat:any = "";
       let brand:any = "";
       let color_name:any = "";
       let pval:any = "";
       let arrsendlitics = []; 
       let arrsendliticsga4 = [];

     if(ct.itemwithattr==1){
       let color = Object.keys(ct.colors)[0];
       let size = Object.keys(ct.colors[color])[0];
       obji.idcolor = color;
       obji.idsize = size;
       obji.iditem = ct.iditem;
       color_name = ct.colors[color][size].name;
       if(ct.colors[color][size].priceFinal) { pval = ct.colors[color][size].priceFinal; }
     }else{
       pval = ct.priceFinal;
       obji.iditem = ct.iditem;
     }



     if(this.translate.currentLang && ct.langs[this.translate.currentLang]){
       desc = ct.langs[this.translate.currentLang].description;
     }else{
       desc = ct.description;
     }
     if(this.translate.currentLang && ct.catlangs && ct.catlangs[this.translate.currentLang] && ct.catlangs[this.translate.currentLang].description) {
       cat = ct.catlangs[this.translate.currentLang].description;
     }
     brand = (ct.brandName) ? ct.brandName : '';


     arrsendlitics.push({
       'id': ct.iditem,
       'name': desc,
       'price': pval,
       'brand': brand,
       'category': cat,
       'variant': color_name,
       'quantity': Number(ct.quantity)
      });

      arrsendliticsga4.push({
        'item_id': ct.iditem,
        'item_name': desc,
        'price': pval,
        'item_brand': brand,
        'item_category': cat,
        'item_variant': color_name,
        'quantity': Number(ct.quantity)
       });

     arr.push(obji);
     this.apiService.removeCart(arr).subscribe((obj: any)=>{
       if(typeof obj!=="undefined" && obj.code==200){

        let total = 0;
         arrsendlitics.forEach((val) => {
          total += val.price;
           this.angulartics2GAEcommerce.ecAddProduct({
                 'id': val.id,
                 'name': val.name,
                 'category': val.category,
                 'brand': val.brand,
                 'variant': val.variant,
                 'price': val.price,
                 'quantity': val.quantity,
                 'position': 0
               });
               this.angulartics2GAEcommerce.ecSetAction("remove", {});

         });


         this.angulartics2.eventTrack.next({
           action: 'removeFromCart',
           properties: {
             label: 'removeFromCart',
             currency: "EUR",
             content_type: 'product_group',
             "event":"removeFromCart",
             gtmCustom:{
               'ecommerce': {
                 'purchase': undefined,
                 'click': undefined,
                 'checkout': undefined,
                 'detail': undefined,
                 'impressions': undefined,
                 'add': undefined,
                 'remove': {
                   'actionField': {'list': 'removeFromCart'},    // 'detail'
                   'products': arrsendlitics
                  },
                  'remove_from_cart': {
                    'currency': 'EUR', 'value':total,    // 'detail'
                    'items': arrsendliticsga4
                   }
                }
             }
           }
         });
          this.getCart(0);
       }else{
         this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
       }
     });
   }

   gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if(window.scrollY<=50){
      document.getElementsByTagName("body")[0].classList.remove("c-page-on-scroll");
      document.getElementById("goTop").style.display = "none";
    }else{
      document.getElementsByTagName("body")[0].classList.add("c-page-on-scroll");
      document.getElementById("goTop").style.display =  "block";
    }

    if(window.pageYOffset  > 100 && window.innerWidth<=991){
      this.fixedMenuMobile = true;
    }
    else{
      this.fixedMenuMobile = false;
    }
  }


   openMenu(){
     this.showMenu=true;
   }

   closeMenu(){
     this.showMenu=false;
     for (let index = 0; index < this.showsubMenu.length; index++) {
       this.showsubMenu[index] = false;
     }
   }


  getdescriptionmenu(){
    if(this.menu && this.menu.length>0){

      this.menu.forEach((menucicle: any, key: any) => {
          if(menucicle.langs && menucicle.langs[this.translate.currentLang]){
            this.menu[key].description = menucicle.langs[this.translate.currentLang];
          }
          if(menucicle.level_info){
            menucicle.level_info.forEach((submenu: any, keysub: any) => {
              if(submenu.langs && submenu.langs[this.translate.currentLang]){
                this.menu[key].level_info[keysub].description = submenu.langs[this.translate.currentLang];
                if(submenu.level_info){
                  submenu.level_info.forEach((submenu2: any, keysub2: any) => {
                    if(submenu2.langs && submenu2.langs[this.translate.currentLang]){
                      this.menu[key].level_info[keysub].level_info[keysub2].description = submenu2.langs[this.translate.currentLang];
                    }
                  });
                }
              }
            });
          }
      });

    }

    this.menusharedService.send(this.menu);
  }

  getLang(acc: any){
		let lang = this.translate.instant(acc.toUpperCase());
		return lang;
	}

}
