import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgSelect2Module } from 'ng-select2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { I18nModule } from './i18n/i18n.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ShowComponent } from './show/show.component';
import { ModalModule } from 'ngb-modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY,  RecaptchaV3Module } from 'ng-recaptcha';
import { ReactiveFormsModule, FormsModule }   from '@angular/forms';
import { PageComponent } from './page/page.component';

import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SigninComponent } from './signin/signin.component';
import { AccountComponent } from './account/account.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ShoworderComponent } from './showorder/showorder.component';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { UrlpipePipe } from './urlpipe.pipe';

import { CookieLawModule } from 'angular2-cookie-law';
import { LowerCasePipe } from '@angular/common';
 import { DeviceDetectorModule } from 'ngx-device-detector';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AgmCoreModule } from '@agm/core';

import { DatepickerModule } from 'ng2-datepicker';
import { DateFnsModule } from 'ngx-date-fns';

import { MomentModule } from 'ngx-moment';
import { BlogComponent } from './blog/blog.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { BlogdetailComponent } from './blogdetail/blogdetail.component';
import { ItemreviewComponent } from './itemreview/itemreview.component';
import { CookieService } from 'ngx-cookie-service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

const dbConfig: DBConfig  = {
  name: 'modaserverpro',
  version: 1,
  objectStoresMeta: [{
    store: 'show_itemsList',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } }
    ]
  } ]
};
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';

import { PlyrModule } from 'ngx-plyr';
import { RedirectComponent } from './redirect/redirect.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { PointsComponent } from './points/points.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PagenotfoundComponent,
    ShowComponent,
    ProductComponent,
    ContactComponent,
    PageComponent,
    RegisterComponent,
    CheckoutComponent,
    SigninComponent,
    AccountComponent,
    ProfileComponent,
    OrdersComponent,
    ResetpasswordComponent,
    ShoworderComponent,
    UrlpipePipe,
    BlogComponent,
    BlogdetailComponent,
    RedirectComponent,
    WishlistComponent,
    ItemreviewComponent,
    PointsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    I18nModule,
    NgxImageZoomModule ,
    SweetAlert2Module.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCCI7qRJur9j8e-XSlSZgIo_se9qymQP8E'
    }),
    CarouselModule,
    ModalModule,
    InfiniteScrollModule, 
    RecaptchaV3Module,
    ReactiveFormsModule ,
    FormsModule,
  	NgSelect2Module,
    SocialLoginModule,
    MaterialModule,
    BrowserAnimationsModule,
    CookieLawModule,
    DeviceDetectorModule,
    LazyLoadImageModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ScrollingModule,
    DatepickerModule,
    DateFnsModule.forRoot(),
    MomentModule,
    PlyrModule,
    NgImageFullscreenViewModule,
    NgbTooltipModule,
    NgbRatingModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  providers: [
    Title,
    UrlpipePipe,

    LowerCasePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2986046504773007'),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    CookieService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfnpukpAAAAAGvuiwNq7_BUJ6jrQJkmtfPHuGgz" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
