<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li><a [routerLink]="(['/account'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'myaccount' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'my_points' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="c-layout-sidebar-menu c-theme ">
			<div class="c-sidebar-menu-toggler">
					<span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
					<a href="javascript:;" class="c-content-toggler" data-toggle="collapse" data-target="#sidebar-menu-1">
						<span class="c-line"></span>
						<span class="c-line"></span>
						<span class="c-line"></span>
					</a>
			</div>
            <ul class="c-sidebar-menu collapse in" id="sidebar-menu-1">
                <li class="c-dropdown c-open">
                    <a href="javascript:;" class="c-toggler">{{ 'myaccount' | translate }}
                        <span class="c-arrow"></span>
                    </a>
                    <ul class="c-dropdown-menu">
                        <li class="c-active">
                            <a [routerLink]="(['/points'] | localize)" href="javascript:void(0);">{{ 'my_points' | translate }}</a>
                        </li>
                        <li class=" ">
                            <a [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
                        </li>
                        <li class="">
                            <a [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
                        </li>
                        <li class=" ">
                            <a [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
                        </li>
                        <li class="">
                            <a [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
                        </li>
                        <li>
                            <a (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
		</div>
		<div class="c-layout-sidebar-content ">
			<div class="c-margin-b-40 c-order-history-2">
                <div>{{ 'you_have' | translate }} <span class="c-font-30"><b style="color: #d48e0c;">{{this.userPoints}}</b></span> <ng-container *ngIf="this.userPoints == 1"> {{ 'point' | translate }}</ng-container><ng-container *ngIf="this.userPoints != 1"> <span class="text-lowercase"> {{ 'points' | translate }}</span></ng-container>.</div>
				<div class="table-responsive">
                    <div class="mat-app-background basic-container ">
						<div class="example-container ">
							<div class="example-loading-shade"
							*ngIf="isLoadingResults || isRateLimitReached">
							<mat-spinner *ngIf="isLoadingResults"></mat-spinner>
							<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
								{{ 'POINTS_ERROR' | translate }}
							</div>
						</div>
						<div class="example-table-container mat-elevation-z8">
							<table mat-table [dataSource]="dataTable" class="example-table" matSort matSortActive="pointsupdatedat" matSortDisableClear matSortDirection="desc">
                                <ng-container matColumnDef="updated_at">
                                    <th style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'date' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.created_at  | date: 'yyyy-MM-dd'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="idpointtype">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 235px;">{{ 'type' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        <ng-container *ngIf="row.idpointcategory == 1">{{ 'user_register' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 2">{{ 'login_website' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 3"><ng-container *ngIf="row.pendingpoints">{{ 'finish_buy_points' | translate }}</ng-container> <ng-container *ngIf="row.addpoints">{{ 'finish_buy_points_2' | translate }}</ng-container> <ng-container *ngIf="row.numberdoc"> {{row.numberdoc}}</ng-container></ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 4">{{ 'comment_product' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 5">{{ 'comment_product_photo' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 6">{{ 'newsletter_subscribe_points' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 7">{{ 'full_buy_shared_link' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 8">{{ 'buy_shared_link' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 9">{{ 'expired_points' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 10">{{ 'used_points' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 11">{{ 'returned_points' | translate }}</ng-container>
                                        <ng-container *ngIf="row.idpointcategory == 12">{{ 'item_points' | translate  }}</ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="points">
                                    <th style="min-width: 100px;" mat-header-cell *matHeaderCellDef>{{ 'points' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        <div class="badge btn-success" *ngIf="row.addpoints" tooltipClass="tooltip-custom-class" ngbTooltip="{{ 'added_points' | translate}}">+ {{row.addpoints}}</div>
                                        <div class="badge btn-danger" *ngIf="row.delpoints" tooltipClass="tooltip-custom-class" ngbTooltip="{{ 'removed_points' | translate}}">- {{row.delpoints}}</div>
                                        <div class="badge btn-warning" tooltipClass="tooltip-custom-class" ngbTooltip="{{ 'pending_points' | translate}}" *ngIf="row.pendingpoints">+ {{row.pendingpoints}}</div>
                                        <div class="badge btn-secondary" tooltipClass="tooltip-custom-class" ngbTooltip="{{ 'used_points' | translate}}" *ngIf="row.usedpoints">- {{row.usedpoints}}</div>
                                    </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="delpoints">
                                    <th style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'removed_points' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        <div class="badge btn-info">{{row.delpoints}}</div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="pendingpoints">
                                    <th style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'pending_points' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        <div class="badge btn-warning">{{row.pendingpoints}}</div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="usedpoints">
                                    <th style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'used' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        <div class="badge btn-danger">{{row.usedpoints}}</div>
                                    </td>
                                </ng-container> -->
                                <ng-container matColumnDef="expirationdate">
                                    <th style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'expires_in' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.expirationdate}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="totalpoints">
                                    <th style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'total_points' | translate }}</th>
                                    <td mat-cell *matCellDef="let row">
                                        <div class="badge btn-primary">{{row.totalpoints}}</div>
                                    </td>
                                </ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
					</div>
					<mat-paginator [hidePageSize]="true" [length]="resultsLength" [pageSize]="10"></mat-paginator>
				</div>
			</div>
				</div>
			</div>

            <div *ngIf="this.points && this.points.length > 0">
                <h4><i>{{ 'how_points_work' | translate }}</i></h4>

                <ng-container *ngFor="let point of this.points">
                    <ng-container *ngIf="point.idpointtype == 1"><i>{{ 'register_point' | translate }} - {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 2"><i>{{ 'login_website' | translate }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 3"><i>{{ 'finish_buy_points' | translate }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 4"><i>{{ 'leave_review_product' | translate }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 5"><i>{{ 'review_product_point' | translate }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 6"><i>{{ 'newsletter_subscribe_points' | translate }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 7"><i>{{ 'friend_purchased_item' | translate }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 8"><i>{{ 'you_made_purchase_shared' | translate }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                    <ng-container *ngIf="point.idpointtype == 12"><i>{{ 'item_points' | translate  }} -  {{point.points}} <ng-container *ngIf="point.points > 1">{{ 'points' | translate }}</ng-container> <ng-container *ngIf="point.points == 1">{{ 'point' | translate }}</ng-container></i><br></ng-container>
                </ng-container>
            </div>

            <ng-container *ngIf="this.gap">
                <ng-container *ngFor="let gap_info of this.gap[3]">
                    <div *ngIf="gap_info.idgap == '35'" style="margin-top: 25px;">
                        <button class="btn c-theme-bg c-font-white c-btn-square cartBtn" href="javascript:void(0)" [routerLink]="(['/page',   (gap_info.subject | urlpipe | lowercase), gap_info.idgap  ] | localize)">
                            {{ 'learn_more_legal_points' | translate }} 
                            <i class="fa fa-arrow-right"></i>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
		</div>
	</div>
</div>