import { Component, OnInit, ViewChild  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CartsharedService } from '../cartshared.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared.service';
import { Options } from 'select2';
import { MustMatch, NifValidatorPT } from '../must-match.validator';
 import { Router, ActivatedRoute , ParamMap  } from '@angular/router';
import { LocalstorageService } from '../localstorage.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LoginService } from '../login.service';
import { SessionService } from '../session.service';
declare var $: any;

import { ModalManager } from 'ngb-modal';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { CheckwebpService } from '../checkwebp.service';

import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { TitlechangeService } from '../titlechange.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('invoicecountry') invoicecountry;
  @ViewChild('invoiceaddress') invoiceaddress;
  @ViewChild('invoicelocality') invoicelocality;
  @ViewChild('invoicepostalcode') invoicepostalcode;
  @ViewChild('shippingcountry') shippingcountry;
  @ViewChild('shippingaddress') shippingaddress;
  @ViewChild('shippinglocality') shippinglocality;
  @ViewChild('shippingpostalcode') shippingpostalcode;
  @ViewChild('voucherCode') voucherCodeInput;
  @ViewChild('pointsExchange') pointsExchange;
  @ViewChild('ModalPasswordCh') ModalPassword;
  @ViewChild('ModalSignin') ModalSignin;
  @ViewChild('emailrecoverPassCh') emailrecoverPass;
  @ViewChild('codepostal_pickup') codepostal_pickup;

  @ViewChild('pickupDetails') pickupDetails;
  optionsCountry: Options;
  optionsCalling: Options;
  signinForm:FormGroup;

  countries:any;
  cartsubscription:Subscription;
  clickEventsubscriptionLogin:any;
  cart:any;
  cartTotal:any;
  companyInfo:any;
  shippingTotal:any;
  checkboxReplicate:any;
  checkboxPassword:any;
  shippingInfo:any;
  selectShippingmode:any;
  selectShippingcompany:any;
  selectPayment:any;
  checkoutForm:FormGroup;
  submitted = false;
  submittedSign = false;
  pickupShipping:any;
  pickupShippingArr:any;
  pickupShippingIndex:any;
  voucherError:any;
  vouchersArr:any;
  waitingsubmit:any;
  userInfo:any;
  loadUser:any;
  subscribe:any;
  hiddenLoad:any;
  emailCheck:any;
  modalRecoverRef:any;
  browserwebp:any;
  backcheck:any;
  userLogging:any;
  minbuy:any;
  shippingfeefail:any;
  clienttype:any;
  voucherText:any;
  taxprocess: any;

  dpd_lat:number = 0;
  dpd_lng:number = 0;
  zoom:any = 11;
  pickup_selected:any;

  modalSignin:any;
  pickupDetailsRef:any;

  oldShipSelected: any;

  userexchangepoints: any = 0;
  ac_points:any;

  qtdPoints:any;
  showpoints: boolean = false;
  showMsgErrorPoints: boolean = false;
  msgErrorPoints: any;
  errorPointsLimit: 0;

  salesPoints: any;

  shipping_address_oldvalue:any;
  shipping_address2_oldvalue:any;

  constructor(private _cookieService:CookieService, public titlechangeService:TitlechangeService,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public checkwebp: CheckwebpService,private seoService: SeoService, public localize: LocalizeRouterService,private aroute: ActivatedRoute,private modalService: ModalManager,public sessionService: SessionService,private loginService: LoginService, private metaService: Meta, public titleService: Title, public localstorageService: LocalstorageService,private route: Router,  public apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder, public cartsharedService: CartsharedService, public sharedService: SharedService) {
    this.backcheck = this.aroute.snapshot.paramMap.get('back');
    this.hiddenLoad=true;
    this.waitingsubmit=false;
    this.loadUser=false;
    this.emailCheck=false;
    this.pickupShippingArr = null;
    this.clienttype = this._cookieService.get('clienttype');
    this.voucherText="";
    if(this.aroute.snapshot.queryParamMap.get('alert-danger')){
        this.translate.get( this.aroute.snapshot.queryParamMap.get('alert-danger') ).subscribe(translations => {  Swal.fire(translations);    });
    }

    this.userLogging = this.localstorageService.get("userLogging");

    this.browserwebp = this.checkwebp.canUseWebP();

    this.signinForm = this.formBuilder.group({
        rememberme: new FormControl('' ),
        email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]),
        password: ['', [Validators.required, Validators.minLength(1)]]
      });
    this.checkoutForm = this.formBuilder.group({
      firstname: new FormControl('',[ Validators.required ]),
      lastname: new FormControl(''),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")  ] ),
      mobilephone: new FormControl('',[ Validators.required ]),

      nif: new FormControl(''  ),
      address: new FormControl('',[ Validators.required ]  ),
      address2: new FormControl('' ),
      postalcode: new FormControl('',[ Validators.required ]  ),
      locality: new FormControl('',[ Validators.required ]  ),
      idcountry: new FormControl('',[ Validators.required ]  ),

      shipping_company: new FormControl('', ),
      shipping_responsable: new FormControl('', [  Validators.required]),
      shipping_address: new FormControl('', [  Validators.required]),
      shipping_address2: new FormControl('', ),
      shipping_postalcode: new FormControl('', [  Validators.required]),
      shipping_locality: new FormControl('', [  Validators.required]),
      shipping_idcountry: new FormControl('', [  Validators.required] )
    }, {
            validator: [  NifValidatorPT('idcountry', 'nif') ]
        } );



    this.cartsubscription =    this.cartsharedService.getcartEvent().subscribe((obj)=>{
      if(obj && obj[1] && obj[1].sum){
        obj[1].sum = parseFloat(obj[1].sum);
        obj[1].discountvoucher = parseFloat(obj[1].discountvoucher);
      }
      this.cart = obj[0];
      this.cartTotal = obj[1];
      this.companyInfo = obj[2];
      this.shippingTotal = obj[3];
      this.vouchersArr = obj[4];
      this.shippingfeefail = obj[5];
    });

    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/checkout' );
	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/checkout');
      this.metatags(lang.lang);
    });

      this.clickEventsubscriptionLogin =  this.loginService.getloginEvent().subscribe(()=>{
        this.getUserInfo(1);
      });


    this.shippingTotal = 0;
    this.pickupShippingIndex=-1;
    this.getWarning(this.translate.currentLang);

    this.apiService.updateSession().subscribe((obj: any)=>{});
  }

  get f() { return this.checkoutForm.controls; }
  get fSign() { return this.signinForm.controls; }

  ngOnDestroy(): void {
    this.cartsubscription.unsubscribe();
	   this.subscribe.unsubscribe();
     this.clickEventsubscriptionLogin.unsubscribe();
     this.seoService.clearAlternateUrl();
     this.seoService.removeTag("rel=canonical");
  }


  clickScroll(div){
    document.getElementById(div).scrollIntoView();
    window.scrollTo({
         top: window.scrollY-35,
         behavior: "smooth"
    });
  }

  mouseStyle(div){
    document.getElementById(div).style.cursor = 'pointer';
  }


  getWarning(lang){
    this.apiService.getWarning(lang, 2).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(!obj.info || Object.values(obj.info).length==0){
          return 0;
        }
        let warning_ =  this.localstorageService.get("warning_");
        let datenow:any = new Date();
        datenow = datenow.toISOString().split('T')[0];
        let newwar = [];
        if(warning_){
          newwar = warning_;
          let entry = false;
          for (let index = 0; index < warning_.length; index++) {
             if(warning_[index][0]==obj.info.idwebsitewarning.toString() && warning_[index][1]==datenow){
               entry = true; break;
             }
             if(warning_[index][0]==obj.info.idwebsitewarning.toString()){
               newwar.splice(index, 1);
             }
          }
          if(!entry){
            newwar.push([obj.info.idwebsitewarning.toString(), datenow]);

            this.localstorageService.set("warning_", newwar);
            Swal.fire({
              title: obj.info.name,
              imageUrl: this.apiService.imagesDir+obj.info.image,
              imageWidth: 800,
              html: obj.info.description,
              allowOutsideClick: true,
  		          position : 'center'
             });
          }
        }else{

          this.localstorageService.set("warning_", [[obj.info.idwebsitewarning.toString(), datenow]]);

          Swal.fire({
            title: obj.info.name,
            imageUrl: this.apiService.imagesDir+obj.info.image,
            imageWidth: 800,
            html: obj.info.description,
            allowOutsideClick: true,
		          position : 'center'
           });


        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  sendinitialcheckout(step:any, option:any): void {
		let desc:any= "";
		let cat:any = "";
		let brand:any = "";
		let color:any = "";
		let pval:any = "";
		let idcolor:any;
		let idsize:any;
		let galayer:any = [];
    let galayerga4:any = [];

		for (let index = 0; index < this.cart.length; index++) {

			if(this.translate.currentLang && this.cart[index].langs[this.translate.currentLang]){
				desc = this.cart[index].langs[this.translate.currentLang].description;
			}else{
				desc = this.cart[index].description;
			}
			if(this.translate.currentLang && this.cart[index].catlangs && this.cart[index].catlangs[this.translate.currentLang] && this.cart[index].catlangs[this.translate.currentLang].description) {
				cat = this.cart[index].catlangs[this.translate.currentLang].description;
			}
			brand = (this.cart[index].brandName) ? this.cart[index].brandName : '';
			if(this.cart[index].itemwithattr==1){
				idcolor = Object.keys(this.cart[index].colors)[0];
				idsize = Object.keys(this.cart[index].colors[idcolor])[0];
				color = this.cart[index].colors[idcolor][idsize].name;
				if(this.cart[index].colors[idcolor][idsize].priceFinal) { pval = this.cart[index].colors[idcolor][idsize].priceFinal; }
			}else{
				pval = this.cart[index].priceFinal;
			}
			this.angulartics2GAEcommerce.ecAddProduct({
						'id': this.cart[index].iditem,
						'name': desc,
						'category': cat,
						'brand': brand,
						'quantity': this.cart[index].quantity,
						'price': pval,
						'variant': color,
						'position': index,
					});
					this.angulartics2GAEcommerce.ecSetAction("checkout", {'step':step, 'option':option});

				 	galayer.push({
						'id': this.cart[index].iditem,
						'name': desc,
						'price': pval,
						'brand': brand,
						'quantity': this.cart[index].quantity,
						'category': cat,
						'variant': color
          });

          galayerga4.push({
						'item_id': this.cart[index].iditem,
						'item_name': desc,
						'price': pval,
						'item_brand': brand,
						'quantity': this.cart[index].quantity,
						'item_category': cat,
						'item_variant': color,
          });
		}

		if(step=="1"){
			this.angulartics2.eventTrack.next({
				action: 'Checkout',
				properties: {
					label: 'Checkout',
					currency: "EUR",
					content_type: 'product_group',
					"event":"Checkout",
					gtmCustom:{
						'ecommerce': {
							'purchase': undefined,
		          'click': undefined,
		          'add': undefined,
		          'detail': undefined,
		          'impressions': undefined,
		          'remove': undefined,
							'checkout': {
								'actionField': {'list': 'Checkout', 'step':step, 'option':option},    // 'detail'
								'products': galayer
							 },
               'begin_checkout': {
								'currency': 'EUR', 
                'value':this.cartTotal.total,    // 'detail'
								'items': galayerga4
							 }
						 }
					}
				}
			});
		}
    // SEND CLICK PURCHASE EVENT
    if(step == "2"){
      this.angulartics2.eventTrack.next({
        action: 'select_content',
        properties: {
          label: 'select_content',
          content_type: 'checkoutClickToBuy',
          item_id: 'checkoutClickToBuy',
          "event":"select_content",
          gtmCustom:{
            'ecommerce': {
              'purchase': undefined,
              'click': undefined,
              'add': undefined,
              'checkout': undefined,
              'detail': undefined,
              'impressions': undefined,
              'remove': undefined
            }
          }
        }
      });
    }
	}


  openModalRecover(){
        this.modalRecoverRef = this.modalService.open(this.ModalPassword, {
            size: 'md',
            windowClass: 'modal-md'  ,
            hideCloseButton: false,
            centered: true,
            animation: true,
            keyboard: false,
            closeOnOutsideClick: false,
			backdropClass: 'modal-backdrop'
        });
        this.modalRecoverRef.onOpen.subscribe(() => {

                $("modal .fade.show").addClass("in");
            });
    }

    closeModalRecover(){
        this.modalService.close(this.modalRecoverRef);
    }

  onSubmitSignin() {
    if(this.signinForm && this.signinForm.controls.email && this.signinForm.controls.email.value){
      this.signinForm.controls.email.setValue( this.signinForm.controls.email.value.toString().trim() );
      this.signinForm.controls.email.setValue( this.signinForm.controls.email.value.toString().toLowerCase() );
    }
    this.submittedSign = true;
    if (this.signinForm.invalid) {
      return;
    }
    var formData = new FormData();
    Object.keys(this.signinForm.controls).forEach(key => {
      if(this.signinForm.controls[key].value){
        formData.append("userweb["+key+"]", this.signinForm.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });
    this.apiService.signin(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.submittedSign = false;
        this.localstorageService.set("userLogging", true);
        this.userLogging = true;
        this.localstorageService.set("userLoggingName", obj.info);
        this.sessionService.sendsession(true, obj.info.name);
        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.signinForm.controls["email"].setValue("");
        this.signinForm.controls["password"].setValue("");
        this.getUserPoints();
        // this.closeModalSignin();
        if(obj.updatedWishlist && obj.updatedWishlist==true){
          this.translate.get( 'wishlist_updated' ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } }); });
        }
        this.getUserInfo(1);
      }else if(obj.code==302){
this.submittedSign = false;

        this.localstorageService.set("userLogging", true);
        this.sessionService.sendsession(true, "");
        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.userLogging = true;


        this.getUserInfo(1);

      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }




  getUserInfo(op:any){
    this.apiService.getUserInfo(this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.emailCheck = true;
        this.loadUser = true;
        this.userInfo=obj.info;
        this.clienttype = obj.info.type;
        let first=this.userInfo.name;
        first = this.userInfo.name.substr(0,this.userInfo.name.indexOf(' '));
        if(first==""){
          first = this.userInfo.name;
        }
        let last = this.userInfo.name.substr(this.userInfo.name.indexOf(' ')+1);

        if(this.clienttype == '0'){
          if(this.checkoutForm.controls['firstname'].value=="" || op==0){
            this.checkoutForm.controls['firstname'].setValue(this.userInfo.name);
          }
        }
        else{
          if(this.checkoutForm.controls['firstname'].value=="" || op==0){
            this.checkoutForm.controls['firstname'].setValue(first);
          }
          if(this.checkoutForm.controls['lastname'].value=="" || op==0){
            this.checkoutForm.controls['lastname'].setValue(last);
          }
        }
        if(this.checkoutForm.controls['address'].value=="" || op==0){
          this.checkoutForm.controls['address'].setValue(this.userInfo.address);
        }
        this.checkoutForm.controls['address2'].setValue(this.userInfo.address2);
        if(this.checkoutForm.controls['postalcode'].value=="" || op==0){
          this.checkoutForm.controls['postalcode'].setValue(this.userInfo.postalcode);
        }
        if(this.checkoutForm.controls['locality'].value=="" || op==0){
          this.checkoutForm.controls['locality'].setValue(this.userInfo.locality);
        }
        this.checkoutForm.controls['nif'].setValue(this.userInfo.nif);
        let countryclient:any="0";
        let changec=false;
        if(op==0){
          changec=true;
          if(this.userInfo.idregion){
            this.checkoutForm.controls['idcountry'].setValue(this.userInfo.idcountry+"-"+this.userInfo.idregion);
            this.checkoutForm.controls['shipping_idcountry'].setValue(this.userInfo.idcountry+"-"+this.userInfo.idregion);
              countryclient = this.userInfo.idcountry+"-"+this.userInfo.idregion;
          }else{
            this.checkoutForm.controls['idcountry'].setValue(this.userInfo.idcountry);
            this.checkoutForm.controls['shipping_idcountry'].setValue(this.userInfo.idcountry );
            countryclient = this.userInfo.idcountry;
          }
        }else{
          if(this.userInfo.idregion){
              countryclient = this.userInfo.idcountry+"-"+this.userInfo.idregion;
          }else{
            countryclient = this.userInfo.idcountry;
          }
          if(this.checkoutForm.controls['idcountry'].value!=countryclient){
            changec=true;
            this.checkoutForm.controls['shipping_idcountry'].setValue(countryclient);
            this.checkoutForm.controls['idcountry'].setValue(countryclient);
          }
        }

        this.checkoutForm.controls['email'].setValue(this.userInfo.email.toString().toLowerCase());

    //    this.checkoutForm.controls['telephone'].setValue(this.userInfo.telephone);

        if(changec){
          this.apiService.getShippings(countryclient, countryclient, this.userInfo.address, this.userInfo.postalcode, this.userInfo.locality).subscribe((obj: any)=>{ 

            if(typeof obj!=="undefined" && obj.code==200){
              this.shippingInfo = obj.info;
              if(this.shippingInfo[0]){
                this.selectShippingmode = this.shippingInfo[0].idshippingmode;
                if(this.selectShippingmode==1 && this.shippingInfo[0].companies.length==1){
                  this.selectShippingcompany = this.shippingInfo[0].companies[0].idshippingcompany;
                    this.getShippingCosts();
                    if(this.shippingInfo[0].companies[0].abbreviation=="CNP2"){this.pickupShipping=1;}else{this.pickupShipping=0;}
                } 
              }
            }else{
              this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
            }
          });
        }

        if(obj.voucher && obj.voucher.length>0){
          for (let index = 0; index < obj.voucher.length; index++) {
            this.addVoucher(obj.voucher[index].code);
          }
        }

      }else if(obj.code==302){ 
        this.clienttype = 0;  
      }else{
        this.clienttype = 0;
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }
  
  openModalSignin(){
    this.modalSignin = this.modalService.open(this.ModalSignin, {
        size: 'md',
        windowClass: 'modal-md'  ,
        hideCloseButton: false,
        centered: true,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: false,
        backdropClass: 'modal-backdrop'
    });
    this.modalSignin.onOpen.subscribe(() => {
      $("modal .fade.show").addClass("in");
    });
}

closeModalSignin(){
    this.modalService.close(this.modalSignin);
}

  ngOnInit(): void {


    this.selectShippingcompany=null;
    this.pickupShipping=0;
    this.selectShippingmode=null;
    this.selectPayment=null;
	  this.metatags(this.translate.currentLang);
    this.getCountry();
    this.getCallingcode();
    this.getPoints();
    this.getUserPoints();
    this.getSalesPoints();
    this.checkboxReplicate=1;
    this.checkboxPassword=1;

	this.optionsCalling = {
      templateSelection: function (data, container) {
		return "+"+data.text;
	 },
      templateResult: function (data, container) {
		return "+"+data.text;
	 }
    };

  }

  public setTitle( newTitle: string) {
	  this.titleService.setTitle( newTitle  );this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(4,9,lang, 'MENU_CHECKOUT').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title && obj.title.content){

          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }


        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  ngAfterViewInit(){
    if(!this.cart){
      this.getCart(0);
      this.getCompany();
    }else{
			this.sendinitialcheckout("1", "");
		}
  }



  sendinitialpurchase(obj): void {

		let desc:any= "";
		let cat:any = "";
		let brand:any = "";
		let color:any = "";
		let pval:any = "";
		let idcolor:any;
		let idsize:any;
		let galayer:any = [];
    let galayerga4:any = [];

    obj.total = Number(obj.total);
    
		for (let index = 0; index < this.cart.length; index++) {

			if(this.translate.currentLang && this.cart[index].langs[this.translate.currentLang]){
				desc = this.cart[index].langs[this.translate.currentLang].description;
			}else{
				desc = this.cart[index].description;
			}
			if(this.translate.currentLang && this.cart[index].catlangs && this.cart[index].catlangs[this.translate.currentLang] && this.cart[index].catlangs[this.translate.currentLang].description) {
				cat = this.cart[index].catlangs[this.translate.currentLang].description;
			}else{ cat = '' }
			brand = (this.cart[index].brandName) ? this.cart[index].brandName : 'Brandsibuy';
			if(this.cart[index].itemwithattr==1){
				idcolor = Object.keys(this.cart[index].colors)[0];
				idsize = Object.keys(this.cart[index].colors[idcolor])[0];
				color = this.cart[index].colors[idcolor][idsize].name;
				if(this.cart[index].colors[idcolor][idsize].priceFinal) { pval = Number(this.cart[index].colors[idcolor][idsize].priceFinal); }
			}else{
				pval = Number(this.cart[index].priceFinal);
			}

			galayer.push({
				'id': this.cart[index].iditem,
				'name': desc,
				'price': pval,
				'brand': brand,
				'quantity': this.cart[index].quantity,
				'category': cat,
				'variant': color
      });
      galayerga4.push({
				'item_id': this.cart[index].iditem,
				'item_name': desc,
				'price': pval,
				'item_brand': brand,
				'quantity': parseInt(this.cart[index].quantity),
				'item_category': cat,
				'item_variant': color,
      });
		}

    this.angulartics2GAEcommerce.ecSetAction("purchase" , {'id': obj.number, 'revenue':obj.total, 'shipping':this.shippingTotal});
		this.angulartics2.eventTrack.next({
			action: 'Purchase',
			properties: {
				label: 'Purchase',
				currency: "EUR",
				content_type: 'product_group',
				"event":"Purchase",
				gtmCustom:{
					'ecommerce': {
	          'click': undefined,
	          'add': undefined,
	          'checkout': undefined,
	          'detail': undefined,
	          'impressions': undefined,
	          'remove': undefined,
						'purchase': {
							'actionField': {'id': obj.number, 'revenue':obj.total, 'shipping':this.shippingTotal},    // 'detail'
							'products': galayer
						 }
					 }
				}
			}
		});
    // GA4 Purchase
    this.angulartics2.eventTrack.next({
			action: 'purchase',
			properties: {
				label: 'purchase',
				currency: "EUR",
				content_type: 'product_group',
				"event":"purchase",
				gtmCustom:{
					'ecommerce': {
	          'click': undefined,
	          'add': undefined,
	          'checkout': undefined,
	          'detail': undefined,
	          'impressions': undefined,
	          'remove': undefined,
						'purchase': {
              'currency':'EUR', 'transaction_id': obj.number, 'value':obj.total, 'shipping':this.shippingTotal,    // 'detail'
              'items': galayerga4,
						 }
					 }
				}
			}
		});
	}

  getCart(change: any){
    if(change==0){
      this.apiService.getCart(1).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          if(obj.info && obj.info.length>0){
            this.cart = obj.info;
            if(obj.points){
              this.userexchangepoints = Number(obj.points);
            }
            else{
              this.userexchangepoints = 0;
            }
            obj.calc.values.summary.sum = parseFloat(obj.calc.values.summary.sum);
            obj.calc.values.summary.total = parseFloat(obj.calc.values.summary.total);
            obj.calc.values.summary.discountvoucher = parseFloat(obj.calc.values.summary.discountvoucher);
            this.cartTotal = obj.calc.values.summary;
            this.sendinitialcheckout("1", "");
            if(obj.shippingCost){
              this.shippingTotal = obj.shippingCost;
            }else{
              this.shippingTotal = 0;
            }
            if(typeof obj.shippingfee==="undefined"){
              this.shippingfeefail = 0;
            }else{
              this.shippingfeefail = obj.shippingfee;
            }

            if(obj.vouchers){
              this.vouchersArr = obj.vouchers;
            }else{
              this.vouchersArr = [];
            }
            if(obj.taxprocess){
              obj.taxprocess = Number(obj.taxprocess);
              this.taxprocess = obj.taxprocess;
            }
            else{
              this.taxprocess = 0;
            }
            if(obj.cartupdated){ this.translate.get( 'cartChanged' ).subscribe(translations => {  Swal.fire(translations); });}
          }
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }


  numberformat(value){
    return value.toFixed(2).replace(",", " ").replace(".", ",");
  }

  getCompany(){
    this.apiService.readCompany(0, "").subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.companyInfo = obj.info;
        this.minbuy = Number(obj.info.mintotal);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getCountry(){
    this.apiService.getCountry().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        const lang = this.translate.currentLang;

     obj.infoarray.sort((a,b) => (a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.description[lang].normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0))

       this.countries = obj.infoarray;
       this.getUserInfo(0);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getCallingcode(){
  /*  this.apiService.getCallingcode().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.telephone_callingcode = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });*/
  }

  cartImg(item:any){
    if(item.itemwithattr==1){
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        if(this.browserwebp && item.colors[color][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[color][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[color][key].images[0].photo;
    }else{
        if(this.browserwebp && item.images[0].webp){
          return this.apiService.imagesDir+item.images[0].webp;
        }
        return this.apiService.imagesDir+item.images[0].photo;
    }
  }

  cartDesc(ct:any){
    if(ct.itemwithattr==1){
      let color = Object.keys(ct.colors)[0];
      let size = Object.keys(ct.colors[color])[0];
      return ct.langs[this.translate.currentLang].description+" - " + ct.colors[color][size].langs[this.translate.currentLang].name + " - "+ct.colors[color][size].number;
    }else{
      return ct.langs[this.translate.currentLang].description;
    }
  }

  cartPrice(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceOriginal;
      }else{
        return item.priceOriginal;
      }
    }else{
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinal;
      }else{
        return item.priceFinal;
      }
    }
  }

  cartPriceTxt(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceOriginalTxt;
      }else{
        return item.priceOriginalTxt;
      }
    }else{
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinalTxt;
      }else{
        return item.priceFinalTxt;
      }
    }
  }


  removeItemCart(ct:any, i:any){
    let arr:any=[];
      let obji:any = {} ;
    if(ct.itemwithattr==1){
      let color = Object.keys(ct.colors)[0];
      let size = Object.keys(ct.colors[color])[0];
      obji.idcolor = color;
      obji.idsize = size;
      obji.iditem = ct.iditem;
    }else{
      obji.iditem = ct.iditem;
    }


    let desc:any= "";
		let cat:any = "";
		let brand:any = "";
		let color:any = "";
		let pval:any = "";


		if(this.translate.currentLang && ct.langs[this.translate.currentLang]){
			desc = ct.langs[this.translate.currentLang].description;
		}else{
			desc = ct.description;
		}
		if(this.translate.currentLang && ct.catlangs && ct.catlangs[this.translate.currentLang] && ct.catlangs[this.translate.currentLang].description) {
			cat = ct.catlangs[this.translate.currentLang].description;
		}
		brand = (ct.brandName) ? ct.brandName : '';

		if(ct && ct.itemwithattr==1){
			color = ct.colors[obji.idcolor][obji.idsize].name;
			if(ct.colors[obji.idcolor][obji.idsize].priceFinal) { pval = ct.colors[obji.idcolor][obji.idsize].priceFinal; }
		}else{
			pval = ct.priceFinal;
		}


    arr.push(obji);
    this.apiService.removeCart(arr).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.getSalesPoints();
         this.sharedService.sendClickEvent(this.translate.currentLang);


         this.angulartics2GAEcommerce.ecAddProduct({
              'id': ct.iditem,
              'name': desc,
              'category': cat,
              'brand': brand,
              'variant': color,
              'price': pval,
              'quantity': ct.quantity,
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("remove", {});

            this.angulartics2.eventTrack.next({
      				action: 'removeFromCart',
      				properties: {
                label: 'removeFromCart',
      					currency: "EUR",
      					content_type: 'product_group',
                "event":"removeFromCart",
                gtmCustom:{
                  'ecommerce': {
										'purchase': undefined,
					          'click': undefined,
					          'add': undefined,
					          'checkout': undefined,
					          'detail': undefined,
					          'impressions': undefined,
                    'remove': {
                      'actionField': {'list': 'removeFromCart'},    // 'detail'
                      'products': [
                        {
                          'id': ct.iditem,
                          'name': desc,
                          'price': pval,
                          'brand': brand,
                          'category': cat,
                          'variant': color,
                          'quantity': ct.quantity
                         }
                      ]
                     },
                     'remove_from_cart': {
                      'currency': 'EUR', 'value':pval,    // 'detail'
                      'items': [
                        {
                          'item_id': ct.iditem,
                          'item_name': desc,
                          'price': pval,
                          'item_brand': brand,
                          'item_category': cat,
                          'item_variant': color,
                          'quantity': ct.quantity
                         }
                      ]
                     }
                   }
                }
      				}
      			});
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  descProd(item){
    if(item.quantity>1){
      let arr:any=[];
      let obj: any = {};
      if(item && item.itemwithattr==1){
        obj.idcolor = item.idcolor;
        obj.idsize = item.idsize;
        obj.fullcode = item.code+"-"+obj.idcolor+"-"+obj.idsize;
        obj.idcolor_integration = item.colors[item.idcolor][item.idsize].idcolor_integration ;
        obj.idsize_integration = item.colors[item.idcolor][item.idsize].idsize_integration ;
      }else{
        obj.fullcode = item.code;
      }
      obj.iditem = item.iditem;
      obj.idmanager_company = item.idmanager_company;
      obj.iditem_integration = item.iditem_integration;
      obj.quantitycomplete = 1;
      obj.quantity = Number(item.quantity)-1;
      arr.push(obj);

      let desc:any= "";
	    let cat:any = "";
	    let brand:any = "";
	    let color:any = "";
	    let pval:any = "";

	    if(this.translate.currentLang && item.langs[this.translate.currentLang]){
	      desc = item.langs[this.translate.currentLang].description;
	    }else{
	      desc = item.description;
	    }
	    if(this.translate.currentLang && item.catlangs && item.catlangs[this.translate.currentLang] && item.catlangs[this.translate.currentLang].description) {
	      cat = item.catlangs[this.translate.currentLang].description;
	    }
	    brand = (item.brandName) ? item.brandName : '';

			if(item && item.itemwithattr==1){
				color = item.colors[item.idcolor][item.idsize].name;
				if(item.colors[item.idcolor][item.idsize].priceFinal) { pval = item.colors[item.idcolor][item.idsize].priceFinal; }
			}else{
				pval = item.priceFinal;
			}


      this.apiService.addCart(arr).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.getSalesPoints();
          this.sharedService.sendClickEvent(this.translate.currentLang);
          this.updateShippings();

          this.angulartics2GAEcommerce.ecAddProduct({
	              'id': item.iditem,
	              'name': desc,
	              'category': cat,
	              'brand': brand,
	              'variant': color,
	              'price': pval,
	              'quantity': 1,
	              'position': 0
	            });
	            this.angulartics2GAEcommerce.ecSetAction("remove", {});

	            this.angulartics2.eventTrack.next({
	      				action: 'removeFromCart',
	      				properties: {
	                label: 'removeFromCart',
	      					currency: "EUR",
	      					content_type: 'product_group',
	                "event":"removeFromCart",
	                gtmCustom:{
	                  'ecommerce': {
											'purchase': undefined,
						          'click': undefined,
						          'add': undefined,
						          'checkout': undefined,
						          'detail': undefined,
						          'impressions': undefined,
	                    'remove': {
	                      'actionField': {'list': 'removeFromCart'},    // 'detail'
	                      'products': [
	                        {
	                          'id': item.iditem,
	                          'name': desc,
	                          'price': pval,
	                          'brand': brand,
	                          'category': cat,
	                          'variant': color,
	                          'quantity': 1
	                         }
	                      ]
	                     },
                       'remove_from_cart': {
	                      'currency': 'EUR', 'value':pval,    // 'detail'
	                      'items': [
	                        {
	                          'item_id': item.iditem,
	                          'item_name': desc,
	                          'price': pval,
	                          'item_brand': brand,
	                          'item_category': cat,
	                          'item_variant': color,
	                          'quantity': 1
	                         }
	                      ]
	                     }
	                   }
	                }
	      				}
	      			});
        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }
  }

  incProd(item){
    let arr:any=[];
    let obj: any = {};
    if(item && item.itemwithattr==1){
      obj.idcolor = item.idcolor;
      obj.idsize = item.idsize;
      obj.fullcode = item.code+"-"+obj.idcolor+"-"+obj.idsize;
      obj.idcolor_integration = item.colors[item.idcolor][item.idsize].idcolor_integration ;
      obj.idsize_integration = item.colors[item.idcolor][item.idsize].idsize_integration ;
    }else{
      obj.fullcode = item.code;
    }
    obj.iditem = item.iditem;
    obj.idmanager_company = item.idmanager_company;
    obj.iditem_integration = item.iditem_integration;
    obj.quantitycomplete = 1;
    obj.quantity = Number(item.quantity)+1;
    arr.push(obj);


    let desc:any= "";
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && item.langs[this.translate.currentLang]){
      desc = item.langs[this.translate.currentLang].description;
    }else{
      desc = item.description;
    }
    if(this.translate.currentLang && item.catlangs && item.catlangs[this.translate.currentLang] && item.catlangs[this.translate.currentLang].description) {
      cat = item.catlangs[this.translate.currentLang].description;
    }
    brand = (item.brandName) ? item.brandName : '';

		if(item && item.itemwithattr==1){
			color = item.colors[item.idcolor][item.idsize].name;
			if(item.colors[item.idcolor][item.idsize].priceFinal) { pval = item.colors[item.idcolor][item.idsize].priceFinal; }
		}else{
			pval = item.priceFinal;
		}


    this.apiService.addCart(arr).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.getSalesPoints();
        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.updateShippings();

        this.angulartics2GAEcommerce.ecAddProduct({
              'id': item.iditem,
              'name': desc,
              'category': cat,
              'brand': brand,
              'variant': color,
              'price': pval,
              'quantity': 1,
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("add", {});

            this.angulartics2.eventTrack.next({
      				action: 'addToCart',
      				properties: {
                label: 'addToCart',
      					currency: "EUR",
      					content_type: 'product_group',
                "event":"addToCart",
                gtmCustom:{
                  'ecommerce': {
										'purchase': undefined,
					          'click': undefined,
					          'checkout': undefined,
					          'detail': undefined,
					          'impressions': undefined,
					          'remove': undefined,
                    'add': {
                      'actionField': {'list': 'addToCart'},    // 'detail'
                      'products': [
                        {
                          'id': item.iditem,
                          'name': desc,
                          'price': pval,
                          'brand': brand,
                          'category': cat,
                          'variant': color,
                          'quantity': 1
                         }
                      ]
                     },
                     'add_to_cart': {
                      'currency': 'EUR', 'value':pval ,    // 'detail'
                      'items': [
                        {
                          'item_id': item.iditem,
                          'item_name': desc,
                          'price': pval,
                          'item_brand': brand,
                          'item_category': cat,
                          'item_variant': color,
                          'quantity': 1
                         }
                      ]
                     }
                   }
                }
      				}
      			});
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  updateShippings(){
    let countryclient  =  this.invoicecountry.nativeElement.value ;
    let country  = "";
    let address = "";
    let postalcode  = "";
    let locality = "";
    if(this.checkboxReplicate==1){
        country  =  this.shippingcountry.nativeElement.value ;
        address  =  this.shippingaddress.nativeElement.value ;
        postalcode  =  this.shippingpostalcode.nativeElement.value ;
        locality  =  this.shippinglocality.nativeElement.value ;
        if(country==""){
          country  =  this.invoicecountry.nativeElement.value ;
        }
    }else{
        country  =  this.invoicecountry.nativeElement.value ;
        address  =  this.invoiceaddress.nativeElement.value ;
        postalcode  =  this.invoicepostalcode.nativeElement.value ;
        locality  =  this.invoicelocality.nativeElement.value ;
    }

    this.apiService.getShippings(countryclient, country, address, postalcode, locality).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.shippingInfo = obj.info;
        if(this.shippingInfo[0]){
          this.selectShippingmode = this.shippingInfo[0].idshippingmode;

          if(this.selectPayment!=null){
            let entry = false;
            this.shippingInfo[0].payments.forEach(childObj=> {
               if(childObj.idpaymentwebsite==this.selectPayment){
                 entry = true;
               }
            });
            if(!entry){

              const hTMLInputElement = (document.getElementById("radioP"+this.selectPayment)) as HTMLInputElement;
          		if(hTMLInputElement!=null){
          			hTMLInputElement.checked = false;
          		}
              this.selectPayment=null;
            }
          }

        }


        this.selectShippingcompany=null;
        this.pickupShipping=0;
        if(this.selectShippingmode==1 && this.shippingInfo[0].companies.length==1){
          this.selectShippingcompany = this.shippingInfo[0].companies[0].idshippingcompany;
          this.getShippingCosts();
        }
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }




  stockajust(event:any, item:any, type:any){

    let quantity;
    if(type == 1){
      quantity = event.target.value;
    }else{
      quantity = event;
    }
    if(quantity<1){
      quantity=1;
    }

    let arr:any=[];
    let obj: any = {};
    if(item && item.itemwithattr==1){
      obj.idcolor = item.idcolor;
      obj.idsize = item.idsize;
      obj.fullcode = item.code+"-"+obj.idcolor+"-"+obj.idsize;
      obj.idcolor_integration = item.colors[item.idcolor][item.idsize].idcolor_integration ;
      obj.idsize_integration = item.colors[item.idcolor][item.idsize].idsize_integration ;
    }else{
      obj.fullcode = item.code;
    }
    obj.iditem = item.iditem;
    obj.idmanager_company = item.idmanager_company;
    obj.iditem_integration = item.iditem_integration;
    obj.quantitycomplete = 1;
    obj.quantity = quantity;
    arr.push(obj);


    let desc:any= "";
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && item.langs[this.translate.currentLang]){
      desc = item.langs[this.translate.currentLang].description;
    }else{
      desc = item.description;
    }
    if(this.translate.currentLang && item.catlangs && item.catlangs[this.translate.currentLang] && item.catlangs[this.translate.currentLang].description) {
      cat = item.catlangs[this.translate.currentLang].description;
    }
    brand = (item.brandName) ? item.brandName : '';

		if(item && item.itemwithattr==1){
			color = item.colors[item.idcolor][item.idsize].name;
			if(item.colors[item.idcolor][item.idsize].priceFinal) { pval = item.colors[item.idcolor][item.idsize].priceFinal; }
		}else{
			pval = item.priceFinal;
		}


    this.apiService.addCart(arr).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.getSalesPoints();
        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.updateShippings();

        if(Number(item.quantity)<obj.quantity){
        this.angulartics2GAEcommerce.ecAddProduct({
              'id': item.iditem,
              'name': desc,
              'category': cat,
              'brand': brand,
              'variant': color,
              'price': pval,
              'quantity': obj.quantity-Number(item.quantity),
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("add", {});

            this.angulartics2.eventTrack.next({
      				action: 'addToCart',
      				properties: {
                label: 'addToCart',
      					currency: "EUR",
      					content_type: 'product_group',
                "event":"addToCart",
                gtmCustom:{
                  'ecommerce': {
										'purchase': undefined,
					          'click': undefined,
					          'checkout': undefined,
					          'detail': undefined,
					          'impressions': undefined,
					          'remove': undefined,
                    'add': {
                      'actionField': {'list': 'addToCart'},    // 'detail'
                      'products': [
                        {
                          'id': item.iditem,
                          'name': desc,
                          'price': pval,
                          'brand': brand,
                          'category': cat,
                          'variant': color,
                          'quantity': obj.quantity-Number(item.quantity)
                         }
                      ]
                     },
                     'add_to_cart': {
                      'currency': 'EUR', 'value':pval,    // 'detail'
                      'items': [
                        {
                          'item_id': item.iditem,
                          'item_name': desc,
                          'price': pval,
                          'item_brand': brand,
                          'item_category': cat,
                          'item_variant': color,
                          'quantity': obj.quantity-Number(item.quantity)
                         }
                      ]
                     }
                   }
                }
      				}
      			});
          }else if(Number(item.quantity)>obj.quantity){
            this.angulartics2GAEcommerce.ecAddProduct({
  	              'id': item.iditem,
  	              'name': desc,
  	              'category': cat,
  	              'brand': brand,
  	              'variant': color,
  	              'price': pval,
  	              'quantity': Number(item.quantity)-obj.quantity,
  	              'position': 0
  	            });
  	            this.angulartics2GAEcommerce.ecSetAction("remove", {});

  	            this.angulartics2.eventTrack.next({
  	      				action: 'removeFromCart',
  	      				properties: {
  	                label: 'removeFromCart',
  	      					currency: "EUR",
  	      					content_type: 'product_group',
  	                "event":"removeFromCart",
  	                gtmCustom:{
  	                  'ecommerce': {
  											'purchase': undefined,
  						          'click': undefined,
  						          'add': undefined,
  						          'checkout': undefined,
  						          'detail': undefined,
  						          'impressions': undefined,
  	                    'remove': {
  	                      'actionField': {'list': 'removeFromCart'},    // 'detail'
  	                      'products': [
  	                        {
  	                          'id': item.iditem,
  	                          'name': desc,
  	                          'price': pval,
  	                          'brand': brand,
  	                          'category': cat,
  	                          'variant': color,
  	                          'quantity': Number(item.quantity)-obj.quantity
  	                         }
  	                      ]
  	                     },
                         'remove_from_cart': {
  	                      'currency': 'EUR', 'value': pval,    // 'detail'
  	                      'items': [
  	                        {
  	                          'item_id': item.iditem,
  	                          'item_name': desc,
  	                          'price': pval,
  	                          'item_brand': brand,
  	                          'item_category': cat,
  	                          'item_variant': color,
  	                          'quantity': Number(item.quantity)-obj.quantity
  	                         }
  	                      ]
  	                     }
  	                   }
  	                }
  	      				}
  	      			});
          }


      }else{
        quantity = Number(item.quantity);
        if(obj.msg === "NOT_ENOUGH_STOCK"){
          this.updateInputStock(obj.stock, obj.iditem, obj.idcolor, obj.idsize);
          this.stockajust(obj.stock, item, 2)
        }
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });


  }

  updateInputStock(quantity:any, item:any, color?:any, size?:any){
    let quantityinput ;
    if(!color){
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value);
    }else{
      quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value);
    }
    if(isNaN(quantityinput)){
      quantityinput = 0;
    }
    if(quantityinput<0){
      quantityinput = 0;
    }
    if(quantityinput>parseInt(quantity)){
      quantityinput = parseInt(quantity);
    }
    if(!color){
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value = quantityinput;
    }else{
      (<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value = quantityinput;
    }
  }


  checkchangecountry(){
    if(this.clienttype!=1){
      this.changecountry();
    }
  }

  changecountry(){
    let countryclient  =  this.invoicecountry.nativeElement.value ;
    let country  = "";
    let address = "";
    let postalcode  = "";
    let locality = "";
    if(this.checkboxReplicate==1){
        country  =  this.shippingcountry.nativeElement.value ;
        address  =  this.shippingaddress.nativeElement.value ;
        postalcode  =  this.shippingpostalcode.nativeElement.value ;
        locality  =  this.shippinglocality.nativeElement.value ;
        if(country==""){
          country  =  this.invoicecountry.nativeElement.value ;
        }
    }else{
        country  =  this.invoicecountry.nativeElement.value ;
        address  =  this.invoiceaddress.nativeElement.value ;
        postalcode  =  this.invoicepostalcode.nativeElement.value ;
        locality  =  this.invoicelocality.nativeElement.value ;
    }

    this.apiService.getShippings(countryclient, country, address, postalcode, locality).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.shippingInfo = obj.info;
        if(this.shippingInfo[0]){
          this.selectShippingmode = this.shippingInfo[0].idshippingmode;

          if(this.selectPayment!=null){
            let entry = false;
            this.shippingInfo[0].payments.forEach(childObj=> {
               if(childObj.idpaymentwebsite==this.selectPayment){
                 entry = true;
               }
            });
            if(!entry){

              const hTMLInputElement = (document.getElementById("radioP"+this.selectPayment)) as HTMLInputElement;
          		if(hTMLInputElement!=null){
          			hTMLInputElement.checked = false;
          		}
              this.selectPayment=null;
            }
          }

        }


        this.selectShippingcompany=null;
        this.pickupShipping=0;
        if(this.selectShippingmode==1 && this.shippingInfo[0].companies.length==1){
          this.selectShippingcompany = this.shippingInfo[0].companies[0].idshippingcompany;
          this.getShippingCosts();
        }
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });


  }

  autoCheck(){
    if(this.selectPayment!=null){
      $("#radioP"+this.selectPayment).prop("checked", true);
    }
    return "";
  }

  autoCheckShipp(){
    if(this.selectShippingcompany!=null){
      $("#radioC"+this.selectShippingcompany).prop("checked", true);
    }
    return "";
  }

  getShippingCosts(){
    let country = "";
    if(this.checkboxReplicate==1){
      if(this.shippingcountry){
        country = this.shippingcountry.nativeElement.value ;
      }
    }else{
      country = this.invoicecountry.nativeElement.value ;
    }
    this.apiService.getCartbyattr(country, this.selectShippingcompany, this.selectShippingmode).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info && obj.info.length>0){
          if(obj.points){
            this.userexchangepoints = Number(obj.points);
          }
          else{
            this.userexchangepoints = 0;
          }
          obj.calc.values.summary.sum = parseFloat(obj.calc.values.summary.sum);
          obj.calc.values.summary.discountvoucher = parseFloat(obj.calc.values.summary.discountvoucher);

          this.cartTotal = obj.calc.values.summary;
          if(obj.shippingCost){
            this.shippingTotal = obj.shippingCost;
          }else{
            this.shippingTotal = 0;
          }
          if(typeof obj.shippingfee==="undefined"){
            this.shippingfeefail = 0;
          }else{
            this.shippingfeefail = obj.shippingfee;
          }
        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }



  getpostalcode(elem_country:any, elem_postalcode:any, elem_locality:any){
    if(this.checkoutForm.get(elem_country).value=="" || this.checkoutForm.get(elem_country).value==179 || this.checkoutForm.get(elem_country).value=="179-1" || this.checkoutForm.get(elem_country).value=="179-2"  || this.checkoutForm.get(elem_country).value=="179-3"){
      this.apiService.getpostalcode(this.checkoutForm.get(elem_postalcode).value).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.checkoutForm.controls[elem_locality].setValue(obj.designation) ;
        }else{
          this.translate.get( 'msg_valCP' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }


  addressShippingchange(){
    this.checkboxReplicate=!this.checkboxReplicate;
    this.selectShippingcompany=null;
    this.pickupShipping=0;
    this.changecountry();
  }

  changeShippingCompany(idshippingcompany:any, abbreviation:any){
    if(abbreviation=="CNP2"){this.pickupShipping=1;}else{this.pickupShipping=0;}
    this.selectShippingcompany=idshippingcompany;
    this.getShippingCosts();
    setTimeout( () => {  if(abbreviation=="CNP2" && this.codepostal_pickup){ this.codepostal_pickup.nativeElement.value = this.userInfo.postalcode; this.searchPickups(this.codepostal_pickup.nativeElement) } }, 200 );

    if(this.oldShipSelected && this.oldShipSelected =='CNP2'){
        this.checkoutForm.controls['shipping_address'].setValue('');
        this.checkoutForm.controls['shipping_address2'].setValue('');
        this.checkoutForm.controls['shipping_postalcode'].setValue('');
        this.checkoutForm.controls['shipping_locality'].setValue('');
    }
    this.oldShipSelected = abbreviation;
  }

  searchPickups(codepostal ){
    this.pickupShippingIndex = -1;
    let country = "";
    if(this.checkboxReplicate==1){
      country = this.shippingcountry.nativeElement.options[this.shippingcountry.nativeElement.selectedIndex].attr ;
    }else{
      country = this.invoicecountry.nativeElement.options[this.invoicecountry.nativeElement.selectedIndex].attr ;
    }
    country = country.split("-")[0];
    if(codepostal.value==""){
      let postalcode  = "";
      if(this.checkboxReplicate==1){
          postalcode  =  this.shippingpostalcode.nativeElement.value ;
      }else{
          postalcode  =  this.invoicepostalcode.nativeElement.value ;
      }
      let postalcode1 = postalcode.toString().split("-", 2);
      codepostal.value=postalcode1[0].toString();
      if(codepostal.value==""){
        this.translate.get( 'fill_in_postal' ).subscribe(translations => {  Swal.fire(translations);    });
        return;
      }
    }

    let country_id = "";
    if(this.checkboxReplicate==1){
      country_id = this.shippingcountry.nativeElement.options[this.shippingcountry.nativeElement.selectedIndex].value ;
    }else{
      country_id = this.invoicecountry.nativeElement.options[this.invoicecountry.nativeElement.selectedIndex].value ;
    }
    if(country_id=="179-3"){
      this.apiService.getpostalcode(codepostal.value).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && !obj.isvalid){
          this.translate.get( 'not_valid_cp' ).subscribe(translations => {  Swal.fire(translations);    }); 
          this.pickupShippingArr = [];
          // this.pickupShippingIndex = -1;
          this.checkoutForm.controls['shipping_address'].setValue('');
          this.checkoutForm.controls['shipping_address2'].setValue('');
          this.checkoutForm.controls['shipping_postalcode'].setValue('');
          this.checkoutForm.controls['shipping_locality'].setValue('');
          return;
        }
        else{
          this.apiService.getPickups(country, "CNP2", codepostal.value).subscribe((obj: any)=>{
            if(typeof obj!=="undefined" && obj.code==200){
              this.pickupShippingArr = obj.info;
              if(obj.info.length > 0){
                let min_lat = Number(this.pickupShippingArr[0].latitude);
                let min_lng = Number(this.pickupShippingArr[0].longitude);
                this.pickupShippingArr.forEach(element => { 
                  element.longitude = Number(element.longitude);
                  element.latitude = Number(element.latitude);
                  if(element.latitude < min_lat){ min_lat =  element.latitude; }
                  if(element.longitude < min_lng){ min_lng =  element.longitude; }
                });
        
                this.dpd_lat = min_lat;
                this.dpd_lng = min_lng;
              }
              else{
                // this.pickupShippingIndex = -1;
                this.checkoutForm.controls['shipping_address'].setValue('');
                this.checkoutForm.controls['shipping_address2'].setValue('');
                this.checkoutForm.controls['shipping_postalcode'].setValue('');
                this.checkoutForm.controls['shipping_locality'].setValue('');
              }
            }else{
              this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
            }
          });
        }
      })
    }
    else{
      this.apiService.getPickups(country, "CNP2", codepostal.value).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.pickupShippingArr = obj.info;
          if(obj.info.length > 0){
            let min_lat = Number(this.pickupShippingArr[0].latitude);
            let min_lng = Number(this.pickupShippingArr[0].longitude);
            this.pickupShippingArr.forEach(element => { 
              element.longitude = Number(element.longitude);
              element.latitude = Number(element.latitude);
              if(element.latitude < min_lat){ min_lat =  element.latitude; }
              if(element.longitude < min_lng){ min_lng =  element.longitude; }
            });
    
            this.dpd_lat = min_lat;
            this.dpd_lng = min_lng;
          }
          else{
            // this.pickupShippingIndex = -1;
            this.checkoutForm.controls['shipping_address'].setValue('');
            this.checkoutForm.controls['shipping_address2'].setValue('');
            this.checkoutForm.controls['shipping_postalcode'].setValue('');
            this.checkoutForm.controls['shipping_locality'].setValue('');
          }
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }


  addVoucher(voucherCode){
    if(voucherCode==""){
      return ;
    }
    if(this.checkoutForm.controls['email'].value==""){
      this.voucherError=1;
      const matchingControl = this.checkoutForm.controls['email'];
      this.voucherText="voucher_email";
      matchingControl.setErrors({ required: true }); return ;
    }else{
      this.voucherError=0;
    }

    let country = "";
    if(this.checkboxReplicate==1){
      if(this.shippingcountry && this.shippingcountry.nativeElement.value){
        country = this.shippingcountry.nativeElement.value;
      }
      else{
        country = "";
      }
      if(country == ""){ country = this.invoicecountry.nativeElement.value;}
    }else{
      country = this.invoicecountry.nativeElement.value;
    }

    this.apiService.addVoucher(voucherCode, this.checkoutForm.controls['email'].value).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){

        if(obj.info){
          if(!this.vouchersArr){
            this.vouchersArr=[];
          }
          //this.vouchersArr.push(obj.info);
          this.voucherCodeInput.nativeElement.value="";
          this.getCart(0);this.voucherText = "";
        }
      }else{
        // if(this.clienttype != 1){
          this.voucherText = obj.msg;
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
        // }
      }
    });
  }

  emailChange(){
    if(this.checkoutForm.controls['email'].value!=""){
      this.voucherText = "";
    }
  }

  removeVoucher(index:any, idvoucher:any){
    this.apiService.removeVoucher(idvoucher).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.vouchersArr.splice(index, 1);
        this.getCart(0);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  buy(){

    if(this.clienttype == 0 && !this.userLogging){
      this.translate.get( "finish_buy_login_first" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      return;
    }

    if(this.checkoutForm && this.checkoutForm.controls.email && this.checkoutForm.controls.email.value){
      this.checkoutForm.controls.email.setValue( this.checkoutForm.controls.email.value.toString().trim() );
      this.checkoutForm.controls.email.setValue( this.checkoutForm.controls.email.value.toString().toLowerCase() );
    }
    if(this.waitingsubmit){
      return;
    }

    if(this.showpoints && this.userexchangepoints == 0 && (this.pointsExchange && this.pointsExchange.nativeElement.value != '' && Number(this.pointsExchange.nativeElement.value) > 0)){

      let titleshow = 'exchange_buy_points_multiple';
      if(this.pointsExchange.nativeElement.value == 1){
        titleshow = 'exchange_buy_points';
      }
      Swal.fire({
        title: this.translate.instant(titleshow).replace(":1:", this.pointsExchange.nativeElement.value),
        showCancelButton: true,
        cancelButtonText:this.translate.instant('cancel'),
        confirmButtonText: this.translate.instant('yes_discount'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.exchangePoints(this.pointsExchange.nativeElement.value);
        }
      });
      return;
    }
    this.submitted = true;

    this.waitingsubmit=true;
    if (this.checkoutForm.invalid) {
      this.waitingsubmit=false;
      const firstElementWithError = document.querySelector('input.ng-invalid');
      firstElementWithError.scrollIntoView(true);
      window.scrollBy(0, -80); 
      return;
    }
    let re;
    re = /^[+]?[0-9]{9,13}$/;
    re = /^\s*([^\s]\s*){6,15}$/;
    if(!re.test(this.checkoutForm.controls['mobilephone'].value.toString())  && (this.checkoutForm.controls['shipping_idcountry'].value.toString()=="179" || this.checkoutForm.controls['shipping_idcountry'].value.toString()=="179-1" || this.checkoutForm.controls['shipping_idcountry'].value.toString()=="179-2" || this.checkoutForm.controls['shipping_idcountry'].value.toString()=="179-3") ){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.waitingsubmit = false;
        return;
    }else{
      re = /^[+]?[0-9]{7,16}$/;
      re = /^\s*([^\s]\s*){6,15}$/;
      if(!re.test(this.checkoutForm.controls['mobilephone'].value.toString())){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.waitingsubmit = false;
        return;
      }
    }
    if(this.pickupShipping==1 && this.pickupShippingIndex<0){
      this.waitingsubmit=false;
      this.translate.get( "choise_pickup_point" ).subscribe(translations => {
        Swal.fire({title:translations, customClass: { container: 'swal-index' } });
      });
      this.waitingsubmit=false;
      return;
    }
    if(this.selectShippingmode==1 && this.selectShippingcompany<=0){
      this.waitingsubmit=false;
      this.translate.get( "choise_shippingcompany" ).subscribe(translations => {
        Swal.fire({title:translations, customClass: { container: 'swal-index' } });
      });
      return;
    }
    if(!this.selectPayment){
      this.waitingsubmit=false;
      this.translate.get( "paymentchoose" ).subscribe(translations => {
        Swal.fire({title:translations, customClass: { container: 'swal-index' } });
      });
      return;
    }

    let option_2 = this.selectPayment;
		let el2 = document.getElementById("abbr_radioP"+this.selectPayment+"_"+this.selectShippingmode) as HTMLInputElement;
		if(el2) { option_2 = el2.value; }

    if(option_2=="MBW" && !document.getElementById("telephone_mbw_"+this.selectShippingmode)){
        Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } });
      this.waitingsubmit=false;
      return;
    }


    if(this.clienttype!=1){
      this.checkoutForm.controls['shipping_company'].setValue(this.checkoutForm.controls['shipping_responsable'].value);
    }
    var formData = new FormData();

    if(option_2=="MBW" && document.getElementById("telephone_mbw_"+this.selectShippingmode)){
      let valueMBW = (<HTMLInputElement>document.getElementById("telephone_mbw_"+this.selectShippingmode)).value;
        if(valueMBW.match(/^[+]{0,1}[0-9]{9,16}$/)==null){

            Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } });
          this.waitingsubmit=false;
          return;
        }
        formData.append("checkout[telephone_mbw]", valueMBW.toString());
    }
    this.hiddenLoad=false;
    Object.keys(this.checkoutForm.controls).forEach(key => {
      if(this.checkoutForm.controls[key].value){
        formData.append("checkout["+key+"]", this.checkoutForm.controls[key].value.toString());
      }else{
        formData.append("checkout["+key+"]", "");
      }
    });
    formData.append("passworddef", "0");
    formData.append("addressshipisdif", "1");

    formData.append("shippingmode", this.selectShippingmode);
    formData.append("shippingcompany", this.selectShippingcompany);
    formData.append("payment", this.selectPayment);
    if(this.vouchersArr){
      this.vouchersArr.forEach( (myObject, index) => {
         formData.append("voucher["+index+"]", myObject.idvoucher);
      });
    }
    if(this.pickupShipping==1){
      formData.append("pickup", this.pickupShipping);
      Object.keys(this.pickupShippingArr[this.pickupShippingIndex]).forEach(key => {
        formData.append("pickupInfo["+key+"]", this.pickupShippingArr[this.pickupShippingIndex][key]);
      });

    }

    if(this.userexchangepoints && this.userexchangepoints > 0){
      formData.append("points", this.userexchangepoints);
    }

    if(this.showpoints && this.salesPoints && this.salesPoints > 0){
      formData.append("addpointsitem", this.salesPoints.toString());
    }

    let option_ = this.selectPayment;
		let el = document.getElementById("text_radioP"+this.selectPayment+"_"+this.selectShippingmode) as HTMLInputElement;
		if(el) { option_ = el.value; }
    this.sendinitialcheckout("2", option_);
       option_ = "";
    this.apiService.buy(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){


        if(obj.abbr_payment!="CC" && obj.abbr_payment!="PYPL"){
					this.sendinitialpurchase(obj);
				}


        this.submitted = false;


        let storedNames = JSON.parse(this.localstorageService.get("orderspc"));
        if(!storedNames){
          storedNames = [];
        }
        storedNames.push(obj.id.toString());
        this.localstorageService.set('orderspc', JSON.stringify(storedNames));
        if(obj.newUrl && obj.newUrl==1){
          window.location.href = obj.newUrlDesc;
        }else{
          this.hiddenLoad=true;
          this.sharedService.sendClickEvent(this.translate.currentLang);
          if(obj.abbr_payment=="TB"){
            this.route.navigate([this.localize.translateRoute('/showorder'),  obj.id],  { queryParams: { alertMsg:'TB' } }  );
          }else{
            this.route.navigate([this.localize.translateRoute('/showorder'),  obj.id ] );
          }
        }
      }else if(typeof obj!=="undefined" && obj.code==202){

        if(obj.abbr_payment!="CC" || obj.abbr_payment!="PYPL"){
					this.sendinitialpurchase(obj);
				}

        this.hiddenLoad=true;
        this.sharedService.sendClickEvent(this.translate.currentLang);
        if(obj.abbr_payment=="TB"){
          this.route.navigate([this.localize.translateRoute('/showorder'),  obj.id],  { queryParams: { alertMsg:'TB' } } );
        }else{
          this.route.navigate([this.localize.translateRoute('/showorder'),  obj.id ] );
        }
      }else{

        let html="";
				if(obj.msg=="notcreatedorderdropshippingstock"){
					this.sharedService.sendClickEvent(this.translate.currentLang);
					if(obj.cartremove && obj.cartremove.delete && obj.cartremove.delete.length){
						html += "<br><b>"+this.translate.instant( 'REMOVED_PRODUCTS' )+"</b><br>";
						for (let index = 0; index < obj.cartremove.delete.length; index++) {
							html += obj.cartremove.delete[index] + "<br>";
						}
						html += "";
					}
					if(obj.cartremove && obj.cartremove.change && obj.cartremove.change.length){
						html += "<br><b>"+this.translate.instant( 'CHANGE_QUANTITY_PRODUCTS' )+"</b><br>";
						for (let index = 0; index < obj.cartremove.change.length; index++) {
							html += obj.cartremove.change[index] + "<br>";
						}
						html += "";
					}
				}


        this.waitingsubmit=false;
        this.submitted = false;
        if(obj.noitem){
          this.sharedService.sendClickEvent(this.translate.currentLang);
        }
        if(obj.msg!="notcreatedorderdropshippingstock"){
          this.translate.get( obj.msg ).subscribe(translations => { this.hiddenLoad=true; if(obj.msg=="notcreatedorder"){ translations = translations.replace(/:1:/, this.companyInfo.email );  }  if(obj.msg=="NOT_ADDED_CONDITIONS_PARAM"){translations = translations.replace(/:1:/, obj.minbuy.toString().replace(".",",")); translations=translations+"€";}  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }else{
          this.translate.get( obj.msg ).subscribe(translations => { this.hiddenLoad=true;  Swal.fire({title:translations, html:html, customClass: { container: 'swal-index' } });    });
        }
      }
    }, (error:any)=>{ this.waitingsubmit=false; this.hiddenLoad=true;
    this.submitted = false; });
  }

  changeEmail(){
    if(this.checkoutForm.controls['email'].value!="" && !this.userInfo){
      this.apiService.emailCheck(this.checkoutForm.controls['email'].value).subscribe((obj: any)=>{
        if(obj.code==200){
          this.emailCheck=true;
        }else{
          this.emailCheck=false;
        }
      });
    }
    if(this.vouchersArr && this.vouchersArr.length>0){
      this.apiService.removeVoucher("").subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.vouchersArr=[];
          this.getCart(0);
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }


  recoverPassch(){
    let pa = this.emailrecoverPass.nativeElement.value.toString();
    if(pa){
      pa = pa.toString().trim() ;
      pa = pa.toString().toLowerCase() ;
    }
    this.apiService.recoverPass(pa, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        });
        this.closeModalRecover();
        this.emailrecoverPass.nativeElement.value="";
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  formatN(a){
    return (parseFloat(a).toFixed(2)).replace(".", ",");
  }

  markerClicked(event){
    this.pickup_selected = event
    if(event.address){
      let address = event.address;
      if(address.length > 64){
        let numremove = address.length - 64;
        address = address.substring(0, 64 - numremove);
      }
      this.checkoutForm.controls['shipping_address'].setValue(address.trim());
      this.checkoutForm.controls['shipping_address2'].setValue('');
    }
    if(event.postalCode){ 
      this.checkoutForm.controls['shipping_postalcode'].setValue(event.postalCode);
    }
    if(event.postalCodeLocation){ 
      this.checkoutForm.controls['shipping_locality'].setValue(event.postalCodeLocation);
    }
  }

  getHour(hour:string){
    if(hour){
      return hour.substr(0,2)+":"+hour.substr(2);
    }
    else{
      return '--:--';
    }
  }

  changeEmailClear(event){
    if(this.signinForm && this.signinForm.controls.email && this.signinForm.controls.email.value){
      this.signinForm.controls.email.setValue ( this.signinForm.controls.email.value.toString().trim() );
    }
  }

  openModalDetailPickup(){
    this.pickupDetailsRef = this.modalService.open(this.pickupDetails, {
      size: 'md',
      windowClass: 'modal-md'  ,
      hideCloseButton: false,
      centered: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: 'modal-backdrop'
    });
    this.pickupDetailsRef.onOpen.subscribe(() => {
      $("modal .fade.show").addClass("in");
    });
  }
  
  closeModalDetailPickup(){
    this.modalService.close(this.pickupDetailsRef);
  }

  exchangePoints(points){
    if(points != ''){
      this.apiService.addPoints(points).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.msgErrorPoints = "";
          this.errorPointsLimit = 0;
          this.showMsgErrorPoints = false;
          this.getCart(0);
        }
        else if(typeof obj!=="undefined" && obj.code==202){
          this.msgErrorPoints = obj.msg;
          this.showMsgErrorPoints = true;
          this.errorPointsLimit = obj.max;
          this.pointsExchange.nativeElement.value = "";
        }
        else{
          this.msgErrorPoints = "";
          this.errorPointsLimit = 0;
          this.showMsgErrorPoints = false;
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      })
    }
  }

  getPoints(){
    this.apiService.getPoints("1,2,3").subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.ac_points = obj.info;
      }
    })
  }

  getUserPoints(){
    this.apiService.getUserPoints().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.qtdPoints = obj.total;
      }else{
        this.qtdPoints = 0;
      }
      if(obj.show){
        this.showpoints = true;
      }
      else{
        this.showpoints = false;
      }
    });
  }

  getSalesPoints(){
    this.apiService.getSalesPoints().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.salesPoints = obj.points;
      }
      else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    })
  }

  checkmaxchars($event){

    let source = $event.srcElement.name;
    let address = this.checkoutForm.controls['shipping_address'].value;
    let address2 = this.checkoutForm.controls['shipping_address2'].value;
    let final_address = address + ' ' + address2;

    if(final_address.length > 64){
      this.translate.get('max_leng_shipping').subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      if(source == 'shipping_address'){
        this.checkoutForm.controls['shipping_address'].setValue(this.shipping_address_oldvalue)
      }
      if(source == 'shipping_address2'){
        this.checkoutForm.controls['shipping_address2'].setValue(this.shipping_address2_oldvalue);
      }
      return;
    }
    else{
      if(source == 'shipping_address'){
        this.shipping_address_oldvalue = address;
      }
      if(source == 'shipping_address2'){
        this.shipping_address2_oldvalue = address2;
      }
    }

  }
}
