import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharelangchangeService {
  private subject = new Subject<any>();
  constructor() { }

  send(lang: any) {
    this.subject.next([lang]);
  }
  get( ): Observable<any>{
    return this.subject.asObservable();
  }

}
