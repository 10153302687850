<!-- breadcrumbs area start -->



<div class="c-content-box c-size-xs c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="row">
			<div class="col-md-12 c-margin-t-10">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [href]="['/'] | localize" onclick="return false;" [routerLink]="['/'] | localize"  class="ccblack">{{ 'home' | translate }}</a></li>
					<li class="separator">|</li>
					<li>{{ 'create_account' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>
		 <!-- breadcrumbs area End -->

		 <div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
		 	<div class="container-fluid c-container-50">
		 		<div class="row">

      <form  [formGroup]="registerForm" (ngSubmit)="onSubmit()">

		<div class="col-xs-12 col-md-12 leftDivider float-left " style="text-align: center; padding-left: 0;">

			<!-- Desktop -->

			<div *ngIf="!this.hideDesktop">
				<div class="row">
					<div class="col-lg-6 col-md-6">
						<span class="title_h3 c-font-16 helveticaltsd c-font-bold">{{ 'choiseoptioncustomeraccount' | translate }}</span><br>
						<div class="radio-toolbar">
							<div class="col-xs-12 col-sm-6" (click)="getPoints(2)">
								<input formControlName="type" (click)="getCountryByTypeClient(0);" type="radio" id="b2c_desk" name="type" value="0"> 
								<label class="control-label" style="margin-bottom: 20px;  min-width: 300px;    text-align: center;font-size: 15px !important;" for="b2c_desk">{{ 'private_customer' | translate }}
								</label>
							</div>
							<div class="col-xs-12 col-sm-6" (click)="getPoints(1)">
								<input type="radio" id="b2b_desk" formControlName="type" (click)="getCountryByTypeClient(1);" name="type" value="1">
								<label style="margin-bottom: 20px;  min-width: 300px;    text-align: center; font-size: 15px !important;" class="control-label" for="b2b_desk">{{ 'reseller' | translate }}</label>
								<br>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6">
						<span class="title_h3 c-font-16 helveticaltsd c-font-bold">{{ 'already_client' | translate }}</span>
						<div class="radio-toolbar">
							<div class="col-xs-12 col-sm-6">
								<label style="min-width: 300px; text-align: center; font-size: 15px !important;" class="control-label login-btn" [routerLink]="['/signin'] | localize">{{ 'reg_login' | translate }}</label>
							</div>
						</div>
					</div>
				</div>

			</div>

			<!-- Mobile -->
			<div *ngIf="this.hideDesktop">
				<span class="title_h3 c-font-16 helveticaltsd c-font-bold">{{ 'choiseoptioncustomeraccount' | translate }}</span><br>
				<div class="radio-toolbar">
					<div class="col-xs-12 col-sm-6" (click)="getPoints(2)">
						<input formControlName="type" (click)="getCountryByTypeClient(0);" type="radio" id="b2c" name="type" value="0"> 
						<label class="control-label" style="margin-bottom: 20px;  min-width: 300px;    text-align: center;font-size: 15px !important;" for="b2c">{{ 'private_customer' | translate }}
						</label>
					</div>
					<div class="col-xs-12 col-sm-6" (click)="getPoints(1)">
						<input type="radio" id="b2b" formControlName="type" (click)="getCountryByTypeClient(1);" name="type" value="1">
						<label style="margin-bottom: 20px;  min-width: 300px;    text-align: center; font-size: 15px !important;" class="control-label" for="b2b">{{ 'reseller' | translate }}</label>
						<br>
					</div>
					<hr style="border: 2px solid #696969; width: 100%;">
					<span class="title_h3 c-font-16 helveticaltsd c-font-bold">{{ 'already_client' | translate }}</span>
					<div class="col-xs-12 col-sm-6">
						<label style="min-width: 300px; text-align: center; font-size: 15px !important;" class="control-label login-btn" [routerLink]="['/signin'] | localize">{{ 'reg_login' | translate }}</label>
					</div>
				</div>
			</div> 

			<div *ngIf="submitted && f.type.errors" class="invalid-feedback">
				<div *ngIf="f.type.errors.required">{{ 'msg_valRequired' | translate }}</div>
			</div>

			 <div class="col-xs-12 col-md-12 leftDivider float-left " style="text-align: center; margin-bottom: 35px; margin-top: 35px;" id="user_points">
				<ng-container *ngIf="this.ac_points">
					<ng-container *ngIf="this.ac_points.length >0; else singlepoint">
						<ng-container *ngFor="let point of this.ac_points">
							<div class="col-lg-12 point-container" *ngIf="point.idpointtype == 1">
								<ng-container *ngIf="point.points>1">
									<span class="points-description">{{('register_points_multiple' | translate).replace(":1:", point.points)}}</span>
								</ng-container>
								<ng-container *ngIf="point.points==1">
									<span class="points-description">{{('register_points' | translate).replace(":1:", point.points)}}</span>
								</ng-container>
							</div>
							<div class="col-lg-12 point-container" *ngIf="point.idpointtype == 6">
								<ng-container *ngIf="point.points>1">
									<span class="points-description">{{('newsletter_points_multiple' | translate).replace(":1:", point.points)}}</span>
								</ng-container>
								<ng-container *ngIf="point.points==1">
									<span class="points-description">{{('newsletter_points' | translate).replace(":1:", point.points)}}</span>
								</ng-container>
							</div>
						</ng-container>
					</ng-container>
					<ng-template #singlepoint>
						<div class="col-lg-12 point-container" *ngIf="this.ac_points.idpointtype == 1">
							<ng-container *ngIf="point.points>1">
								<span class="points-description">{{('register_points_multiple' | translate).replace(":1:", this.ac_points.points)}}</span>
							</ng-container>
							<ng-container *ngIf="point.points==1">
								<span class="points-description">{{('register_points' | translate).replace(":1:", point.points)}}</span>
							</ng-container>
						</div>
						<div class="col-lg-12 point-container" *ngIf="this.ac_points.idpointtype == 6">
							<ng-container *ngIf="point.points>1">
								<span class="points-description">{{('newsletter_points_multiple' | translate).replace(":1:", this.ac_points.points)}}</span>
							</ng-container>
							<ng-container *ngIf="point.points==1">
								<span class="points-description">{{('newsletter_points' | translate).replace(":1:", this.ac_points.points)}}</span>
							</ng-container>
						</div>
					</ng-template>
				</ng-container>
			 </div>
			<br>
		</div>

				<div class="col-xs-12 col-md-6 leftDivider float-left borderInMobile" [style]="f.type.value=='' ? 'display:none' : ''">








					<span class="title_h3 c-font-16 helveticaltsd c-font-bold" id="formStart">{{ 'databilling' | translate }}</span>

					<div class="row b2breg" style="display:block">
						<div class="form-group col-md-12">
							<label *ngIf="f.type.value==1" class="control-label" for="nomecomp">{{ 'companynamereg' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
							<label *ngIf="f.type.value==0" class="control-label" for="nomecomp">{{ 'name' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
							<input type="text" formControlName="compname"  [ngClass]="{ 'is-invalid': submitted && f.compname.errors }"  maxlength="32" id="nomecomp" class="form-control c-square valRequired" />
							<div *ngIf="submitted && f.compname.errors" class="invalid-feedback">
								 <div *ngIf="f.compname.errors.required">{{ 'msg_valRequired' | translate }}</div>
						 </div>
						</div>
					</div>


					<div class="b2breg" style="display:block">

						<div class="form-group">
						 <label class="control-label" for="address">{{ 'address' | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
						 <input type="text" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address" id="address" maxlength="32" class="form-control valRequired c-square" />
						 <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
								<div *ngIf="f.address.errors.required">{{ 'msg_valRequired' | translate }}</div>
						</div>
					 </div>

					 <div class="form-group">
						 <label class="control-label" for="address2">{{ 'addresscomplement' | translate }} </label>
						 <input type="text" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" formControlName="address2"   maxlength="32" id="address2" class="form-control c-square"/>
						 <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
								<div *ngIf="f.address2.errors.required">{{ 'msg_valRequired' | translate }}</div>
						</div>
					 </div>



					 <div class="row">
						 <div class="form-group col-md-5 pad0 mb-0">
							 <label class="control-label" for="postalcode">{{ "postalcod" | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
							 <input type="text" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" formControlName="postalcode"   id="postalcode" (change)="getpostalcode()" class="form-control valRequired c-square" />
							 <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
									<div *ngIf="f.postalcode.errors.required">{{ 'msg_valRequired' | translate }}</div>
							</div>
							 </div>
							<div class="form-group col-md-7">
								<label class="control-label" for="locality">{{ "locality" | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
								<input type="text"  [ngClass]="{ 'is-invalid': submitted && f.locality.errors }" formControlName="locality"   id="locality" class="form-control valRequired c-square" />
                <div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
                   <div *ngIf="f.locality.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
							</div>
						</div>


						<div class="form-group">
							<label class="control-label full-width float-left">{{ 'country' | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
							<select *ngIf=" this.countries "   [ngClass]="{ 'is-invalid': submitted && f.idcountry.errors }" formControlName="idcountry"  (change)="getpostalcode()" id="idcountry" class="form-control c-square c-theme countryControl full-width  " >
								<option value="" >{{ 'selectcountry' | translate }}</option>

									<ng-container *ngFor="let country of this.countries ;  ">
										<option value="{{ country.fullid }}"  >{{ country.description[this.translate.currentLang] }}</option>
									</ng-container >
							</select>
							<div *ngIf="submitted && f.idcountry.errors" class="invalid-feedback">
								 <div *ngIf="f.idcountry.errors.required">{{ 'msg_valRequired' | translate }}</div>
						 </div>
						</div>



						<div class="form-group ">
							<label class="control-label" for="nif"><span class="niftext">{{ 'nif' | translate }}</span><span class="niffrtext" style="display:none;">{{ 'nif_fr' | translate }}</span><i *ngIf="f.type.value==1" class="c-theme-font fa fa-asterisk"></i></label>
							<input type="text"   [ngClass]="{ 'is-invalid': submitted && f.nif.errors }" formControlName="nif" id="nif" class="form-control c-square nifControl " />
              <div *ngIf="submitted && f.nif.errors" class="invalid-feedback">
								<div *ngIf="f.nif.errors.required">{{ 'msg_valRequired' | translate }}</div>
								 <div *ngIf="f.nif.errors.NifValidatorPT">{{ 'msg_valNif' | translate }}</div>
								 <div *ngIf="f.nif.errors.NifRequired">{{ 'msg_valRequired' | translate }}</div>
             </div>
						</div>


					</div>

					<div class="b2breg" style="display:block">
					<div class="form-group ">
						<label class="control-label" for="telephone">{{ 'telephone' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>



					<input type="text" maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.mobilephone.errors }" formControlName="mobilephone" id="telephone" class="form-control c-square valRequired valNumber" />


					<div *ngIf="submitted && f.mobilephone.errors" class="invalid-feedback">
						 <div *ngIf="f.mobilephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
				 </div>
					</div>
				</div>







				</div>
				<div class="col-md-6 col-xs-12 rightDivider float-left" [style]="f.type.value=='' ? 'display:none' : ''">
					<span class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20 ">{{ 'loginright' | translate }}</span>
					<!--<div class="form-group">
						<label class="control-label" for="nome"><?php echo $lang["personchargresp"]; ?><i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
						<input type="text" name="userweb[obs]" maxlength="32" id="nome" class="form-control c-square valRequired" />
					</div>-->

					<div class="form-group" [style]="f.type.value==0 ? 'display:none' : ''">
						<label class="control-label" for="email">{{ 'personchargresp' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
						<input type="email" [ngClass]="{ 'is-invalid': submitted && f.obs.errors }" formControlName="obs" id="obs" class="form-control c-square valRequired valEmail" />
            <div *ngIf="this.submitted && f.obs.errors" class="invalid-feedback">
               <div *ngIf="f.obs.errors.required">{{ 'msg_valRequired' | translate }}</div>
               <div *ngIf="f.obs.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
							 <div *ngIf="f.obs.errors.ObsRequired">{{ 'msg_valRequired' | translate }}</div>
           </div>
					</div>

					<div class="form-group">
						<label class="control-label" for="email">{{ 'email' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
						<input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" id="email" class="form-control c-square valRequired valEmail" />
            <div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
               <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
               <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
           </div>
					</div>


					<div class="form-group row">
						<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 float-left noPadMob mb1rm">
							<label class="control-label" for="password">{{ 'password' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
							<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
              <div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
                 <div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
             </div>
						</div>
						<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pr-0 pl-20 float-left noPadMob">
							<label class="control-label" for="passwordconf">{{ 'confirmpassword' | translate }} <i class="c-theme-font fa fa-asterisk"></i></label>
							<input type="password" id="passwordconf" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" formControlName="confirmPassword" class="form-control c-square valRequired valMatch" />
              <div *ngIf="this.submitted && f.confirmPassword.errors" class="invalid-feedback">
                 <div *ngIf="f.confirmPassword.errors.required">{{ 'msg_valRequired' | translate }}</div>
                 <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'msg_valMatchP' | translate }}</div>
             </div>
						</div>
					</div>
					<div class="row">
					<div class="form-group">
						<div class="form-group col-sm-6 col-xs-12 pad0">
							<div class="c-checkbox">
								<input id="checkbox1" formControlName="newsletter" name="newsletter" class="c-check" type="checkbox">
								<label for="checkbox1" class="c-font-14 helveticaltsd">
									<span class="inc"></span>
									<span class="check"></span>
									<span class="box"></span> {{ 'newsletter_subscribe' | translate }}  </label>
							</div>
						</div>
					</div>
					</div>

					<div class="form-group row help-text mt-10">
						<span class="help-block col-sm-6 col-xs-12 c-font-14 helveticaltsd ccblack pad0"><i class="c-theme-font fa fa-asterisk"></i>{{ 'requiredfields' | translate }}.</span>
						<div class="col-md-6 pull-right text-right pad0">
							<button class="btn c-btn-black btn c-font-uppercase c-btn-bold c-btn-slim c-btn-border-2x c-btn-square c-btn-signup c-font-14 helveticaltsd" type="submit">{{ 'register' | translate }}</button>
						</div>
					</div>

				</div>
			</form>

		</div>
	</div>
</div>

<modal #ModalRegisterPoints  id="registerSuccess" class="modalRemoveFooter">
	<modal-header></modal-header>
	<modal-content>
		<div class="row mt-3">
			<div class="col-sm-12">
				<img src="assets/images/success-icon.png" class="success-img" alt="Success">
				<div class="content-info-register">
					<ng-container *ngIf="this.typeuser== 0; else othermessage">
						<h2 class="text-center">{{ 'register_success_points' | translate }}</h2>
					</ng-container>
					<ng-template #othermessage>
						<h2 class="text-center">{{ 'usersavesuccessaccounthold' | translate }}</h2>
					</ng-template>
					<div class="msg-info">
						<p *ngIf="this.pointsRegister && this.pointsRegister.points && this.pointsRegister.points>1"><b>{{('register_added_points_multiple' | translate).replace(":1:", this.pointsRegister.points)}}</b></p>
						<p *ngIf="this.pointsRegister && this.pointsRegister.points && this.pointsRegister.points==1"><b>{{('register_added_points' | translate).replace(":1:", this.pointsRegister.points)}}</b></p>
						<p *ngIf="this.invalidEmailWarning==1"><b>{{('usersavedwithinvalidemail' | translate).replace(":1:", this.invalidEmailAddress)}}</b></p>
					</div>
					<button *ngIf="this.showbtncheckout && this.typeuser== 0" class="btn c-btn-black btn c-font-uppercase c-btn-bold c-btn-square c-font-14 helveticaltsd btn-keep-buy"  [routerLink]="['/checkout'] | localize" (click)="closeModalRecover()">{{ 'shopnow' | translate }}</button>
				</div>
			</div>
		</div>
	</modal-content>
</modal>