import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private subject = new Subject<any>();
  constructor() { }

  sendsession(login: any, name: any) {
    this.subject.next([login, name]);
  }
  getsessionEvent( ): Observable<any>{
    return this.subject.asObservable();
  }
}
