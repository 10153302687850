<div class="c-content-box c-size-xs c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="col-md-12  c-margin-t-10 noPadding">
			<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
				<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate  }}</a></li>
				<li>|</li>
                <ng-container *ngIf="this.idorder "  >
                    <li><a href="javascript:void(0)" [routerLink]="(['/account'] | localize)"> {{ 'myaccount' | translate }}</a></li>
                    <li>|</li>
                    <li><a href="javascript:void(0)" *ngIf="this.document"> {{ 'reviews' | translate }} {{ 'of' | translate }} {{ this.document.numberdoc }}</a></li>
                </ng-container>
		    </ul>
		</div>
	</div>
</div> 

<div class="col-xs-12" style="margin-top: 20px;">
    <div class="row">
        <div class="col-xs-12">
            <span class="mt-0 title_h3 c-font-16 helveticaltsd c-font-bold ccblack background-color-theme full-width float-left m0 col-md-12 c-margin-b-40 fullWidth" style="font-size: 16px;">
                {{ 'write_your_review' | translate }} <ng-conntainer *ngIf="this.document && this.document.numberdoc">{{ this.document.numberdoc }}</ng-conntainer>
            </span>

            <div class="itemreview col-lg-12 col-xs-12" *ngFor="let itm of this.itemsReview; let in = index">
                <div class="row">
                    <div class="line item-img col-md-1 col-xs-2">
                        <div class="title">{{ 'article' | translate }}:</div>
                        <div class="info"><img style="width:75px; height:auto;margin:0 auto;" [src]="this.apiService.imagesDir+itm.images[0].photo"></div>
                    </div>
                    <div class="line item-description col-md-1 col-xs-4">
                        <div class="title"></div>
                        <div class="info mob-info">
                            {{ itm.description }} <br>
                            <b>{{ 'color' | translate }}:</b> {{ getcolorname(itm.color) }}<br>
                            <b>{{ 'size' | translate }}:</b> {{itm.size.number}}<br>
                        </div>
                    </div>
                    <div class="line item-classification col-md-2 col-xs-6">
                        <div class="title">{{ 'rating' | translate }}:</div>
                        <div class="info">
                            <ngb-rating [readonly]="itm.created" [max]="5" [(rate)]="itm.rating" style="font-size: 30px;"></ngb-rating><br>
                            <div *ngIf="itm.submittedReview && itm.rating == ''" class="invalid-feedback">
                            <div> <span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="line item-review col-md-6 col-xs-12">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="title">{{ 'comment_title' | translate }}:</div>
                                <div class="info">
                                    <input type="text" [disabled]="itm.created"  [(ngModel)]="itm.title" placeholder="{{ 'title' | translate }}" [ngClass]="{ 'is-invalid': itm.submittedReview && itm.title == '' }"  maxlength="250" class="form-control c-square valRequired"/>
                                    <div *ngIf="itm.submittedReview && itm.title == ''" class="invalid-feedback">
                                        <div><span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="title">{{ 'comment_description' | translate }}:</div>
                                <div class="info">
                                    <textarea rows="1" [disabled]="itm.created"  maxlength="500" style="width: 100%;" [(ngModel)]="itm.comment" class="form-control textarea-comment" placeholder="{{ 'leave_a_comment' | translate }}"></textarea>
                                    <div *ngIf="itm.submittedReview && itm.comment == ''" class="invalid-feedback">
                                        <div><span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line item-review col-md-2 col-xs-12">
                        <div class="title" *ngIf="!itm.created">{{ 'add_images' | translate }}:</div>
                        <div class="info" *ngIf="!itm.created">
                            <label for="images" class="drop-container">
                                <span class="drop-title">{{ 'drop_files_here' | translate }}</span>
                                <input type="file" id="images" accept="image/png,image/jpeg" (change)="uploadFile(in, $event)" multiple>
                            </label>
                        </div>
                    </div>
                    <div class="line col-md-12 col-xs-12" *ngIf="itm.images_64 && itm.images_64.length > 0">
                        <div class="images-container">
                            <div class="image-upload-view" *ngFor="let img of itm.images_64; let i = index">
                                <div class="delete-image" *ngIf="!itm.created" (click)="deleteFile(in,i)"><span>&#10006;</span></div>
                                <img [src]="'data:image/png;base64,'+ img" class="imge-view">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <div class="submit-comment-container" *ngIf="!itm.created" style="float: right;">
                                <button class="btn c-theme-bg c-btn-square c-font-white c-font-14 c-font-lower mt-2 submit-comment" (click)="submitReview(in)">{{ 'comment_validate' | translate }}</button>
                            </div>
                            <div class="submit-comment-container" *ngIf="itm.created" style="float: right;">
                                <button class="btn btn-sm c-btn-sbold btn-disabled"><i class="icon-check"></i> {{ 'review_sent' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <ul class="accordion-box clearfix list-unstyled" style="margin-top: 50px;">
                <ng-container *ngFor="let itm of this.itemsReview; let in = index">
                    <li class="accordion block" *ngIf="itm.rate == 0">
                        <div class="acc-btn" (click)="openbox($event)">
                            <ng-container *ngIf="itm && itm.images && itm.images[0]">
                                <div class="product-info">
                                    <img style="width:50px; height:auto;margin:0 auto;" [src]="this.apiService.imagesDir+itm.images[0].photo">
                                    {{ itm.description }}
                                </div>
                            </ng-container>
                            <div class="open-box" style="color: green;" *ngIf="itm.created"><i class="icon-check"></i></div>
                        </div>
                        <div class="acc-content">
                            <div class="content" *ngIf="!itm.created">
                                <p>{{'add_images' | translate}}:</p> 
                                <label for="images" class="drop-container">
                                    <span class="drop-title">{{ 'drop_files_here' | translate }}</span>
                                    <input type="file" id="images" accept="image/png,image/jpeg" (change)="uploadFile(in, $event)" multiple>
                                </label>
    
                                <ng-container *ngIf="itm.images_64 && itm.images_64.length > 0">
                                    <div class="images-container">
                                      <div class="image-upload-view" *ngFor="let img of itm.images_64; let i = index">
                                        <div class="delete-image" (click)="deleteFile(in,i)"><span>&#10006;</span></div>
                                        <img [src]="'data:image/png;base64,'+ img" class="imge-view">
                                      </div>
                                    </div>
                                  </ng-container>
                                <div class="row">
                                    <div class="col-sm-6">
                                    <div class="col-sm-12">
                                        <ngb-rating  [max]="5" [(ngModel)]="itm.rating" style="font-size: 30px;"></ngb-rating><br>
                                        <div *ngIf="itm.submittedReview && itm.rating == ''" class="invalid-feedback">
                                        <div> <span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12" style="margin-bottom: 12px;">
                                    <input type="text"  [(ngModel)]="itm.title" placeholder="{{ 'title' | translate }}" [ngClass]="{ 'is-invalid': itm.submittedReview && itm.title == '' }"  maxlength="250" class="form-control c-square valRequired"/>
                                    <div *ngIf="itm.submittedReview && itm.title == ''" class="invalid-feedback">
                                        <div><span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
                                    </div>
                                    </div>
                                    <div class="col-sm-12">
                                    <textarea rows="5"  maxlength="500" style="width: 100%;resize: none;" [(ngModel)]="itm.comment" class="form-control" placeholder="{{ 'leave_a_comment' | translate }}"></textarea>
                                    <div *ngIf="itm.submittedReview && itm.comment == ''" class="invalid-feedback">
                                        <div><span style="font-size: 12px;">{{ 'msg_valRequired' | translate }}</span></div>
                                    </div>
                                    </div>
                                    <div class="col-sm-6"></div>
                                    <div class="col-sm-6">
                                    <div class="submit-comment-container" *ngIf="!itm.created">
                                        <button class="btn c-theme-bg c-btn-square c-font-white c-font-14 c-font-lower mt-2 submit-comment" (click)="submitReview(in)">{{ 'comment' | translate }}</button>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul> -->

            <ng-container *ngIf="itemsReview && itemsReview.length == 0">
                <p>{{ 'no_items_review' |  translate }}</p>
            </ng-container>
        </div>
    </div>
</div>