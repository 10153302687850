import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { SessionService } from '../session.service';
import { Router, ActivatedRoute   } from '@angular/router';
import { SharedService } from '../shared.service';
import { LogoutService } from '../logout.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  userInfo:any;
  subscribe:any;
  showPoints: boolean = false;
  constructor(public titlechangeService:TitlechangeService,private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title,public logoutservice: LogoutService,public sharedService: SharedService, private route: Router, public sessionService: SessionService, public apiService: ApiService, public translate: TranslateService) { }

  ngOnInit(): void {

    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/account' );
    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/account', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/account', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/account', 'en');
     this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/account', 'fr');


    this.apiService.getUserInfo(this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.userInfo=obj.info;
      }else if(obj.code==302){ this.sessionService.sendsession(false, ""); this.sharedService.sendClickEvent(this.translate.currentLang); this.route.navigate([this.localize.translateRoute('/')] ); }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });

    this.apiService.getUserPoints().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.show){
          this.showPoints = true;
        }
      }
    })

    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/account');
        this.metatags(lang.lang);
      });
      this.metatags(this.translate.currentLang);

  }


  getRappel(){
    if(this.userInfo.rappel){
      return (this.translate.instant("rappelavailable")).replace(":1:", this.userInfo.rappel);
    }
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }

    public setTitle( newTitle: string) {
  	   this.titleService.setTitle( newTitle  );
       this.titlechangeService.send();
    }

  metatags(lang){
    this.apiService.getMetatags(8,9,lang, 'MENU_INFO').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

}
