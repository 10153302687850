
<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li><a [routerLink]="(['/blog'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'blog' | translate }}</a></li>
          <li>|</li>
					<li *ngIf="this.langs">{{ this.langs[this.translate.currentLang].title }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<!-- BEGIN: BLOG LISTING -->

<div class="container-fluid c-container-50">
	<div class="row">
    <div class="col-lg-10 col-md-8 col-sm-7 col-xs-12" >

					<div class="c-content-blog-post-1" *ngIf="this.blog">
																<div class="c-media c-content-overlay">

											<img alt="{{ this.blog.image | translate }} - {{ 'Name_APP' | translate }}" class="c-overlay-object img-responsive" [src]="this.apiService.imagesDir+this.blog.image">
										</div>

						<h1 class="  c-font-bold c-font-uppercase c-font-18">
							   {{ blog.title }}						</h1>
						<div class="c-panel c-margin-b-30">

													<div class="c-author">
																{{ 'by' | translate }}  <span class="c-font-uppercase"> {{ blog.font }}</span>
															</div>
														<div class="c-date">{{ 'on' | translate }}  <span class="c-font-uppercase"> {{ blog.updated_at  }} </span></div>


						</div>


						<div class="c-desc c-font-17-blog" [innerHTML]="blog.description">

						</div>
<div class="addthis_inline_share_toolbox_64fh c-social-shares noPadding mb-20"></div>
<div class="c-social-shares-second  mb-20">
<a href="https://www.facebook.com/sharer/sharer.php?u=http://modaserverpro.com/{{ this.translate.currentLang }}/blog/{{ this.langs[this.translate.currentLang].url }}" target="_blank"><span class="button-share-social"><i class="fa fa-facebook"></i></span></a>
<a style="    margin-left: 5px;" href="https://twitter.com/intent/tweet?url=http://modaserverpro.com/{{ this.translate.currentLang }}/blog/{{ this.langs[this.translate.currentLang].url }}" target="_blank"><span class="button-share-social"><i class="fa fa-twitter"></i></span></a>
</div>
					</div>

			</div>
			<div class="col-lg-2 col-md-4 col-sm-5 col-xs-12">

				<div class="input-group">
					<input type="text" #inputSearchBlog name="search" class="form-control c-square c-theme-border" placeholder="">
					<span class="input-group-btn">
						<button class="btn c-theme-btn c-theme-border c-btn-square" (click)="searchblog(inputSearchBlog)" type="button">{{ 'search' | translate }}</button>
					</span>
				</div>

				<div class="c-content-ver-nav">
					<div class="c-content-title-1 c-theme c-title-md c-margin-t-40">
						<span class="c-font-bold c-font-uppercase title_h5">{{ 'recent_posts' | translate }}</span>
						<div class="c-line-left c-theme-bg"></div>
					</div>
					<ul class="c-content-recent-posts-1">

						<ng-container *ngIf="this.postsrecents">
						<li *ngFor=" let ct of  this.postsrecents; let i=index  ">
							<div class="c-image c-image-blog-thumb">
								<a onclick="return false;" [routerLink]="['/blog/'+ ct.url] | localize" [href]="['/blog/'+ ct.url] | localize">
									<img alt="{{ ct.image | translate }} - {{ 'Name_APP' | translate }}" [src]="this.apiService.imagesDir+ct.image" class="img-responsive">
								</a>
							</div>
							<div class="c-post">
								<a [innerHTML]="ct.descriptionTitle" onclick="return false;" [routerLink]="['/blog/'+ ct.url] | localize" [href]="['/blog/'+ ct.url] | localize" class="c-title c-font-13">
								   					</a>
									<div class="c-date c-font-12">{{ ct.updated_at  }}</div>
								</div>
							</li>
						 </ng-container>


										</ul>
										<div style="display: flex; justify-content: center">
											<div class="btn c-theme-bg c-font-white c-btn-square cartBtn float-left" style="margin-top: 15px;" [routerLink]="(['/blog'] | localize)">{{ 'see_all_posts' | translate }}</div>
										</div>
									</div>

								</div>
							</div>
						</div>
