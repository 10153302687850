<div class="   ">
	<div style="margin-top: 80px;" class="fourOhFour c-margin-t-50">
		<div class="text-center">
		
		</div>
		<div class="clearfix"></div>
		<br>



		<h3 class="pt text-center">{{ 'information_looking_not_available' | translate }}</h3>


		<h4 style="margin-top: 80px;" *ngIf="this.relateditemarr && this.relateditemarr.length>0" class="pt text-center"><b>{{ 'suggestprod' | translate }}</b></h4>

		<br>

		<div class="col-xs-12" *ngIf="this.relateditemarr && this.relateditemarr.length>0">
			<div class="clearfix"></div>
			<div class="col-xs-12   noPadding">



				<ng-container *ngFor="let product of this.relateditemarr; let i_product=index ">
					<div class="col-md-2 col-sm-4 col-xs-12 productWrapper c-margin-b-20 c-bg-white c-related-prod">
						<a rel="noopener" onclick="return false;" [href]='this.localize.translateRoute("/product")+"/"+this.lowercase.transform(this.urlpipePipe.transform(product.langs[this.translate.currentLang].description)+"-"+product.iditem) ' (click)="urlmount(product, product.langs[this.translate.currentLang].description, product.iditem )">
							<div class="imgProd c-border c-border-grey-4">
								<img [alt]="product.langs[this.translate.currentLang].description+' - '+ ('Name_APP' | translate)" [src]="getoneimg(product)">
							</div>
							<div class="prodCont c-border noBorder-top c-border-grey-4">
								<span style="min-height: 47px;" class="title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center">{{ product.langs[this.translate.currentLang].description }}</span>
								<div class="product-price col-sm-12 col-xs-12 text-center pull-right noPadding-sides noMargin">
									<span *ngIf="this.userLogging"><ins class="c-font-16 c-theme-font"> {{ getPriceRelated(product) }} €</ins></span>&nbsp;
								</div>
								</div>
							</a>
						</div>
					</ng-container>




				</div>
			</div>


		<br>
	</div>
</div>
