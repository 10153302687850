<div class="c-content-box c-size-sm c-bg-white noPadding c-margin-t-10">
	<div class="container-fluid c-container-50">
		<div class="">
			<div class="col-xs-12 noPadding">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a></li>
					<li>|</li>
					<li><a [routerLink]="(['/account'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'myaccount' | translate }}</a></li>
					<li>|</li>
					<li>{{ 'wishlist' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<div class="c-content-box c-size-sm c-overflow-hide c-bg-white">
    <div class="container-fluid c-container-50">
        <div class="c-layout-sidebar-menu c-theme ">
			<div class="c-sidebar-menu-toggler">
                <span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
                <a href="javascript:;" class="c-content-toggler" data-toggle="collapse" data-target="#sidebar-menu-1">
                    <span class="c-line"></span>
                    <span class="c-line"></span>
                    <span class="c-line"></span>
                </a>
			</div>
            <ul class="c-sidebar-menu collapse in" id="sidebar-menu-1">
                <li class="c-dropdown c-open">
                    <a href="javascript:;" class="c-toggler">{{ 'myaccount' | translate }}
                        <span class="c-arrow"></span>
                    </a>
                    <ul class="c-dropdown-menu">
                        <li class="">
                            <a [routerLink]="(['/points'] | localize)" href="javascript:void(0);" *ngIf="this.showPoints">{{ 'my_points' | translate }}</a>
                        </li>
                        <li class=" ">
                            <a [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
                        </li>
                        <li class=" ">
                            <a [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
                        </li>
                        <li class="">
                            <a [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
                        </li>
                        <li class="c-active">
                            <a [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
                        </li>
                        <li>
                            <a (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
		</div>
        <div class="c-layout-sidebar-content">
            <div class="row" id="prodHolder">
                <ng-container *ngIf="this.wishlistItems && this.wishlistItems.length > 0">
                    <ng-container *ngFor="let item of this.wishlistItems;">
                        <div class="col-md-4 col-lg-3   col-xs-12 col-sm-6 productWrapper c-margin-b-10 c-bg-white " id="prodBox{{ item.iditem }}">
                            <div>
                              <a href="javascript:;" [style]="item.outofstock? 'cursor:auto' : 'cursor:pointer'" (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem)">
                                <div class="imgProd" [class.soldout]="item.outofstock">
                                  <img  [lazyLoad]="loadImg(item)" [defaultImage]="this.browserwebp ? defaultImage : loadImg(item) " alt="{{ item.langs[this.translate.currentLang].description  }} - {{ 'Name_APP' | translate }}"   style="display: inline;">
                                  <div *ngIf="  item.novelty==1 && ((item.datelimitnovelty && item.datelimitnovelty>=item.datenow) || !item.datelimitnovelty ) && !item.withPromotion" class="prodNovelty">{{ 'new' | translate }}</div> 						</div>
                                  <div *ngIf="  item.withPromotion && item.withPromotion==1" class="prodPromo"> -{{ showDiscount( item ) }}%</div>
                                  <div class="madeinportugal" *ngIf="item.countryOriginy && item.countryOriginy === 'Portugal'"><img src="assets/images/pt.png" alt="Portugal" width="30" height="30"></div>
                                  <div class="madeinportugal" *ngIf="item.countryOriginy && item.countryOriginy === 'Italy'"><img src="assets/images/it.png" alt="Italy" width="30" height="30"></div>
                                </a>
                                <div *ngIf="item.outofstock" style="position: relative;">
                                    <div class="nostock">{{ 'out_of_stock' | translate }}</div>
                                </div>
                                <div class="product-desc col-sm-12 col-xs-12 text-center pull-right noPadding-sides noMargin" style="margin-bottom: 10px;">
                                    <span class="c-font-12">{{ 'ref' | translate }}: {{item.code}}</span><br>
                                    <span class="c-font-12">{{ 'color' | translate }}: {{getColorName(item, item.wishcolor)}}</span>
                                  </div>
                                <div class="prodCont">
                                  <span style="    min-height: 44px;" class="title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center hidden-xs hidden-sm"><b>{{ parseText(item.langs[this.translate.currentLang].description, 38)  }}</b></span>
                                  <span style="    min-height: 44px;" class="title_h4 pull-left noPadding col-sm-12 col-xs-12 text-center hidden-md hidden-lg"><b>{{ parseText(item.langs[this.translate.currentLang].description, 70)  }}</b></span>
                                  <div class="product-price col-sm-12 col-xs-12 text-center pull-right noPadding-sides noMargin">
                                    <div class="">
                                        <del *ngIf="  item.withPromotion && item.withPromotion==1  ">{{ showPrice(item, 1) }}€</del>
                                        <ins>{{ showPrice(item, 2) }}€</ins>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-12">
                                        <div class="delete-item-wish">
                                            <a href="javascript:;" (click)="removeLine(item.iditem, item.wishcolor)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.wishlistItems && this.wishlistItems.length == 0">
                    <div class="no-items-found">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                        </svg>
                        <span>{{ 'noproductsonwishlist' | translate }}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>