


<div class="c-content-box c-size-xs c-bg-white">
	<div class="container-fluid c-container-50">
		<div class="row">
			<div class="col-md-12 c-margin-t-10">
				<ul class="c-custom-breadcrumbs c-fonts-regular noPadding noMargin">
					<li><a [href]="['/'] | localize" onclick="return false;" [routerLink]="['/'] | localize"  class="ccblack">{{ 'home' | translate }}</a></li>
					<li class="separator">|</li>
					<li>{{ 'recover_password' | translate }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>







<div class="c-content-box c-size-md c-overflow-hide c-bg-white">
	<div class="container-fluid">
		<div class="col-md-6 colCenter">
			<span class="title_h3 c-font-24 c-font-sbold">{{ 'recover_password' | translate }}</span>
			<form  [formGroup]="resetForm" (ngSubmit)="onSubmit()">

				<div class="form-group row">
					<div class="col-md-6">
						<label class="control-label" for="password">{{ 'password' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
						<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" name="password" class="form-control c-square valRequired">
						<div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
						 <div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
						 <div *ngIf="f.password.errors.minlength">{{ 'min6char' | translate }}</div>
					 </div>
					</div>
					<div class="col-md-6">
						<label class="control-label" for="passwordconf">{{ 'confirmpassword' | translate }}<i class="c-theme-font fa fa-asterisk"></i></label>
						<input type="password" id="passwordconf" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" formControlName="confirmPassword" name="passwordconf" class="form-control c-square valRequired valMatch">
						<div *ngIf="this.submitted && f.confirmPassword.errors" class="invalid-feedback">
						 <div *ngIf="f.confirmPassword.errors.required">{{ 'msg_valRequired' | translate }}</div>
						 <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'msg_valMatchP' | translate }}</div>
					 </div>
					</div>
				</div>
				<div class="form-group help-text row c-margin-t-40">
					<span class="help-block col-md-8"><i class="c-theme-font fa fa-asterisk"></i> {{ 'requiredfields' | translate }}.</span>
					<div class="col-md-4 text-right">
						<button class="btn c-theme-btn c-btn-square c-btn-uppercase c-btn-bold" type="submit">{{ 'save' | translate }}</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
