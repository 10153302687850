import { Injectable, Inject, Optional, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(@Inject(DOCUMENT) private dom) { }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.insertBefore(element, this.dom.getElementsByTagName('base')[0]);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  clearAlternateUrl(){
    let list =this.dom.querySelectorAll('link[rel=alternate]') ;
      if(list){
        for (let index = 0; index < list.length; index++) {
          list[index].remove();
        }
      }
  }

  updateAlternateUrl(url:string, l:string){
    const head = this.dom.getElementsByTagName('head')[0];

      var element: HTMLLinkElement= this.dom.createElement('link') as HTMLLinkElement;
      head.insertBefore(element, this.dom.getElementsByTagName('base')[0]);

    element.setAttribute('rel','alternate')
    element.setAttribute('hreflang',l)
    element.setAttribute('href',url)
  }

  removeTag(attrSelector: string) {
    if (attrSelector) {
      try {
        this.dom.querySelector('link[' + attrSelector + ']').remove();
      } catch (e) {
        console.log('Error while removing tag ' + e.message);
      }
    }
  }

}
